import { Dropdown, Loader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/Dashboard.module.sass'
import EnergyBreakdownBarChart from 'src/components/charts/EnergyBreakdownBarChart'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { dashboardExecutionChart, dashboardExecutionList, dashboardScenarioId } from 'src/stores/AppStore'
import { ExecutionChartResponse, ExecutionList } from 'src/models/api/DashboardResponseModel'
import React, { useState, startTransition, useCallback, useMemo, useEffect } from 'react'
import classNames from 'classnames'

type SortOrder = 'asc' | 'none' | 'desc'
type SortKey = 'row.year' | 'row.boy_storage' | 'row.eoy_storage_expected'

const EnergyBreakdown = () => {
  const [searchQueryState, setSearchQueryState] = useRecoilState<number>(dashboardScenarioId)
  const [executionList, setExecutionList] = useState<ExecutionList[]>([])
  const [chartList, setChartList] = useState<ExecutionChartResponse[]>([])
  const dashboardExecutionListState = useRecoilValueLoadable(dashboardExecutionList)
  const dashboardExecutionChartValue = useRecoilValue(dashboardExecutionChart)
  const [sortConfig, setSortConfig] = useState<{ key: SortKey; order: SortOrder }>({ key: 'row.year', order: 'asc' })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const chartData = useMemo(() => {
    if (dashboardExecutionChartValue && Array.isArray(dashboardExecutionChartValue)) {
      return dashboardExecutionChartValue as ExecutionChartResponse[]
    }
    return []
  }, [dashboardExecutionChartValue])

  React.useEffect(() => {
    if (dashboardExecutionListState.state === 'hasValue' && dashboardExecutionListState.contents) {
      startTransition(() => {
        if (dashboardExecutionListState.contents['items'] !== undefined) {
          const items = dashboardExecutionListState.contents['items']
          setExecutionList(items)
          setChartList(chartData)
          if (items.length > 0 && searchQueryState === 0) {
            setSearchQueryState(items[0].execution_scenario_association_id)
          }
        } else {
          setExecutionList([])
          setChartList([])
          setSearchQueryState(0)
        }
      })
    }
  }, [chartData, dashboardExecutionListState, searchQueryState, setSearchQueryState])

  useEffect(() => {
    if (dashboardExecutionListState.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [dashboardExecutionListState.state])

  const handleSort = useCallback((key: SortKey) => {
    setSortConfig((prevConfig) => {
      const newOrder = prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc'
      return { key, order: newOrder }
    })
  }, [])

  const sortedItems = useMemo(() => {
    const indexedSummary = chartList.map((item, index) => ({
      ...item,
      originalIndex: index,
    }))

    const getValue = (item: ExecutionChartResponse, key: SortKey) => {
      switch (key) {
        case 'row.year':
          return item.year
        case 'row.boy_storage':
          return item.boy_storage
        case 'row.eoy_storage_expected':
          return item.eoy_storage_expected
        default:
          return ''
      }
    }

    const sorted = [...indexedSummary].sort((a, b) => {
      const itemAValue = getValue(a, sortConfig.key)
      const itemBValue = getValue(b, sortConfig.key)

      if (sortConfig.order === 'asc') {
        if (itemAValue < itemBValue) return -1
        if (itemAValue > itemBValue) return 1
        return 0
      } else {
        if (itemAValue > itemBValue) return -1
        if (itemAValue < itemBValue) return 1
        return 0
      }
    })

    return sorted
  }, [chartList, sortConfig])

  return (
    <div className={Style.energy_breakdown}>
      {isLoading && (
        <div className={Style.loaderWrapper}>
          <Loader />
        </div>
      )}
      {!isLoading && sortedItems.length > 0 ? (
        <div>
          <p className={Style.tabTitle}>Energy breakdown</p>
          <div className={(Style.dropdownContainer, Style.rightContainer)}>
            <div className={Style.search}></div>
            <div className={classNames(Style.rightContainerEnergy, 'rightContainer')}>
              <Dropdown
                label='Select Scenario'
                items={executionList.map((item) => ({
                  id: item.execution_scenario_association_id,
                  label: item.name,
                }))}
                selectedItem={searchQueryState ?? 0}
                onSelectItem={(v) => setSearchQueryState(Number(v))}
                optionsHeight={200}
                cssClass={classNames('is-custom', Style.dropdownPlaceholder)}
              />
            </div>
          </div>
          <section className={Style.chart}>
            <EnergyBreakdownBarChart data={chartData} />
          </section>
          <section className={Style.grid}>
            <div className={Style.overflowx}>
              <Table
                headers={[
                  {
                    label: 'Year',
                    sort: sortConfig.key === 'row.year' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('row.year'),
                  },
                  {
                    label: 'BOY Storage',
                    sort: sortConfig.key === 'row.boy_storage' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('row.boy_storage'),
                  },
                ]}
              >
                {!isLoading &&
                  sortedItems.length > 0 &&
                  sortedItems.map((row: ExecutionChartResponse) => (
                    <TableRow key={row.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{row.year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{row.boy_storage}</TableCell>
                    </TableRow>
                  ))}
              </Table>
            </div>
          </section>
        </div>
      ) : (
        !isLoading && <div className={Style.noRecords}>No records found</div>
      )}
    </div>
  )
}

export default EnergyBreakdown
