import { getAsync, getFileWithDownloadProgress, postAsync } from 'src/helpers/apiRequest'
import { ExecutionResponse } from 'src/models/api/ExecutionReponseModel'
import { ExecutionsDetailsResponseModel } from 'src/models/api/ExecutionsDetailsResponseModel'
import { ExecutionsRequestModal } from 'src/models/api/ExecutionsRequestModal'
import { IRRNPVCalcModel } from 'src/models/api/IRRNPVCalcModel'
import { IResponse } from 'src/models/api/IResponse'
import { NewExecutionsRequestModal } from 'src/models/api/NewExecutionsRequestModal'
import { getToken } from './TokenManager'
import { generateUniqueString } from 'src/helpers/utils'
import { PreferedExecutionScenarioResponse } from 'src/models/api/PreferedExecutionScenarioResponse'

const fetchExecutionsList = async (
  projectId: number,
  page: number,
  pageSize: number,
  searchquery: string,
  sortColumn: string | number,
  sortOrder: string,
) => {
  try {
    const baseUri = `/v1/execution`
    const token = await getToken()
    const requestbody: ExecutionsRequestModal = {
      token: token,
    }
    const response: IResponse<ExecutionResponse> = await getAsync<ExecutionsRequestModal, ExecutionResponse>(
      `${baseUri}?project_id=${projectId}&page=${page}&size=${pageSize}&name=${searchquery}&sort_column=${sortColumn}&sort_order=${sortOrder}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const fetchExecutionsDetails = async (execution_scenario_id: number) => {
  try {
    const token = await getToken()
    const requestbody: ExecutionsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/execution/execution-scenario`
    const url = `${baseUri}/${execution_scenario_id}`
    const response: IResponse<ExecutionsDetailsResponseModel> = await getAsync<
      ExecutionsRequestModal,
      ExecutionsDetailsResponseModel
    >(url, requestbody)

    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const newExecutionData = async (execution_scenario_id: string[], projectId: number) => {
  try {
    const token = await getToken()
    const executionName = `Execution - ${generateUniqueString()}`
    const requestbody: NewExecutionsRequestModal = {
      token: token,
      name: executionName,
      project_id: projectId,
      description: '',
      scenario_ids: execution_scenario_id,
    }
    const baseUri = `/v1/execution`
    const response: IResponse<ExecutionsDetailsResponseModel> = await postAsync<
      ExecutionsRequestModal,
      ExecutionsDetailsResponseModel
    >(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const fetchIRRNPVDetails = async (execution_scenario_id: number) => {
  try {
    const token = await getToken()
    const requestbody: ExecutionsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/execution/summary/net_present_value`
    const url = `${baseUri}?execution_scenario_id=${execution_scenario_id}`
    const response: IResponse<IRRNPVCalcModel[]> = await getAsync<ExecutionsRequestModal, IRRNPVCalcModel[]>(
      url,
      requestbody,
    )

    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const downloadSummaryFile = (execution_scenario_id: number, onDownloadProgress: (progress: number) => void) =>
  downloadFile(execution_scenario_id, onDownloadProgress)

export const downloadFile = async (execution_scenario_id: number, onDownloadProgress: (progress: number) => void) => {
  try {
    const baseUri = `v1/execution/summary/download?execution_scenario_id=`
    const token = await getToken()
    const response = await getFileWithDownloadProgress(`${baseUri}${execution_scenario_id}`, token, onDownloadProgress)
    return response
  } catch (error) {
    console.error(`Error file:`, error)
    throw error
  }
}

export const downloadErrorLogFile = (execution_scenario_id: number, onDownloadProgress: (progress: number) => void) =>
  downloadExecutionErrorLogFile(execution_scenario_id, onDownloadProgress)

export const downloadExecutionErrorLogFile = async (
  execution_scenario_id: number,
  onDownloadProgress: (progress: number) => void,
) => {
  try {
    const baseUri = `v1/execution/summary/log/`
    const token = await getToken()
    const response = await getFileWithDownloadProgress(`${baseUri}${execution_scenario_id}`, token, onDownloadProgress)
    return response
  } catch (error) {
    console.error(`Error downloading log file:`, error)
    throw error
  }
}

const fetchPreferedExecutionScenario = async (projectId: number, statusId: number) => {
  try {
    const baseUri = '/v1/execution/scenarios/latest'
    const token = await getToken()
    const url = `${baseUri}?project_id=${projectId}&status_id=${statusId}&sort_column=created_at&sort_order=desc`

    const requestBody: ExecutionsRequestModal = {
      token: token,
    }

    const response = await getAsync<ExecutionsRequestModal, PreferedExecutionScenarioResponse>(url, requestBody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export {
  fetchExecutionsDetails,
  fetchExecutionsList,
  fetchIRRNPVDetails,
  newExecutionData,
  fetchPreferedExecutionScenario,
}
