import React, { useEffect, useMemo } from 'react'
import { AverageContingencyFCASPriceChartColors } from 'src/config/colors'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { FcasChartDetails } from 'src/models/api/ReportMappingResponseModel'
import AverageContingencyFCASPriceChart from './AverageContingencyFCASPriceChart'
import { updateChartDataInForm } from 'src/api/ReportService'

interface AverageContingencyFCASPriceChartProcessorProps {
  data: FcasChartDetails
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessAverageContingencyFCASPriceChart: React.FC<AverageContingencyFCASPriceChartProcessorProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const chartData = useMemo(() => {
    if (!data.avg_regulation_fcas_price_basis || data.avg_regulation_fcas_price_basis.length === 0) {
      return null
    }

    const avgRegulationFCASPriceBasis = data.avg_regulation_fcas_price_basis

    return {
      labels: avgRegulationFCASPriceBasis.map((item) => item.year.toString() ?? 'N/A'),
      datasets: [
        {
          label: 'R1',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_r1_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[0],
          backgroundColor: 'transparent',
          borderWidth: 3,
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'R6',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_r6_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[1],
          backgroundColor: 'transparent',
          borderWidth: 3,
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'R60',
          data: avgRegulationFCASPriceBasis.map(
            (item) => parseFloat(Number(item.avg_r60_rrp_adjusted).toFixed(2)) ?? 0,
          ),
          borderColor: AverageContingencyFCASPriceChartColors[2],
          backgroundColor: 'transparent',
          borderWidth: 3,
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'R5',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_r5_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[3],
          borderWidth: 3,
          backgroundColor: 'transparent',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'L1',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_l1_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[4],
          borderWidth: 3,
          backgroundColor: 'transparent',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'L6',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_l6_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[5],
          borderWidth: 3,
          backgroundColor: 'transparent',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'L60',
          data: avgRegulationFCASPriceBasis.map(
            (item) => parseFloat(Number(item.avg_l60_rrp_adjusted).toFixed(2)) ?? 0,
          ),
          borderColor: AverageContingencyFCASPriceChartColors[6],
          borderWidth: 3,
          backgroundColor: 'transparent',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'L5',
          data: avgRegulationFCASPriceBasis.map((item) => parseFloat(Number(item.avg_l5_rrp_adjusted).toFixed(2)) ?? 0),
          borderColor: AverageContingencyFCASPriceChartColors[7],
          borderWidth: 3,
          backgroundColor: 'transparent',
          tension: 0.4,
          yAxisID: 'y',
        },
      ],
    }
  }, [data])

  useEffect(() => {
    if (!chartData) return
    updateChartDataInForm(setFormData, sectionId, 'chart2', chartData)
  }, [chartData, setFormData, sectionId])

  return <AverageContingencyFCASPriceChart chartData={chartData} />
}

export default ProcessAverageContingencyFCASPriceChart
