export enum SortingItemsEnum {
  CREATED_AT = 'created_at',
  NAME = 'name',
  CLIENT_NAME = 'client_name',
}

export const SortingItems = [
  {
    id: SortingItemsEnum.CREATED_AT,
    label: 'Created Date',
  },
  {
    id: SortingItemsEnum.NAME,
    label: 'Project Name',
  },
  {
    id: SortingItemsEnum.CLIENT_NAME,
    label: 'Client Name',
  },
]
