import { Button, Container } from '@aurecon-creative-technologies/styleguide'
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { FetchReportMappingDataRequestModel } from 'src/api/ReportService'
import { selectedProjectIdState } from 'src/stores/AppStore'
import Style from '../../styles/Reports.module.sass'
import ScenarioOverview from './ScenarioOverview'
import SelectScenario from './SelectScenario'

interface GenerateReportProps {
  mode: 'clone' | 'generate'
}

const GenerateReport = ({ mode }: GenerateReportProps) => {
  const navigate = useNavigate()
  const params = useParams()
  const reportId = params?.report_Id ?? undefined
  const [showOverview, setShowOverview] = useState(false)
  const [overviewData, setOverviewData] = useState<FetchReportMappingDataRequestModel | null>(null)
  const projectId = useRecoilValue(selectedProjectIdState)

  const handleToggleView = (data: FetchReportMappingDataRequestModel | null = null) => {
    setShowOverview(!showOverview)
    if (data) {
      setOverviewData(data)
    }
  }

  const handleGoBackClick = useCallback(() => {
    navigate(`/dashboard/${projectId}/reportlist`)
  }, [navigate, projectId])

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <div className={Style.go_back}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
                onClick={handleGoBackClick}
              />
              <h2 className={Style.titleHead}>{mode === 'clone' ? 'Clone Report' : 'Generate Report'}</h2>
            </div>
          </div>
        </Container>
      </header>
      {showOverview ? (
        <ScenarioOverview handleToggleView={handleToggleView} data={overviewData} />
      ) : (
        <SelectScenario
          mode={mode}
          handleToggleView={handleToggleView}
          cloneReportId={mode === 'clone' ? reportId : undefined} // Use reportId only if mode is 'clone'
        />
      )}
    </Container>
  )
}

export default GenerateReport
