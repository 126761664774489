import { Button } from '@aurecon-creative-technologies/styleguide'
import React, { useRef, useState } from 'react'
import PrivacyPolicy from 'src/pages/PrivacyPolicy'
import TermsAndConditions from 'src/pages/TermsAndConditions'
import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_black.svg'
import Style from '../styles/Footer.module.sass'
import { Modal, useModal } from './Modal'

type FooterProps = {
  appversion?: string | null
}

const Footer: React.FC<FooterProps> = ({ appversion }) => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const { isShowing: isTermsShowing, toggleModal: toggleTermsModal } = useModal(false)
  const { isShowing: isPrivacyShowing, toggleModal: togglePrivacyModal } = useModal(false)
  const [terms, setTerms] = useState(false)
  const [privacy, setPrivacy] = useState(false)

  const toggleTerms = () => {
    toggleTermsModal()
    setTerms(!terms)
  }

  const togglePrivacy = () => {
    togglePrivacyModal()
    setPrivacy(!privacy)
  }

  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <footer className={Style.footer}>
      <AureconLogo height={30} width={90} />
      <div className={Style.textContainer}>
        <Button
          type='text'
          size='extra small'
          label='Terms & Conditions'
          onClick={toggleTerms}
          cssClass={Style.linkButton}
        />
        <Modal
          isShowing={isTermsShowing}
          onClose={toggleTermsModal}
          size='large'
          isCloseButton={true}
          isOverlay={true}
          shouldOverlayClose={true}
        >
          <div
            ref={modalContentRef}
            style={{ maxHeight: '100vh', overflowY: 'auto', marginRight: '16px', marginTop: '50px' }}
          >
            <TermsAndConditions scrollToTop={scrollToTop} isModal={true} />
          </div>
        </Modal>
        <span className={Style.separator}>|</span>
        <Button
          type='text'
          size='extra small'
          label='Privacy Policy'
          onClick={togglePrivacy}
          cssClass={Style.linkButton}
        />
        <Modal
          isShowing={isPrivacyShowing}
          onClose={togglePrivacyModal}
          size='large'
          isCloseButton={true}
          isOverlay={true}
          shouldOverlayClose={true}
        >
          <div
            ref={modalContentRef}
            style={{ maxHeight: '100vh', overflowY: 'auto', marginRight: '16px', marginTop: '50px' }}
          >
            <PrivacyPolicy scrollToTop={scrollToTop} isModal={true} />
          </div>
        </Modal>
        <span className={Style.separator}>|</span>
        <p className={Style.text}>Version: {appversion}</p>
      </div>
    </footer>
  )
}

export default Footer
