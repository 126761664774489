import React, { useRef, useEffect } from 'react'
import { Chart, TooltipItem } from 'chart.js/auto'

interface AverageContingencyFCASPriceChartProps {
  chartData: {
    labels: string[]
    datasets: {
      label: string
      data: number[]
      borderColor: string
      backgroundColor: string
      borderWidth: number
      tension: number
      yAxisID: string
    }[]
  } | null
}

export const AverageContingencyFCASPriceChart: React.FC<AverageContingencyFCASPriceChartProps> = ({ chartData }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<Chart<'line'> | null>(null)

  useEffect(() => {
    if (!chartData) return
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx) return

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: chartData.labels,
        datasets: chartData.datasets.map((dataset) => ({
          ...dataset,
          borderColor: dataset.borderColor,
          backgroundColor: dataset.backgroundColor,
          borderWidth: dataset.borderWidth,
          tension: dataset.tension,
          yAxisID: 'y', // Use the same Y-axis for all datasets
        })),
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Year',
            },
          },
          y: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: '$/MWh',
            },
            position: 'left',
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxHeight: 1,
              boxWidth: 30,
              // usePointStyle: true, // This makes the legend items use line point styles
            },
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            callbacks: {
              label: (tooltipItem: TooltipItem<'line'>) => `$${(tooltipItem.raw as number).toFixed(2)}`,
            },
          },
          title: {
            display: true,
            text: 'Average Contingency FCAS price basis',
            font: {
              size: 14,
              weight: 'normal',
            },
            padding: {
              top: 10,
              bottom: 20,
            },
          },
        },
      },
    })

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [chartData])

  return <canvas ref={chartRef} width='400' height='200'></canvas>
}

export default AverageContingencyFCASPriceChart
