import * as Yup from 'yup'

export const GenerateReportRequestSchema = Yup.object().shape({
  created_at: Yup.string(),
  updated_at: Yup.string(),
  created_by: Yup.string(),
  updated_by: Yup.string(),
  name: Yup.string(),
  description: Yup.string(),
  project_id: Yup.number(),
  preferred_scenario_id: Yup.number(),
  execution_scenario_association_id: Yup.number(),
  report_file_path: Yup.string(),
  preferred_date: Yup.string(),
  initialIntroComment: Yup.string(),
  preferredScenarioIntroComment: Yup.string(),
  report_detail: Yup.object().shape({
    created_at: Yup.string(),
    updated_at: Yup.string(),
    created_by: Yup.string(),
    updated_by: Yup.string(),
    commercial_operation_date: Yup.string().required('Commercial operation date is required'),
    commercial_operation_comments: Yup.string(),
    economic_life: Yup.number().required('Economic life is required'),
    economic_life_comments: Yup.string(),
    nem_region: Yup.string(),
    contingency_fcas_registered_capacity: Yup.object().shape({
      r1_max_registered_capacity: Yup.number(),
      r6_max_registered_capacity: Yup.number(),
      r60_max_registered_capacity: Yup.number(),
      r5_max_registered_capacity: Yup.number(),
      l1_max_registered_capacity: Yup.number(),
      l6_max_registered_capacity: Yup.number(),
      l60_max_registered_capacity: Yup.number(),
      l5_max_registered_capacity: Yup.number(),
    }),
    regulation_fcas_registered_capacity: Yup.object().shape({
      raise_reg: Yup.number(),
      lower_reg: Yup.number(),
    }),
    cap_contract_price: Yup.string().required('CAP contract price is required'),
    cap_contract_volume: Yup.string().required('CAP contract volume is required'),
    generation_marginal_loss_factor: Yup.string().required('Generation marginal loss factor is required'),
    load_marginal_loss_factor: Yup.string().required('Load marginal loss factor is required'),
    pop_wholesale_correction_factor: Yup.string(),
    pop_regulation_fcas_correction_factor: Yup.string(),
    pop_contingency_fcas_correction_factor: Yup.string(),
    capital_cost: Yup.string().required('Capital cost is required'),
    average_total_o_and_m_cost: Yup.string().required('Average total O&M cost is required'),
    lgc_price: Yup.string().required('LGC price is required'),
    outage_rate: Yup.string().required('Outage rate is required'),
    average_permissable_cycle: Yup.string(),
    standby_power_consumption: Yup.string(),
    report_scenario_mapping: Yup.array()
      .of(
        Yup.object().shape({
          created_at: Yup.string(),
          updated_at: Yup.string(),
          created_by: Yup.string(),
          updated_by: Yup.string(),
          scenario_id: Yup.number(),
          discharge_power: Yup.string(),
          charge_power: Yup.string(),
          bol_storage_capacity: Yup.string(),
          cap_contract: Yup.string(),
          network_support_service: Yup.string(),
          total_net_revenue: Yup.string(),
          net_present_value: Yup.string(),
          internal_rate_of_return: Yup.string(),
          inertia_contract: Yup.string(),
          sips_contract: Yup.string(),
          vcas_contract: Yup.string(),
          sras_contract: Yup.string(),
          additional_fields: Yup.array().of(
            Yup.object().shape({
              created_at: Yup.string(),
              updated_at: Yup.string(),
              created_by: Yup.string(),
              updated_by: Yup.string(),
              name: Yup.string(),
              value: Yup.string().required('Value is required'),
              slug_name: Yup.string(),
            }),
          ),
        }),
      )
      .required('Report scenario mapping is required'),
    technical_assumptions: Yup.array()
      .of(
        Yup.object().shape({
          created_at: Yup.string(),
          updated_at: Yup.string(),
          created_by: Yup.string(),
          updated_by: Yup.string(),
          year: Yup.number(),
          operating_year: Yup.number(),
          energy_storage_retention: Yup.string().required('Energy storage retention is required'),
          round_trip_efficiency: Yup.string().required('Round trip efficiency is required'),
          re_generation: Yup.string().required('Regeneration is required'),
        }),
      )
      .required('Technical assumptions are required'),
    report_section: Yup.array().of(
      Yup.object().shape({
        created_at: Yup.string(),
        updated_at: Yup.string(),
        created_by: Yup.string(),
        updated_by: Yup.string(),
        general_comment: Yup.string(),
        formatted_comment: Yup.string(),
        chart_data: Yup.string(),
        section_id: Yup.number(),
      }),
    ),
  }),
})
