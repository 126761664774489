import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { ResponseData } from '../models/api/IResponse'

interface IAppInsightsConfig {
  conn: string
  ip: string
}

export const getAIConn = async (): Promise<IAppInsightsConfig | null> => {
  try {
    const response = await fetch('/v1/app/aiconn')
    const result = await response.json()
    return ResponseData<IAppInsightsConfig>(result) || null
  } catch {
    return null
  }
}

const reactPlugin = new ReactPlugin()

const insightsState = {
  appInsights: null as ApplicationInsights | null,
  tooManyRequests: false,
}

getAIConn()
  .then((aiConfig) => {
    console.log('** Starting Application Insights...')

    if (!aiConfig) return

    const browserHistory = createBrowserHistory()
    insightsState.appInsights = new ApplicationInsights({
      config: {
        connectionString: aiConfig.conn,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    })

    insightsState.appInsights.loadAppInsights()
    insightsState.appInsights.setAuthenticatedUserContext(aiConfig.ip.replace('.', ''))
  })
  .catch((error: number) => {
    console.log(`** Couldn't start Application Insights. Error: ${error}`)
    insightsState.tooManyRequests = error === 429
  })

export const { appInsights, tooManyRequests } = insightsState
export { reactPlugin }
