import { Button } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/ConfirmationModal.module.sass'
import { appInsights } from 'src/api/AppInsights'
import PropTypes from 'prop-types'

interface ConfirmationModalProps {
  title: string
  message: string
  onCancel: () => void
  onConfirm: () => void
  yesButton?: string
  noButton?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
  yesButton = 'Confirm',
  noButton = 'Cancel',
}) => {
  if (appInsights) appInsights.trackPageView({ name: 'DeleteProjects' })
  return (
    <>
      <h2>
        <b>{title}</b>
      </h2>
      <p>{message}</p>
      <div className={Style.buttonContainer}>
        <Button type='custom' cssClass={`is-white ${Style.cancelBtn}`} label={noButton} onClick={onCancel} />
        <Button type='custom' cssClass={`is-white ${Style.confirmBtn}`} label={yesButton} onClick={onConfirm} />
      </div>
    </>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  yesButton: PropTypes.string,
  noButton: PropTypes.string,
}

export default ConfirmationModal
