import {
  AdditionalField,
  GenerateReportRequest,
  ReportDetail,
  ReportScenarioMapping,
  ReportSection,
  ContingencyFCASRegisteredCapacity,
  RegFCASRegisteredCapacity,
  CommercialAndMarketAssumptions,
  TechnicalAssumption,
  ScenarioDetail,
} from './GenerateReportRequestFormData'

export const GenerateReportRequestDefaults: GenerateReportRequest = {
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  created_by: '',
  updated_by: '',
  name: '',
  description: '',
  project_id: 0,
  preferred_scenario_id: 0,
  execution_scenario_association_id: 0,
  npv_discount_rate: 0,
  report_file_path: '',
  preferred_date: '',
  initialIntroComment: '',
  preferredScenarioIntroComment: '',
  report_detail: ReportDetailDefaults(),
}

function ReportDetailDefaults(): ReportDetail {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    commercial_operation_date: '',
    commercial_operation_comments: '',
    economic_life: 0,
    economic_life_comments: '',
    nem_region: '',
    contingency_fcas_registered_capacity: ContingencyFCASRegisteredCapacityDefaults(),
    regulation_fcas_registered_capacity: RegFCASRegisteredCapacityDefaults(),
    cap_contract_price: '0',
    cap_contract_volume: '0',
    generation_marginal_loss_factor: '0',
    load_marginal_loss_factor: '0',
    pop_wholesale_correction_factor: '0',
    pop_regulation_fcas_correction_factor: '0',
    pop_contingency_fcas_correction_factor: '0',
    pop_reg_fcas: 0,
    pop_contingency_fcas: 0,
    capital_cost: '0',
    average_total_o_and_m_cost: '0',
    average_total_o_and_m: '0',
    lgc_price: '0',
    outage_rate: '0',
    average_permissable_cycle: '0',
    standby_power_consumption: '0',
    report_scenario_mapping: [ReportScenarioMappingDefaults()],
    technical_assumptions: [TechnicalAssumptionDefaults()],
    report_section: Array.from({ length: 13 }, (_, i) => ReportSectionDefaults(i + 1)),
    commercial_and_market_assumptions: CommercialAndMarketAssumptionsDefaults(),
    pop_wholesale_energy_generation: 0,
    generation_marginal_loss_factor_start_of_life: 0,
    generation_marginal_loss_factor_end_of_life: 0,
    load_marginal_loss_factor_start_of_life: 0,
    load_marginal_loss_factor_end_of_life: 0,
    scenario_detail: ScenarioDetailDefaults(),
  }
}
function ScenarioDetailDefaults(): ScenarioDetail {
  return {
    rated_charge_power: 0,
    rreg_utilisation: 0,
    is_sras_contract: false,
    selected_inertia_constant: 0,
    pop_wholesale_energy_load: 0,
    scenario_id: 0,
    bol_storage_capacity: 0,
    lreg_utilisation: 0,
    is_vcas_contract: false,
    rocof_design_criteria: 0,
    pop_reg_fcas: 0,
    is_active: 0,
    id: 0,
    vcas_rates_mvar: 0,
    inertia_utilisation: 0,
    is_cap_contract_intended: false,
    standby_power_consumption: 0,
    pop_contingency_fcas: 0,
    project_title: '',
    overload_discharge_power: 0,
    overload_charge_power: 0,
    is_manual_ltsa_input: false,
    lgc_prce_forecast: 0,
    standard_inflation_rate: 0,
    year_of_deployment: 0,
    first_year_of_commercial_operation: 0,
    solve_interval_hours: 0,
    inverter_capacity: 0,
    is_manual_warranty_input: false,
    bess_power_rating: 0,
    total_project_cost_uplift: 0,
    created_by: '',
    project_life_years: 0,
    window_hours: 0,
    is_contracted_inertia_enabled: false,
    is_network_services_intended: false,
    bess_energy_storage_capacity: 0,
    npv_discount_rate: 0,
    updated_by: '',
    time_res: 0,
    is_inertia_contract: false,
    is_non_market_service: false,
    o_and_m_inflation_rate: 0,
    lgc_price: 0,
    created_at: new Date(),
    rated_discharge_power: 0,
    is_sips_contract: false,
    inertia_contract: 0,
    ltsa_avg_cost: 0,
    updated_at: new Date(),
    cap_contracts: [],
    marginal_loss_factors: [],
    bess_inputs: [],
    capex: [],
    repex: [],
    commercial_parameters: [],
    market_inputs: [],
    network_support_services: [],
    coupled_type: null,
    hybrid_generation_type: null,
  }
}

function ReportScenarioMappingDefaults(): ReportScenarioMapping {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    scenario_id: 1,
    discharge_power: '0',
    charge_power: '0',
    bol_storage_capacity: '0',
    cap_contract: 'No',
    network_support_service: '0',
    total_net_revenue: '0',
    net_present_value: '0',
    internal_rate_of_return: '0',
    is_non_market_service: '0',
    non_market_service: 'No',
    npv_discount_rate: 0,
    inertia_scenario: 'No',
    sips_contract: '0',
    vcas_contract: '0',
    sras_contract: '0',
    additional_fields: [AdditionalFieldDefaults()],
  }
}

function TechnicalAssumptionDefaults(): TechnicalAssumption {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    year: 0,
    operating_year: 0,
    energy_storage_retention: '0',
    round_trip_efficiency: '0',
    re_generation: '0',
  }
}

function ReportSectionDefaults(section_id: number): ReportSection {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    general_comment: '',
    formatted_comment: '',
    chart_data: '',
    section_id,
  }
}

function AdditionalFieldDefaults(): AdditionalField {
  return {
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    created_by: '',
    updated_by: '',
    name: 'other',
    value: '0',
    slug_name: '',
  }
}

function ContingencyFCASRegisteredCapacityDefaults(): ContingencyFCASRegisteredCapacity {
  return {
    r1_max_registered_capacity: 0,
    r6_max_registered_capacity: 0,
    r60_max_registered_capacity: 0,
    r5_max_registered_capacity: 0,
    l1_max_registered_capacity: 0,
    l6_max_registered_capacity: 0,
    l60_max_registered_capacity: 0,
    l5_max_registered_capacity: 0,
  }
}

function RegFCASRegisteredCapacityDefaults(): RegFCASRegisteredCapacity {
  return {
    raise_reg: 0,
    lower_reg: 0,
  }
}

function CommercialAndMarketAssumptionsDefaults(): CommercialAndMarketAssumptions {
  return {
    contingency_fcas_registered_capacity: ContingencyFCASRegisteredCapacityDefaults(),
    reg_fcas_registered_capacity: RegFCASRegisteredCapacityDefaults(),
    capital_cost: 0,
    average_total_o_and_m: 0,
    average_permissible_cycles: 0,
  }
}
