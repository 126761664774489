import { Button, IHeader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { GENERATING_TABLE_HEADERS } from 'src/config/constant'
import { GeneratingTableModelItem } from 'src/models/api/ForecastingTableModel'
import Style from '../../styles/UploadForecast.module.sass'

interface GeneratingTablePreviewProps {
  data: GeneratingTableModelItem[]
}

const GeneratingTablePreview: React.FC<GeneratingTablePreviewProps> = ({ data }) => {
  if (data.length === 0) {
    return null
  }
  const rows = data.map((item) => [
    item['DateTime'].toString().split('T')[0],
    item['DateTime'].toString().split('T')[1],
    item['DCPower'].toString(),
  ])

  const tableProfileHeaders: IHeader[] = GENERATING_TABLE_HEADERS.map((header) => ({
    label: header,
    style: { width: '300px' },
  }))

  return (
    <div className={Style.noProfilePadding} style={{ width: '100%' }}>
      <Button
        type='custom'
        cssClass={Style.leapYearText}
        label='Note: Only the first 10 lines are shown'
        icon='info'
        size='small'
      />
      <Table headers={tableProfileHeaders}>
        {rows.map((row) => (
          <TableRow key={row?.['Time']}>
            {row.map((cell) => (
              <TableCell cellClass={Style.tableDataProfilePadding} key={cell?.['Time']} title={cell}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default GeneratingTablePreview
