import { Container, Grid, Loader, Pagination } from '@aurecon-creative-technologies/styleguide'
import React, { startTransition, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import ProcessPreferredScenarioDashboardChart from 'src/components/charts/ProcessPreferredScenarioDashboardChart'
import { DEFAULT_PAGINATION_DISPLAY_PAGES } from 'src/config/constant'
import { ItemResponse } from 'src/models/api/DashboardResponseModel'
import { dashboardCurrentPage, dashboardNvbBreakdown, selectedProjectIdState } from 'src/stores/AppStore'
import Style from '../../styles/Dashboard.module.sass'
import { useNavigate } from 'react-router-dom'

const NPVbreakdown = () => {
  const navigate = useNavigate()
  const projectId = useRecoilValue(selectedProjectIdState)
  const [currentPage, setCurrentPage] = useRecoilState(dashboardCurrentPage)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [nvpBreakdown, setNvpBreakdown] = useState<ItemResponse[]>([])
  const getNvb = useRecoilValueLoadable(dashboardNvbBreakdown)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (getNvb.state === 'hasValue' && getNvb.contents) {
      startTransition(() => {
        if (getNvb.contents['items'] !== undefined) {
          setNvpBreakdown(getNvb.contents['items'])
          setTotalCount(getNvb.contents['total'])
          setTotalPages(getNvb.contents['pages'])
        } else {
          setNvpBreakdown([])
          setTotalPages(1)
        }
      })
    }
  }, [getNvb])

  useEffect(() => {
    if (getNvb.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [getNvb.state])

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page === 0 ? 1 : page)
  }

  const handleNavigation = (selectedExecutionID: number) => {
    if (selectedExecutionID) navigate(`/dashboard/${projectId}/executiondetail/${selectedExecutionID}`)
  }

  return (
    <Container fluid>
      {isLoading && (
        <div className={Style.loaderWrapper}>
          <Loader />
        </div>
      )}
      {!isLoading && nvpBreakdown.length > 0 ? (
        <div>
          <section className={Style.npvbreakdown}>
            <div className={Style.grid}>
              {!isLoading &&
                nvpBreakdown.map((data) => (
                  <button
                    key={data.id}
                    className={Style.grid_card}
                    onClick={() => handleNavigation(data.npv_and_irr_calculation.execution_scenario_id)}
                  >
                    <p className={Style.scenarioname}>{data.npv_and_irr_calculation.scenario_name}</p>
                    <div className={Style.chart}>
                      <ProcessPreferredScenarioDashboardChart data={data.npv_and_irr_calculation} />
                    </div>
                    <Grid row gap={12}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} cell>
                        <div className={Style.npv_scenarioname}>
                          <p>Scenario</p>
                          <p className={Style.pb0}>Scenario Name</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} right cell>
                        <div className={Style.npv_scenarioname}>
                          <p>{data.npv_and_irr_calculation.scenario_id}</p>
                          <p className={Style.pb0}>{data.npv_and_irr_calculation.scenario_name}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </button>
                ))}
            </div>
          </section>
          {!isLoading && nvpBreakdown.length !== 0 && (
            <div className={Style.paginationCont}>
              <div className={Style.paginationButtons}>
                <Pagination
                  page={currentPage}
                  pageCount={totalPages}
                  displayPages={DEFAULT_PAGINATION_DISPLAY_PAGES}
                  onChange={handlePaginationClick}
                  prevButtonTitle='Previous page'
                  nextButtonTitle='Next page'
                  cssClass={Style.paginatCont}
                  showJumpToInput
                />
              </div>
              <div className={Style.totalPageCount}>
                {currentPage} - {totalPages} of {totalCount}
              </div>
            </div>
          )}
        </div>
      ) : (
        !isLoading && <div className={Style.noRecords}>No records found</div>
      )}
    </Container>
  )
}

export default NPVbreakdown
