import { getAsync, getAsyncList } from 'src/helpers/apiRequest'
import { AllProjectApiResponse, ApiResponse } from 'src/models/api/Projects'
import { ProjectsRequestModal } from 'src/models/api/ProjectsRequestModal'
import { IResponse } from '../models/api/IResponse'
import { getToken } from './TokenManager'
import { ProductionSecretModel } from 'src/models/api/ProductionSecret'
import { UserResponseModel } from 'src/models/api/UserGetResponseModel'

export interface IAppVersion {
  version: string
}

export interface IAppFlags {
  [x: string]: boolean
}

export interface IAppOpenAPI {
  openapi: string
  info: Record<string, never>
  paths: Record<string, never>
  components: Record<string, never>
}

const FAIL_VERSION_RESPONSE = { success: false, data: { version: '0.0.0' } }
const FAIL_FLAGS_RESPONSE = { success: false, data: {} }

export const getAppVersion = async (): Promise<IResponse<IAppVersion>> => {
  try {
    const token = await getToken()
    const baseUri = `/v1/app/version`

    const response = await fetch(`${baseUri}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      return { ...FAIL_VERSION_RESPONSE, status: response.status }
    }

    const data = await response.json()
    return { success: true, data, status: response.status }
  } catch (err) {
    console.error(err)
    return { ...FAIL_VERSION_RESPONSE, status: 500 }
  }
}

export const getAppFlags = async (): Promise<IResponse<IAppFlags>> => {
  try {
    const response = await fetch('/v1/app/flags')

    if (response.ok) {
      const data = await response.json()
      return { success: true, data, status: response.status }
    }

    return { ...FAIL_FLAGS_RESPONSE, status: response.status }
  } catch {
    return { ...FAIL_FLAGS_RESPONSE, status: 500 } // Assuming 500 for a general error
  }
}

export const fetchProjectData = async (
  page: number,
  pageSize: number,
  sortColumn: string | number,
  sortOrder: string,
  searchquery: string,
) => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/projects`
    const response = await getAsync<ProjectsRequestModal, ApiResponse>(
      `${baseUri}?sort_column=${sortColumn}&sort_order=${sortOrder}&page=${page}&size=${pageSize}&name=${searchquery}`,
      requestbody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchAllProjectData = async (): Promise<IResponse<AllProjectApiResponse>> => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/projects/all`
    const response = await getAsync<ProjectsRequestModal, AllProjectApiResponse>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchProductedSecret = async (): Promise<IResponse<ProductionSecretModel>> => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/protected/secret`
    const response = await getAsync<ProjectsRequestModal, ProductionSecretModel>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchAllUser = async (): Promise<IResponse<UserResponseModel[]>> => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/users/all`
    const response = await getAsyncList<ProjectsRequestModal, UserResponseModel[]>(`${baseUri}`, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchSwaggerDoc = async (): Promise<IResponse<IAppOpenAPI>> => {
  try {
    const token = await getToken()
    const baseUri = `/openapi.json`

    const response = await fetch(`${baseUri}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const data = await response.json()
    return { success: true, data, status: response.status }
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}
