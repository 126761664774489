import React from 'react'
import Style from '../styles/StepLine.module.sass'
import { Icon } from '@aurecon-creative-technologies/styleguide'

interface StepLineProps {
  steps: string[]
  currentStep: number
}

const StepLine: React.FC<StepLineProps> = ({ steps, currentStep }) => {
  return (
    <div className={Style.stepLineContainer}>
      {steps.map((step, index) => (
        <div
          key={step}
          className={`${Style.step} ${currentStep >= index ? Style.focus : ''} ${
            currentStep > index ? Style.active : ''
          }`}
        >
          <div className={Style.stepNumber}>
            {currentStep > index ? <Icon colour='white' type='check' style={{ fontSize: '13px' }} /> : <></>}
          </div>
        </div>
      ))}
    </div>
  )
}

export default StepLine
