import { Chart, TooltipItem, registerables } from 'chart.js'
import React, { useEffect, useRef } from 'react'
import 'chartjs-plugin-datalabels'

Chart.register(...registerables)

interface ProcessChartData {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    backgroundColor: string
  }[]
}

interface FCASRevenueChartProps {
  chartData: ProcessChartData
}

const FCASRevenueChart: React.FC<FCASRevenueChartProps> = ({ chartData }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart<'bar'> | null>(null)

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx) return

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        indexAxis: 'x',
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                if (tooltipItem.raw !== undefined) {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
                }
                return ''
              },
            },
          },
          legend: {
            position: 'bottom',
          },
          datalabels: {
            display: false,
          },
        },
      },
    })

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [chartData])

  return <canvas ref={chartRef} width={600} height={400}></canvas>
}

export default FCASRevenueChart
