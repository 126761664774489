import {
  Button,
  Container,
  DateInput,
  Dropdown,
  FormInput,
  Grid,
  InfoTooltip,
} from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { fetchPreferedExecutionScenario } from 'src/api/ExecutionsService'
import { FetchReportMappingDataRequestModel, validateReportsDetails } from 'src/api/ReportService'
import { PreferedExecutionScenario } from 'src/models/api/PreferedExecutionScenarioResponse'
import { reportSelectScenarioFormState, selectedProjectIdState } from 'src/stores/AppStore'
import * as Yup from 'yup'
import Style from '../../styles/Reports.module.sass'
import { useNavigate } from 'react-router-dom'

interface IDropdownItemProps {
  id: string
  label: string
}

interface IValidationError {
  name?: string
  preferredScenario?: string
  preferredDate?: string
}

const createSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    preferredScenario: Yup.string().required('Preferred scenario is required').min(1, 'Preferred scenario is required'),
    preferredDate: Yup.date().required('Preferred date is required'),
  })

const SelectScenario = ({
  mode,
  handleToggleView,
  cloneReportId,
}: {
  mode?: string
  handleToggleView: (data: FetchReportMappingDataRequestModel) => void
  cloneReportId?: string
}) => {
  const navigate = useNavigate()
  const projectId = useRecoilValue(selectedProjectIdState)
  const [dropdownItems, setDropdownItems] = useState<IDropdownItemProps[]>([])
  const [preferedExecutionScenario, setPreferedExecutionScenario] = useState<PreferedExecutionScenario[]>([])
  const [selectedScenario, setSelectedScenario] = useState<PreferedExecutionScenario | null>(null)
  const [validationErrors, setValidationErrors] = useState<IValidationError>({})
  const [formData, setFormData] = useRecoilState(reportSelectScenarioFormState)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const resetFormData = useCallback(() => {
    setFormData({
      name: '',
      preferred_date: '',
      execution_scenario_association_id: null,
      project_id: projectId,
    })
  }, [setFormData, projectId])

  useEffect(() => {
    if (mode === 'generate' && isFirstRender) {
      resetFormData()
      setIsFirstRender(false)
    }
  }, [mode, isFirstRender, resetFormData])

  const handleGoBackClick = useCallback(() => {
    navigate(`/dashboard/${projectId}/reportlist`)
  }, [navigate, projectId])

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await fetchPreferedExecutionScenario(projectId, 4)
        if (!response || !Array.isArray(response?.['items'])) {
          throw new Error('Invalid response structure')
        }

        setPreferedExecutionScenario(response?.['items'] || [])
        const items =
          response?.['items'].map((scenario: PreferedExecutionScenario) => ({
            id: scenario?.execution_scenario_association_id.toString(),
            label: scenario?.name,
          })) || []
        setDropdownItems(items)
        setFormData((prev) => ({
          ...prev,
          clone_report_id: mode == 'clone' && cloneReportId !== undefined ? Number(cloneReportId) : 0,
        }))
      } catch (error) {
        console.error('Error fetching execution scenarios:', error)
      }
    }

    fetchScenarios()
  }, [mode, cloneReportId, projectId, setFormData])

  const handleDropdownChange = (item: string | number) => {
    setFormData((prev) => ({ ...prev, execution_scenario_association_id: item }))
    if (item) {
      const scenario = preferedExecutionScenario.find(
        (scenario: PreferedExecutionScenario) =>
          scenario.execution_scenario_association_id.toString() === item.toString(),
      )
      if (scenario) {
        setSelectedScenario(scenario)
      }
    }
  }

  const validateReport = async (
    execution_scenario_id: number,
    report_name: string,
    preferred_date: string,
    project_id: number,
  ) => {
    try {
      const payload = { execution_scenario_id, report_name, preferred_date, project_id }
      const response = await validateReportsDetails(payload)

      if (response) {
        const details = response?.['detail'] || {}

        if (details.report_name === 'valid' && details.preferred_date === 'valid') {
          handleToggleView(formData)
          setValidationErrors({})
        } else {
          const errors: IValidationError = {}
          if (details.report_name !== 'valid') {
            errors.name = details.report_name
          }
          if (details.preferred_date !== 'valid') {
            errors.preferredDate = details.preferred_date
          }
          setValidationErrors(errors)
        }
      }
    } catch (error) {
      console.error('Error validating report:', error)
    }
  }

  const handleDateChange = async (dates: { startDate: Date | null; endDate: Date | null }) => {
    if (!selectedScenario) return

    const preferredDate = dates.startDate
    const adjustedDate = preferredDate
      ? new Date(preferredDate.getTime() - preferredDate.getTimezoneOffset() * 60000)
      : ''
    setFormData((prev) => ({
      ...prev,
      preferred_date: adjustedDate ? adjustedDate.toISOString().split('T')[0] : '',
    }))
  }

  const handleNextButtonClick = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      await createSchema().validate(
        {
          name: formData?.name,
          preferredScenario: formData?.execution_scenario_association_id?.toString() || '',
          preferredDate: formData.preferred_date ? new Date(formData.preferred_date) : null,
          projectId: formData?.project_id,
        },
        { abortEarly: false },
      )
      setValidationErrors({})
      validateReport(
        Number(formData?.execution_scenario_association_id),
        formData?.name,
        formData?.preferred_date,
        Number(formData?.project_id),
      )
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors: IValidationError = {}
        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path as keyof IValidationError] = err.message
          }
        })
        setValidationErrors(errors)
        setFormData({
          name: '',
          preferred_date: '',
          execution_scenario_association_id: null,
        })
      }
    }
  }

  const handleChange = (fieldName: string) => (value: string) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }))
  }

  return (
    <Container fluid cssClass={Style.paddingX}>
      <section className={Style.select_scenario}>
        <h3>Select Scenario</h3>
        <Grid row gap={10}>
          <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
            <FormInput
              type='text'
              key='name'
              label={'Report Name'}
              placeholder={'Name here'}
              value={formData.name}
              onChange={(value) => handleChange('name')(value)}
            />
            {validationErrors.name && <p className={Style.error}>{validationErrors.name}</p>}
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
            <Dropdown
              key='preferredScenario'
              label='Preferred Scenario'
              items={dropdownItems}
              selectedItem={formData.execution_scenario_association_id || ''}
              onSelectItem={handleDropdownChange}
            />
            {validationErrors.preferredScenario && <p className={Style.error}>{validationErrors.preferredScenario}</p>}
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
            <span className={Style.labelHead}>
              <b>
                Preferred date for day reports{' '}
                <InfoTooltip show='Some reports e.g. Sample day of FCAS price data are displayed for a day. Choose a suitable day for which these reports must be generated. Kindly ensure that the date you choose is within the operating life period.' />
              </b>
            </span>
            <DateInput
              label=''
              cssClass='reportDate'
              dates={{
                startDate: formData.preferred_date ? new Date(formData.preferred_date) : null,
                endDate: formData.preferred_date ? new Date(formData.preferred_date) : null,
              }}
              onDateChange={handleDateChange}
            />
            {validationErrors.preferredDate && <p className={Style.error}>{validationErrors.preferredDate}</p>}
          </Grid>
        </Grid>
        <div className={Style.btn_container}>
          <Button type='custom' cssClass={Style.cancelBtn} label='Cancel' size='small' onClick={handleGoBackClick} />
          <Button type='custom' cssClass={Style.nextBtn} label='Next' size='small' onClick={handleNextButtonClick} />
        </div>
      </section>
    </Container>
  )
}
export default SelectScenario
