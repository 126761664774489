import { IRadioItem } from '@aurecon-creative-technologies/styleguide'

export const HybridGenerationType: IRadioItem[] = [
  {
    id: '1',
    label: 'WIND',
  },
  {
    id: '2',
    label: 'SOLAR',
  },
]

export const FileTypeEnum = {
  1: 'Forecast File',
  2: 'Generating Profile',
}

export const FileType: IRadioItem[] = [
  {
    id: '1',
    label: 'FORECAST',
  },
  {
    id: '2',
    label: 'GENERATING_PROFILE',
  },
]

export const ProfileType: IRadioItem[] = [
  {
    id: '1',
    label: 'AC',
  },
  {
    id: '2',
    label: 'DC',
  },
]
