import {
  Button,
  Container,
  IHeader,
  Modal,
  Pagination,
  Pill,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  useModal,
} from '@aurecon-creative-technologies/styleguide'
import { startTransition, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState, useRecoilRefresher_UNSTABLE } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import TableListFilter from 'src/components/TableListFilter'
import { convertFormatDate, dateToString } from 'src/helpers/utils'
import {
  getAllForecastData,
  inProductedSecretState,
  periodFilterState,
  scenarioCurrentPageState,
  scenarioListSelector,
  allScenarioListSelector,
  scenarioCreatedDateFilterState,
  scenarioNameFilterState,
  scenarioYearsFilterState,
  scenarioProjectIdState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import { visitedHeaderTabsState } from 'src/stores/LayoutStore'
import Style from '../../styles/ScenariosList.module.sass'
import ScenarioDelete from './ScenarioDelete'
import { ForecastItem } from 'src/models/api/Forecasting'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { NavbarLabelConst } from 'src/constants/LayoutConst'

type SortOrder = 'asc' | 'none' | 'desc'
type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'

interface CustomHeader extends IHeader {
  style: { width: string; textAlign: TextAlign }
}

interface SortConfig {
  key: string
  order: SortOrder
}

const ScenariosList = () => {
  const navigate = useNavigate()
  if (appInsights) appInsights.trackPageView({ name: 'ScenariosList' })
  const SecretState = useRecoilValue(inProductedSecretState)
  const [currentPage, setCurrentPage] = useRecoilState(scenarioCurrentPageState)
  const projectId = useRecoilValue(selectedProjectIdState)
  const setSelectedProjectId = useSetRecoilState(scenarioProjectIdState)
  const scenarioData = useRecoilValue(scenarioListSelector)
  const [scenarioState, setScenarioState] = useState({ scenarios: [], totalPages: 1, totalCount: 0 })
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', order: 'asc' })
  const forecastFilterData = useRecoilValue(getAllForecastData)
  const [nameFilter, setNameFilter] = useRecoilState(scenarioNameFilterState)
  const [periodFilter, setPeriodFilter] = useRecoilState(periodFilterState)
  const [yearsFilter, setYearsFilter] = useRecoilState(scenarioYearsFilterState)
  const [createdDateFilter, setCreatedDateFilter] = useRecoilState(scenarioCreatedDateFilterState)
  const { isShowing, toggleModal } = useModal(false)
  const [deleteScenarioId, setDeleteScenarioId] = useState<string>('')
  const [visitedHeaderTabs, setVisitedHeaderTabs] = useRecoilState(visitedHeaderTabsState)

  const refreshScenarioList = useRecoilRefresher_UNSTABLE(scenarioListSelector)
  const refreshAllScenarioItems = useRecoilRefresher_UNSTABLE(allScenarioListSelector)
  const refreshAllForecastItems = useRecoilRefresher_UNSTABLE(getAllForecastData)
  const isVisitedScenariosTab = visitedHeaderTabs.find((f) => f === NavbarLabelConst.Scenarios)

  useEffect(() => {
    if (!isVisitedScenariosTab) return

    refreshScenarioList()
    refreshAllScenarioItems()
    refreshAllForecastItems()
  }, [refreshScenarioList, refreshAllScenarioItems, refreshAllForecastItems, isVisitedScenariosTab])

  useEffect(() => {
    return () => {
      if (isVisitedScenariosTab) return
      setVisitedHeaderTabs((state) => [...state, NavbarLabelConst.Scenarios])
    }
  }, [setVisitedHeaderTabs, isVisitedScenariosTab])

  useEffect(() => {
    startTransition(() => {
      setSelectedProjectId(projectId)
      if (scenarioData?.['items']) {
        setScenarioState({
          scenarios: scenarioData?.['items'],
          totalPages: scenarioData?.['pages'],
          totalCount: scenarioData?.['total'],
        })
      } else {
        setScenarioState({ scenarios: [], totalPages: 1, totalCount: 0 })
      }
    })
  }, [projectId, scenarioData, setSelectedProjectId])

  const handleShowUpload = useCallback(() => {
    navigate(`/dashboard/${projectId}/createscenario`)
  }, [navigate, projectId])

  const handlePaginationClick = useCallback(
    (page: number) => {
      setCurrentPage(page === 0 ? 1 : page)
    },
    [setCurrentPage],
  )

  const handleSort = (key: string) => {
    setSortConfig((prevSortConfig) => {
      const order = prevSortConfig.key === key && prevSortConfig.order === 'asc' ? 'desc' : 'asc'
      return { key, order }
    })
  }

  const getSortOrder = (key: string, sortConfig: SortConfig): SortOrder | 'none' => {
    return sortConfig.key === key ? sortConfig.order : 'none'
  }

  const commonStyles = (width: string): { width: string; textAlign: TextAlign } => {
    return { width, textAlign: 'left' }
  }

  const sortedScenarios = useMemo(() => {
    const sorted = [...scenarioState.scenarios].sort((a, b) => {
      const itemAValue = a[sortConfig.key]
      const itemBValue = b[sortConfig.key]

      if (sortConfig.order === 'asc') {
        if (itemAValue < itemBValue) return -1
        if (itemAValue > itemBValue) return 1
        return 0
      } else {
        if (itemAValue > itemBValue) return -1
        if (itemAValue < itemBValue) return 1
        return 0
      }
    })
    return sorted
  }, [scenarioState.scenarios, sortConfig])

  const tableHeaders: CustomHeader[] = [
    {
      label: 'Scenario ID',
      sort: getSortOrder('id', sortConfig),
      style: commonStyles('13%'),
      onSort: () => handleSort('id'),
    },
    {
      label: 'Scenario Name',
      sort: getSortOrder('name', sortConfig),
      style: commonStyles('20%'),
      onSort: () => handleSort('name'),
    },
    {
      label: 'Associated Forecast File',
      sort: getSortOrder('forecast_data.file_name', sortConfig),
      style: commonStyles('20%'),
      onSort: () => handleSort('forecast_data.file_name'),
    },
    {
      label: 'Description',
      style: commonStyles('20%'),
    },
    {
      label: 'Create Date',
      sort: getSortOrder('created_at', sortConfig),
      style: commonStyles('15%'),
      onSort: () => handleSort('created_at'),
    },
    {
      label: 'Action',
      style: commonStyles('20%'),
    },
  ]

  const handleDeleteClick = useCallback(
    (scenarioId: string) => {
      setDeleteScenarioId(scenarioId)
      toggleModal()
    },
    [setDeleteScenarioId, toggleModal],
  )

  const handleViewClick = useCallback(
    (scenarioId: string) => {
      navigate(`/dashboard/${projectId}/editscenario/${scenarioId}?step=7`)
    },
    [navigate, projectId],
  )

  const handleEditClick = useCallback(
    (scenarioId: string) => {
      navigate(`/dashboard/${projectId}/editscenario/${scenarioId}`)
    },
    [navigate, projectId],
  )

  const handleCloseModal = () => {
    toggleModal()
  }

  const removeNameFilterItem = (id: number) => {
    setNameFilter((prev: string[]) => {
      const updatedFilters = prev.filter((filterId) => filterId !== id.toString())
      return updatedFilters
    })
  }
  const isScenarioFilterApplied =
    nameFilter.length > 0 || yearsFilter || (createdDateFilter.startDate && createdDateFilter.endDate)

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Scenarios</h1>
            {SecretState && !isRoleEmpty(SecretState.roles) && (
              <Button type='custom' label='Create Scenario' onClick={handleShowUpload} cssClass={Style.btnNewProject} />
            )}
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <section>
          <TableListFilter
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            setYearsFilter={setYearsFilter}
            periodFilter={periodFilter}
            setPeriodFilter={setPeriodFilter}
            createdDateFilter={createdDateFilter}
            setCreatedDateFilter={setCreatedDateFilter}
            pageName='scenarioList'
          />

          <Container fluid>
            <div className={isScenarioFilterApplied ? Style.bottomBorder : ''}>
              {nameFilter.length > 0 && (
                <span className={Style.customNameTextColor}>
                  Forecast File Name
                  {Array.isArray(forecastFilterData) &&
                    forecastFilterData?.map(
                      (item: ForecastItem) =>
                        nameFilter.includes(item.id.toString()) && (
                          <Pill
                            cssClass={Style.marginXY}
                            colour={12}
                            key={item.file_name}
                            onClose={() => removeNameFilterItem(item.id)}
                            closeTitle='Close'
                          >
                            {item.file_name}
                          </Pill>
                        ),
                    )}
                </span>
              )}
              {periodFilter && (
                <span className={Style.customTextColor}>
                  Period
                  <Pill cssClass={Style.marginXY} colour={12} onClose={() => setPeriodFilter('')} closeTitle='Close'>
                    {periodFilter}
                  </Pill>
                </span>
              )}
              {createdDateFilter.startDate && createdDateFilter.endDate && (
                <span className={Style.customNameTextColor}>
                  Updated Date
                  <Pill
                    cssClass={Style.marginXY}
                    colour={12}
                    onClose={() => setCreatedDateFilter({ startDate: null, endDate: null })}
                    closeTitle='Close'
                  >
                    {convertFormatDate(createdDateFilter.startDate) +
                      ' - ' +
                      convertFormatDate(createdDateFilter.endDate)}
                  </Pill>
                </span>
              )}
            </div>
          </Container>
          <Container fluid cssClass={Style.paddingX}>
            <>
              <div className={Style.overflowx}>
                <Table cssClass={Style.tableAlignment} headers={tableHeaders}>
                  {sortedScenarios.length > 0 ? (
                    sortedScenarios.map((item) => (
                      <TableRow key={item['id']}>
                        <TableCell align='left'>{item['id']}</TableCell>
                        <TableCell align='left'>{item['name']}</TableCell>
                        <TableCell align='left' sortableHeader>
                          {item['forecast_data']['file_name']}
                        </TableCell>
                        <TableCell align='left' cellClass={Style.scenariodesc}>
                          <Tooltip
                            show={item['description']}
                            offset={{
                              left: 2,
                              top: 0,
                            }}
                          >
                            <div
                              className={Style.tooltipScenarioDescription}
                              style={{
                                width: '300px',
                                display: 'inline-block',
                              }}
                            >
                              {item['description']}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='left'>{dateToString(item['created_at'])}</TableCell>
                        <TableCell align='left'>
                          <div className={Style.buttonCont}>
                            <Button
                              type='icon-square'
                              cssClass={Style.iconBtn}
                              size='small'
                              icon='visibility'
                              onClick={() => {
                                handleViewClick(item['id'])
                              }}
                            />
                            {SecretState && !isRoleEmpty(SecretState.roles) && (
                              <>
                                <Button
                                  type='icon-square'
                                  icon='edit'
                                  size='small'
                                  onClick={() => {
                                    handleEditClick(item['id'])
                                  }}
                                  cssClass={Style.iconBtn}
                                />
                                <Button
                                  type='icon-square'
                                  cssClass={Style.iconBtn}
                                  size='small'
                                  icon='delete_forever'
                                  onClick={() => {
                                    handleDeleteClick(item['id'])
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              </div>
              <Modal
                isShowing={isShowing}
                onClose={toggleModal}
                size='medium'
                cssClass='modalBackgroundWhite'
                isCloseButton={true}
                isOverlay={true}
                shouldOverlayClose={true}
              >
                <div>
                  <ScenarioDelete scenarioId={Number(deleteScenarioId)} onClose={handleCloseModal} />
                </div>
              </Modal>
              <div className={Style.paginationCont}>
                <div className={Style.paginationButtons}>
                  <Pagination
                    page={currentPage}
                    pageCount={scenarioState.totalPages}
                    displayPages={4}
                    onChange={handlePaginationClick}
                    prevButtonTitle='Previous page'
                    nextButtonTitle='Next page'
                    cssClass={Style.paginatCont}
                  />
                </div>
                <div className={Style.totalPageCount}>
                  {currentPage} - {scenarioState.totalPages} of {scenarioState.totalCount}
                </div>
              </div>
            </>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default ScenariosList
