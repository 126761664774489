export const SUCCESS_COLOR = '#00BD39'
export const IN_PROGRESS_COLOR = '#14A4DE'
export const FAILED_COLOR = '#DA3B20'

export const AverageContingencyFCASPriceChartColors = [
  '#A5A5A5',
  '#FFBF00',
  '#5B9BD5',
  '#71AD47',
  '#264478',
  '#9E480E',
  '#636363',
  '#997200',
]
export const AverageRegulationFCASPriceChartColors = ['#4472C4', '#ED7D31']

export const AverageRegulationFCASPriceChartBG = ['#4472C4', '#ED7D31']

export const BatteryMarketDispatchStateChargeProfileColors = [
  {
    borderColor: 'rgba(75, 192, 192, 1)',
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
  },
  {
    borderColor: 'rgba(153, 102, 255, 1)',
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
  },
  {
    borderColor: 'rgba(255, 159, 64, 1)',
    backgroundColor: 'rgba(255, 159, 64, 0.2)',
  },
  {
    borderColor: 'rgba(255, 99, 132, 1)',
    backgroundColor: 'rgba(255, 99, 132, 0.2)',
  },
]

export const EnergyBreakdownBarChartColors = {
  boyStorage: '#1750BD',
  eoyStorage: '#42B8D2',
}

export const FCASRevenueChartColors = {
  r1: '#BFECFF',
  r6: '#3FC6FF',
  r60: '#7ED9FF',
  r5: '#EFCAE7',
  l1: '#ffdfbf',
  l6: '#ffbf7e',
  l60: '#DF96CF',
  l5: '#CF61B6',
  reg_raise: '#00648F',
  reg_lower: '#611E52',
}

export const PreferredScenarioReportColors = { positive: '#1AAB40', negative: '#D64554', total: '#118DFF' }
export const PreferredScenarioDashboardColors = { positive: '#363D30', negative: '#7BB801', total: '#118DFF' }

export const SampleDayFCASPriceColors = [
  '#4472C4',
  '#CC6502',
  '#BFECFF',
  '#ffdfbf',
  '#FFBF00',
  '#0070C0',
  '#71AD47',
  '#264478',
  '#7030A0',
  '#06B050',
]

export const SampleDispatchProfilesColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
]

export const WholesaleArbitragePriceSpreadChartColors = {
  generationWeightedAvgPrice: '#CF016B',
  weightedAvgPriceForCharging: '#97999B',
  timeWeightedPrice: '#06B0F0',
  transparent: 'transparent',
}

export const WholesaleEnergyPriceChartColors = {
  lineColor: '#118DFF',
}
