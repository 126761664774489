import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { NpvChart } from 'src/models/api/ReportMappingResponseModel'
import PreferredScenarioResultsChart from './PreferedScenarioResultsChart'
import { getCumulativeReportData } from 'src/helpers/chartUtils'

interface WaterfallChartDataProcessorProps {
  data: NpvChart
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessPreferredScenarioChart: React.FC<WaterfallChartDataProcessorProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const cumulativeData = useMemo(() => getCumulativeReportData(data), [data])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId)
            ? { ...section, chart_data: JSON.stringify(cumulativeData) }
            : section,
        ),
      },
    }))
  }, [cumulativeData, setFormData, sectionId])

  return <PreferredScenarioResultsChart cumulativeData={cumulativeData} pageType='report' />
}

export default ProcessPreferredScenarioChart
