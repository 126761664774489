import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'
import { WholesaleArbitragePriceSpreadChartColors } from 'src/config/colors'

interface WholesaleArbitragePriceSpreadChartProps {
  data: {
    labels: string[]
    datasets: {
      label: string
      data: number[]
    }[]
  }
}

const WholesaleArbitragePriceSpreadChart: React.FC<WholesaleArbitragePriceSpreadChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart | null>(null)

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx) return

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    const colorMapping = {
      'Generation Weighted Avg Price': WholesaleArbitragePriceSpreadChartColors.generationWeightedAvgPrice,
      'Weighted Avg Price for Charging ': WholesaleArbitragePriceSpreadChartColors.weightedAvgPriceForCharging,
      'Time Weighted Price': WholesaleArbitragePriceSpreadChartColors.timeWeightedPrice,
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: data.datasets.map((dataset) => ({
          ...dataset,
          borderColor: colorMapping[dataset.label as keyof typeof colorMapping],
          backgroundColor: WholesaleArbitragePriceSpreadChartColors.transparent,
          tension: 0.4,
        })),
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: '',
              color: 'black',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
          },
          y: {
            title: {
              display: true,
              text: '$/MWh',
              color: 'black',
              font: {
                weight: 'bold',
                size: 16,
              },
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          datalabels: {
            display: false,
          },
        },
      },
    })

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [data])

  return <canvas ref={chartRef} width='600' height='400'></canvas>
}

export default WholesaleArbitragePriceSpreadChart
