import { useNavigate } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { projectDataSelector } from 'src/stores/AppStore'
import { deleteProject } from '../api/ProjectService'
import { useToast } from '@aurecon-creative-technologies/styleguide'

interface DeleteProjectProps {
  projectId?: string
  onClose: () => void
}
const DeleteProject: React.FC<DeleteProjectProps> = ({ projectId, onClose }) => {
  if (appInsights) appInsights.trackPageView({ name: 'DeleteProjects' })
  const navigate = useNavigate()
  const showToast = useToast()

  const refreshFetchItems = useRecoilRefresher_UNSTABLE(projectDataSelector)
  const handleCancel = () => {
    onClose()
  }

  const handleDelete = async () => {
    if (projectId) {
      const response = await deleteProject(projectId)
      if (response) {
        showToast.addToast({
          type: 'success',
          message: `Project deleted successfully`,
          timeout: 3000,
        })
        refreshFetchItems()
        navigate('/projects')
      } else {
        showToast.addToast({
          type: 'error',
          message: `Project deleted failed`,
          timeout: 3000,
        })
      }
    }
    onClose()
  }
  return (
    <ConfirmationModal
      title='Delete Project'
      message='Are you sure you want to delete this project?'
      onCancel={handleCancel}
      onConfirm={handleDelete}
    />
  )
}

export default DeleteProject
