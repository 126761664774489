import { ChartConfiguration } from 'chart.js/auto'
import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { ChartSampleDispatchProfiles, SampleDispatchProfiles } from 'src/models/api/ReportMappingResponseModel'
import { updateChartDataInForm } from 'src/api/ReportService'
import SampleStateChargeProfilesChart from './SampleStateChargeProfilesChart'
import { getDateFromIntervalString } from 'src/helpers/utils'
import { groupBy } from 'lodash'

interface SampleStateChargeProfilesDataProcessorProps {
  data: SampleDispatchProfiles[]
  sectionId: string
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
}

const consolidateDailyAverages = (data: SampleDispatchProfiles[]): ChartSampleDispatchProfiles[] => {
  const groupDatas = groupBy(data, (item: SampleDispatchProfiles) => getDateFromIntervalString(item.datetime))

  return Object.keys(groupDatas).map((i) => ({
    datetime: new Date(i),
    energy_load: 0,
    energy_gen: 0,
    rreg: 0,
    lreg: 0,
    day: new Date(i).getDate(),
    soc: groupDatas[i]?.length
      ? groupDatas[i]
          .map((i: SampleDispatchProfiles) => i.soc)
          .reduce((a: number, b: number) => a + parseFloat(b.toString()), 0) / groupDatas[i].length
      : 0,
    mw_net: groupDatas[i]?.length
      ? groupDatas[i]
          .map((i: SampleDispatchProfiles) => i.mw_net)
          .reduce((a: number, b: number) => a + parseFloat(b.toString()), 0) / groupDatas[i].length
      : 0,
  }))
}

const generateLabels = (data: ChartSampleDispatchProfiles[]): string[] =>
  data.map((item) => new Date(item.datetime).toLocaleDateString())

const generateDatasets = (data: ChartSampleDispatchProfiles[]): ChartConfiguration<'line'>['data']['datasets'] => {
  const keys: Array<keyof SampleDispatchProfiles> = ['soc', 'mw_net']

  const labelsForKeys = ['SOC', 'MW Net']

  return keys.map((key, index) => ({
    label: labelsForKeys[index],
    data: data.map((item) => Number(item[key]) || 0),
    fill: false,
    tension: 0.4,
  }))
}

const ProcessSampleStateChargeProfilesData: React.FC<SampleStateChargeProfilesDataProcessorProps> = ({
  data,
  sectionId,
  setFormData,
}) => {
  const consolidatedData = useMemo(() => consolidateDailyAverages(data), [data])

  const chartData = useMemo(() => {
    return {
      labels: generateLabels(consolidatedData),
      datasets: generateDatasets(consolidatedData),
    }
  }, [consolidatedData])

  useEffect(() => {
    updateChartDataInForm(setFormData, sectionId, 'chart1', chartData)
  }, [chartData, sectionId, setFormData])

  return <SampleStateChargeProfilesChart data={chartData} />
}

export default ProcessSampleStateChargeProfilesData
