import { IRadioItem } from '@aurecon-creative-technologies/styleguide'

export enum ProjectInput {
  COMMERCIAL_PARAMETERS = 'Commercial Parameters',
  VALUE = 'Value',
}

export enum ScenarioCRUDType {
  CREATE = 1,
  UPDATE = 2,
}

export enum BessExpectedPerformanceCyclePerDay {
  YEAR = 'Year',
  OPERATING_YEAR = 'Operating Year',
  ENERGYDISCHARGECAPACITYRETENTION = 'Energy discharge capacity retention at nominated cycling level (%)',
  RTE = 'RTE at nominated cycling level (%)',
  GENERATION_EFFICIENCY = 'Generation Efficiency',
  AC_EFFICIENCY = 'Grid to BESS RTE',
  CROSS_TRANSFER_EFFICIENCY = 'Cross Transfer Efficiency',
  DC_EFFICIENCY = 'DC charging efficiency',
}

export enum BessPowerEfficiency {
  GENERATION_EFFICIENCY = 'Generation Efficiency',
  AC_EFFICIENCY = 'Grid to BESS RTE',
  CROSS_TRANSFER_EFFICIENCY = 'Cross Transfer Efficiency',
  DC_EFFICIENCY = 'DC Charging efficiency',
}

export enum LossFactoryTableHeader {
  OPERATING_YEAR = 'Operating Year',
  GENERATION_MLF = 'Generation MLF',
  LOAD_MLF = 'Load MLF',
  NMsValue = 'NMS Value ($/annum)',
}
export enum NmsTableHeader {
  OPERATING_YEAR = 'Operating Year',
  NMSVALUE = 'NMS Value ($/annum)',
}

export enum BessCyclingCorrectionFactorTable {
  YEAR = 'Year',
  OPERATING_YEAR = 'Operating Year',
  CORRECTION_FACTOR_0_5 = 'Correction factor 0.5 cycling (%)',
  CORRECTION_FACTOR_1 = 'Correction factor 1 cycling (%)',
  CORRECTION_FACTOR_1_5 = 'Correction factor 1.5 cycling (%)',
  CORRECTION_FACTOR_2 = 'Correction factor 2 cycling (%)',
}

export enum CapexValue {
  CAPEX = 'Capex',
  YEAR = 'Year',
  VALUE = 'Value ($)',
  BESS = 'BESS ($)',
  REGENERATOR = 'RE Generator ($)',
  COMMONEQUIPMENT = 'Common Equipment ($)',
}

export enum RepexValue {
  REPEX = 'Repex',
  YEAR = 'Year',
  VALUE = 'Value ($)',
}
export enum QuarterCapVolume {
  YEAR = 'Operating Year',
  Q1_CAP_VOLUME = 'Q1 Cap Volume (MW)',
  Q1_CAP_PRICE = 'Q1 Cap Price ($/MW.h)',
  Q1_STRIKE_PRICE = 'Q1 Strike Price ($/MW.h)',
  Q2_CAP_VOLUME = 'Q2 Cap Volume (MW)',
  Q2_CAP_PRICE = 'Q2 Cap Price ($/MW.h)',
  Q2_STRIKE_PRICE = 'Q2 Strike Price ($/MW.h)',
  Q3_CAP_VOLUME = 'Q3 Cap Volume (MW)',
  Q3_CAP_PRICE = 'Q3 Cap Price ($/MW.h)',
  Q3_STRIKE_PRICE = 'Q3 Strike Price ($/MW.h)',
  Q4_CAP_VOLUME = 'Q4 Cap Volume (MW)',
  Q4_CAP_PRICE = 'Q4 Cap Price ($/MW.h)',
  Q4_STRIKE_PRICE = 'Q4 Strike Price ($/MW.h)',
}
export enum QuarterCapVolumeStep7 {
  Q1_STRIKE_PRICE = 'Q1 Strike Price ($/MW.h)',
  Q2_STRIKE_PRICE = 'Q2 Strike Price ($/MW.h)',
  Q3_STRIKE_PRICE = 'Q3 Strike Price ($/MW.h)',
  Q4_STRIKE_PRICE = 'Q4 Strike Price ($/MW.h)',
}

export enum ProjectInputLgc {
  OPERATING_YEAR = 'Operating Year',
  YEAR = 'Year',
  BESSLTSA = 'BESS LTSA',
  REGLTSA = 'RE Generator LTSA',
  LTSA_MANUAL = 'BESS LTSA ($)',
  WARRANTY_MANUAL = 'Extended Warranty ($)',
  TUOS = 'TUOS ($)',
  LGC = 'LGC Price ($/MW.hr)',
  OTHER = 'Other O & M ($)',
  LGC_FRACTION = 'LGC Fraction (%)',
  LTSA_CALCULATED = 'LTSA Calculated',
  WARRANTY_CALCULATED = 'Warranty Calculated',
  Defaultwarrantedcycling = 'Default Warranted Cycling (Cycle per day)',
}
export enum InertiaContract {
  OPERATING_YEAR = 'Operating Year',
  CONTRACTED_INERTIA_VALUE = 'Contracted Inertia value ($/annum)',
}

export enum SipsContract {
  OPERATING_YEAR = 'Operating Year',
  SIPS_RESERVED_POWER_CAPACITY = 'SIPS Reserved Power Capacity (MW)',
  SIPS_RESERVED_ENERGY_STORAGE_CAPACITY = 'SIPS Reserved Energy Storage Capacity (MWh)',
  SIPS_CONTRACT_VALUE = 'SIPS Contract Value',
}

export enum NonMarket {
  OPERATING_YEAR = 'Operating Year',
  DISCHARGE_POWER_RESERVATION = 'Discharge Power Reservation for Non-Market Services (MW)',
  CHARGE_POWER_RESERVATION = 'Charge Power Reservation for Non-Market Services (MW)',
  ENERGY_RESERVATION = 'Energy Reservation for Non-Market Services (MW)',
}

export enum SrasContract {
  OPERATING_YEAR = 'Operating Year',
  SRAS_RESERVED_ENERGY_STORAGE_CAPACITY = 'SRAS Reserved Energy Storage Capacity (MWh)',
  SRAS_CONTRACT_VALUE = 'SRAS Contract Value',
}

export enum VcasContract {
  OPERATING_YEAR = 'Operating Year',
  REAL_POWER_CAPABILITY_VCAS = 'Real Power Capability When Providing VCAS (MW)',
  VCAS_CONTRACT_VALUE = 'VCAS Contract Value',
}

export const ScenarioErrorMessageConfig = {
  step1: [
    { name: 'project_title', requested: true, message: 'Project name is required' },
    { name: 'project_life_years', requested: true, message: 'Project life years is required' },
    {
      name: 'first_year_of_commercial_operation',
      requested: true,
      message: 'First year of commercial operation is required',
    },
    { name: 'inverter_capacity', requested: true, message: 'Inverter capacity is required' },
  ],
  step2: [
    { name: 'r1_max_registered_capacity', requested: true, message: 'CFCAS Market (R1) is required' },
    { name: 'r6_max_registered_capacity', requested: true, message: 'CFCAS Market (R6) is required' },
    { name: 'r60_max_registered_capacity', requested: true, message: 'CFCAS Market (R60) is required' },
    { name: 'r5_max_registered_capacity', requested: true, message: 'CFCAS Market (R5) is required' },
    { name: 'l1_max_registered_capacity', requested: true, message: 'CFCAS Market (L1) is required' },
    { name: 'l6_max_registered_capacity', requested: true, message: 'CFCAS Market (L6) is required' },
    { name: 'l60_max_registered_capacity', requested: true, message: 'CFCAS Market (L60) is required' },
    { name: 'l5_max_registered_capacity', requested: true, message: 'CFCAS Market (L5) is required' },
    { name: 'reg_raise_max_enablement', requested: true, message: 'Market (Reg raise) is required' },
    { name: 'reg_lower_max_enablement', requested: true, message: 'Market (Reg lower) is required' },
    { name: 'r1_market_elasticity', requested: true, message: 'Market (R1) is required' },
    { name: 'r6_market_elasticity', requested: true, message: 'Market (R6) is required' },
    { name: 'r60_market_elasticity', requested: true, message: 'Market (R60) is required' },
    { name: 'r5_market_elasticity', requested: true, message: 'Market (R5) is required' },
    { name: 'l1_market_elasticity', requested: true, message: 'Market (L1) is required' },
    { name: 'l60_market_elasticity', requested: true, message: 'Market (L6) is required' },
    { name: 'l5_market_elasticity', requested: true, message: 'Market (L60) is required' },
    { name: 'l6_market_elasticity', requested: true, message: 'Market (L5) is required' },
  ],
  step3: [{ name: 'strike_price', requested: true, message: 'Strike price is required' }],
  step4: [],
  step5: [
    { name: 'standby_power_consumption', requested: false, message: 'Standby power consumption is required' },
    { name: 'default_warrant_cycle', requested: false, message: 'Default warranted cycling is required' },
  ],
  step6: [
    {
      name: 'o_and_m_inflation_rate',
      requested: false,
      message: 'O&M + Repex Inflation Rate (annual, real basis) is required',
    },
    { name: 'pop_wholesale_energy_generation', requested: false, message: 'Wholesale Energy Generation is required' },
    { name: 'pop_wholesale_energy_load', requested: false, message: 'Wholesale Energy Load is required' },
    { name: 'pop_reg_fcas', requested: false, message: 'Percent of Perfect - Reg FCAS is required' },
    { name: 'pop_contingency_fcas', requested: false, message: 'Percent of Perfect - Contingency FCAS is required' },
    { name: 'standard_inflation_rate', requested: false, message: 'Standard Inflation Rate is required' },
    { name: 'npv_discount_rate', requested: false, message: 'Discount rate is required' },
    { name: 'total_project_cost_uplift', requested: false, message: 'Total Project Cost is required' },
  ],
}

export const hybridGenerationType: IRadioItem[] = [
  {
    id: '1',
    label: 'Wind',
  },
  {
    id: '2',
    label: 'Solar',
  },
]

export const coupledTypeId = {
  acCoupled: '1',
  dcCoupled: '2',
}

export const coupledType: IRadioItem[] = [
  {
    id: coupledTypeId.dcCoupled,
    label: 'DC Coupled',
  },
  {
    id: coupledTypeId.acCoupled,
    label: 'AC Coupled',
  },
]
