import React from 'react'
import sanitizeHtml from 'sanitize-html'

interface SafeHtmlProps {
  html: string
}

const SafeHtml: React.FC<SafeHtmlProps> = ({ html }) => {
  const cleanHtml = sanitizeHtml(html, {
    allowedTags: [
      'b',
      'i',
      'strike',
      'u',
      'blockquote',
      'a',
      'img',
      'ol',
      'ul',
      'li',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'br',
      'span',
      'strong',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
      img: ['src', 'alt'],
      span: ['style'],
      '*': ['style'],
    },
    parseStyleAttributes: false,
  })

  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
}

export default SafeHtml
