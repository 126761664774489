import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Style from '../../../styles/Reports.module.sass'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'

interface TextEditorProps {
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
  formData: GenerateReportRequest
}
const TextEditor: React.FC<TextEditorProps> = ({ setFormData, sectionId, formData }) => {
  const [value, setValue] = useState('')
  const toolbarOptions = [
    ['bold', 'italic', 'strike', 'underline'],
    [{ list: 'bullet' }, { list: 'ordered' }, 'blockquote'],
    ['link', 'image'],
    [{ size: ['small', false, 'large', 'huge'] }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ]
  const module = {
    toolbar: toolbarOptions,
  }

  useEffect(() => {
    const section = formData.report_detail.report_section.find((sec) => sec.section_id === Number(sectionId))
    if (section) {
      setValue(section.formatted_comment || '')
    }
  }, [formData.report_detail.report_section, sectionId])

  const onChange = (value: string) => {
    setValue(value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId) ? { ...section, formatted_comment: value } : section,
        ),
      },
    }))
  }
  return (
    <div className={Style.textEditor}>
      <ReactQuill className='react-quill' modules={module} theme='snow' value={value} onChange={onChange} />
    </div>
  )
}

export default TextEditor
