import { Container, IHeader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { QuarterCapVolume } from 'src/enums/ScenarioEnums'
import { IRRNVPModalData } from 'src/models/api/IRRNPVCalcResponseModel'
import Style from '../../styles/Execution.module.sass'

interface NVPModelProps {
  modalData: IRRNVPModalData[]
  label: string
}

const NVPmodel = ({ modalData, label }: NVPModelProps) => {
  const tableHeaders: IHeader[] = [
    {
      label: QuarterCapVolume.YEAR,
    },
    {
      label: label,
    },
  ]
  return (
    <Container fluid>
      <h4 className={Style.noBottomPadding}>{label}</h4>
      <div className={`${Style.tbl_container}`}>
        <Table headers={tableHeaders}>
          {modalData.map((row) => (
            <TableRow key={row.year}>
              <TableCell>
                <p className={Style.cell_content}>{row.year}</p>
              </TableCell>
              <TableCell>
                <p className={Style.cell_content}>{row.present_value}</p>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default NVPmodel
