import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide'
import { useAuth0 } from '@auth0/auth0-react'
import { FC, Suspense, useRef, useState } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import LoadingScreen from './components/LoadingScreen'
import Page from './components/Page'
import TermsCookiesModal from './components/TermsCookiesModal'
import Consent from './pages/Consent'
import CookiesPolicy from './pages/CookiesPolicy'
import Home from './pages/Home'
import Swagger from './pages/Swagger'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Profile from './pages/Profile'
import TermsAndConditions from './pages/TermsAndConditions'

import NotFoundImage from './assets/NotFound.svg'

import Layout from './components/Layout'
import CreateEditProject from './pages/CreateEditProject'
import CreateScenario from './pages/CreateScenario'
import Dashboard from './pages/Dashboard'
import EditScenario from './pages/EditScenario'
import ExecutionsList from './pages/Execution/ExecutionList'
import ExecutionsDetail from './pages/Execution/ExecutionsDetail'
import IRRNVPCalculation from './pages/Execution/IRRNVPCalculation'
import ForecastingList from './pages/Forecasting/ForecastingList'
import UploadForecast from './pages/Forecasting/UploadForecast'
import ProjectDashboard from './pages/ProjectDashboard/ProjectDashboard'
import Projects from './pages/Projects'
import GenerateReport from './pages/Reports/GenerateReport'
import ReportDetails from './pages/Reports/ReportDetails'
import ReportsList from './pages/Reports/ReportsList'
import UserSettings from './pages/UserSettings'
import ScenariosList from './pages/scenario/ScenariosList'
import Style from './styles/App.module.sass'
import GenerateProfile from './pages/Forecasting/GenerateProfile'

const App: FC = () => {
  const modalContentRef = useRef<HTMLDivElement>(null)
  const { isLoading, error, isAuthenticated } = useAuth0()
  const [isConsented, setIsConsented] = useState(false)

  if (error) {
    return (
      <div className={Style.errorWrapper}>
        <div className={Style.innerWrapper}>
          <p>Aurecon Template</p>
          <p>
            <img src={NotFoundImage} alt='Error' />
          </p>
          <p>Oops... Looks like we have an issue...</p>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <RecoilRoot>
        <Page>
          <LoadingScreen text='Loading application...' />
        </Page>
      </RecoilRoot>
    )
  }

  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  const publicRoutes = [
    <Route key='home_public' path='/login' element={<Login />} />,
    <Route
      key='terms'
      path='/termsandconditions'
      element={<TermsAndConditions scrollToTop={scrollToTop} isModal={false} />}
    />,
    <Route key='privacy' path='/privacy' element={<PrivacyPolicy scrollToTop={scrollToTop} isModal={false} />} />,
    <Route key='cookies' path='/cookies' element={<CookiesPolicy />} />,
  ]

  const privateRoutes = [
    <Route key='home' path='/' element={<Home />} />,
    <Route key='swagger' path='/swagger' element={<Swagger />} />,
    <Route key='projects' path='/projects' element={<Projects />} />,
    <Route key='createprojects' path='/createproject' element={<CreateEditProject isEdit={false} />} />,
    <Route key='editprojects' path='/editproject/:projectId' element={<CreateEditProject isEdit={true} />} />,
    <Route key='dashboard' path='/dashboard/:projectId' element={<Dashboard />}>
      <Route key='projectDashboard' path='' element={<ProjectDashboard />} />
      <Route key='forecasting' path='forecasting' element={<ForecastingList />} />,
      <Route key='forecastingupload' path='forecastingupload' element={<UploadForecast isEdit={false} />} />,
      <Route
        key='forecastinguploadprofile'
        path='forecastinguploadprofile'
        element={<GenerateProfile isEdit={false} />}
      />
      ,
      <Route
        key='forecastinguploadprofile'
        path='forecastinguploadprofile/:forecastId'
        element={<GenerateProfile isEdit={true} />}
      />
      ,
      <Route key='forecastingedit' path='forecastingedit/:forecastId' element={<UploadForecast isEdit={true} />} />,
      <Route key='scenario' path='scenario' element={<ScenariosList />} />,
      <Route key='createscenario' path='createscenario' element={<CreateScenario />} />,
      <Route key='editscenario' path='editscenario/:scenarioId' element={<EditScenario />} />,
      <Route key='executionlist' path='executionlist' element={<ExecutionsList />} />,
      <Route key='executiondetails' path='executiondetail/:scenario_id' element={<ExecutionsDetail />} />,
      <Route key='executionirrnvp' path='executionirrnvp/:executionId' element={<IRRNVPCalculation />} />,
      <Route key='reportlist' path='reportlist' element={<ReportsList />} />,
      <Route key='generatereport' path='generatereport' element={<GenerateReport mode='generate' />} />,
      <Route key='clonereport' path='clonereport/:report_Id' element={<GenerateReport mode='clone' />} />,
      <Route key='reportdetail' path='reportdetail/:report_Id/:project_type' element={<ReportDetails />} />,
      <Route key='profile' path='profile' element={<Profile />} />
      <Route key='usersettings' path='/dashboard/:projectId/profile' element={<Profile />} />
    </Route>,
    <Route key='settings' path='/settings' element={<UserSettings />} />,
    <Route key='profile' path='/profile' element={<Profile />} />,
  ]

  if (!isAuthenticated) {
    return (
      <RecoilRoot>
        <HashRouter>
          <Routes>
            {publicRoutes}
            <Route path='*' element={<Navigate to='/login' state={location} />} />
          </Routes>
        </HashRouter>
      </RecoilRoot>
    )
  }

  if (!isConsented) {
    return (
      <RecoilRoot>
        <Consent
          setConsented={() => {
            setIsConsented(true)
          }}
        />
      </RecoilRoot>
    )
  }

  // In the case with two routes with the same path, private will override public
  return (
    <RecoilRoot>
      <Suspense
        fallback={
          <Page>
            <LoadingScreen text='Loading application...' />
          </Page>
        }
      >
        <ToastProvider>
          <HashRouter>
            <Layout>
              <Routes>
                {privateRoutes}
                {publicRoutes}
                <Route path='*' element={<NotFound />} />
              </Routes>
            </Layout>
            <TermsCookiesModal />
          </HashRouter>
          <Toast />
        </ToastProvider>
      </Suspense>
    </RecoilRoot>
  )
}

export default App
