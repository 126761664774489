import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Icon } from '@aurecon-creative-technologies/styleguide'

import config from '../config/config'
import { appInsights } from '../api/AppInsights'

import Style from '../styles/Profile.module.sass'

const Profile: FC = () => {
  const { user, logout } = useAuth0()
  if (appInsights) appInsights.trackPageView({ name: 'Profile' })

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ returnTo })
  }

  return (
    <div className={Style.layoutContainer}>
      <p style={{ marginTop: '32px' }}>
        <Icon type='person' size='64px' />
      </p>
      <p>{user?.email} (User)</p>
      <Button type='custom' cssClass={Style.logoutBtn} label='Logout' width='300px' onClick={handleLogout} />
    </div>
  )
}

export default Profile
