import { format } from 'date-fns'

export const validateEmail = (email: string): boolean => {
  const re = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+$/
  return re.test(String(email).toLowerCase())
}
export type SortOrder = 'asc' | 'none' | 'desc'
export const validateAlphaNumeric = (value: string): boolean => /^[a-zA-Z0-9\s]+$/.test(String(value))
export const validateAlphaNumericUnderscoreDash = (value: string): boolean => /^[a-zA-Z0-9-_]+$/.test(String(value))

export const validateSpecialCharacters = (value: string): boolean => !/^[a-zA-Z0-9\-_,.\s]+$/.test(value)

export const isAureconUser = (email: string): boolean => {
  if (!email?.includes('@')) return false
  return email.split('@')[1].toUpperCase() === 'AURECONGROUP.COM'
}

export const validateUrl = (url: string): boolean => {
  try {
    new URL(url)
  } catch (e) {
    console.info('Invalid url - validation failed.')
    return false
  }
  return true
}

export const getUniqueObjects = <T>(array: Array<T>, key: string): T[] => {
  return array.filter((obj, i, self) => self.findIndex((obj2) => obj2[key] === obj[key]) === i)
}

export const getInternalNameFromEmail = (email: string): string => {
  const parts = email.toLowerCase().split('@')
  const nameParts = parts[0].split('.')
  const name = nameParts.map((n) => n.slice(0, 1).toUpperCase() + n.slice(1))
  return name.join(' ')
}

export interface ISyncResult<T> {
  unchanged: Array<T>
  remove: Array<T>
  create: Array<T>
}

export const arraySync = <T>(arrayOne: Array<T>, arrayTwo: Array<T>, key: string): ISyncResult<T> => {
  const result: ISyncResult<T> = {
    unchanged: [],
    remove: [],
    create: [],
  }

  // Items to remove
  arrayOne.forEach((a1) => {
    let remove = true
    arrayTwo.forEach((a2) => {
      if (a1[key] === a2[key]) remove = false
    })

    if (remove) result.remove.push(a1)
    else result.unchanged.push(a1)
  })

  // Items to create
  arrayTwo.forEach((a2) => {
    let create = true
    arrayOne.forEach((a1) => {
      if (a1[key] === a2[key]) create = false
    })

    if (create) result.create.push(a2)
  })

  return result
}

export interface IDifferenceResult<T> {
  unchanged: Array<T>
  changed: Array<T>
}

export const findDifference = <T>(
  arrayOne: Array<T>,
  arrayTwo: Array<T>,
  uniqueKey: string,
  diffKey: string,
): IDifferenceResult<T> => {
  const result: IDifferenceResult<T> = {
    unchanged: [],
    changed: [],
  }

  for (const arrayOneValue of arrayOne) {
    const match = arrayTwo.find((element) => arrayOneValue[uniqueKey] === element[uniqueKey])
    if (match) {
      if (arrayOneValue[diffKey] === match[diffKey]) result.unchanged.push(arrayOneValue)
      else result.changed.push(match)
    } else result.unchanged.push(arrayOneValue)
  }

  return result
}

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value))
}

export const unixDateToString = (time: number): string => {
  const dateObject = new Date(Number(time))
  return dateObject.toLocaleString('en-AU', { year: 'numeric', month: 'numeric', day: 'numeric' })
}

export const unixDateTimeToString = (time: number): string => {
  const dateObject = new Date(Number(time))
  return dateObject.toLocaleString('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const truncateFileName = (name: string, length: number): string => {
  const temp = name.split('.')
  const filename = temp.slice(0, -1).join('.').slice(0, length)
  return `${filename}.${temp[temp.length - 1]}`
}

export const getReadableSize = (size: number | string): string => {
  let fileSizeIndex = 0
  let calculatedSize = Number(size)

  while (calculatedSize > 1024) {
    calculatedSize = calculatedSize / 1024
    fileSizeIndex++
  }

  return `${calculatedSize.toFixed(2)} ${fileSizes[fileSizeIndex]}`
}

const fileSizes = ['B', 'KB', 'MB', 'GB', 'TB']

export const IsNullOrUndefinedString = (value: string): boolean => {
  return (
    value === null ||
    value.toLowerCase() === 'null' ||
    value === undefined ||
    value.toLowerCase() === 'undefined' ||
    value.trim() === ''
  )
}

export const dateToString = (value: Date | string | number, dateFormat?: string): string => {
  try {
    return format(value instanceof Date ? value : new Date(value), dateFormat ?? 'dd/MM/yyyy')
  } catch (error) {
    return 'invalid date'
  }
}

export const titleToUrl = (value: string): string => {
  return value.toLowerCase().replace(/\s/g, '-')
}

export const sizeOf = function (bytes: number): string {
  if (bytes === 0) {
    return '0.00 B'
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${' KMGTP'.charAt(e)}B`
}

export const findFirstDiff = (str1: string, str2: string): number =>
  [...str1].findIndex((el, index) => el !== str2[index])

export type ISortable = string | number | Date // | null
export type ISortDirection = 'desc' | 'asc' | 'none' | undefined
interface ISortKey<T extends Record<string | number | symbol, unknown>, K extends keyof T, V extends ISortable> {
  sortKey: K
  direction?: ISortDirection
  convert?: (val: unknown) => V
}

export const compare = <T>(a: T, b: T, dir?: ISortDirection, convert?: (val: unknown) => ISortable): number => {
  if (dir === 'none') return 0
  const sortVal = dir === 'desc' ? -1 : 1
  if (convert) {
    if (convert(a) > convert(b)) return sortVal
    if (convert(a) < convert(b)) return -sortVal
  } else {
    if (a > b) return sortVal
    if (a < b) return -sortVal
  }
  return 0
}
// eslint-disable-next-line
export const sorting = <T extends Record<string | number | symbol, any>, K extends keyof T, V extends ISortable>(
  a: T | null,
  b: T | null,
  keys: ISortKey<T, K, V>[],
): number => {
  return keys.reduce(
    (p, k) => (!p ? compare((a && a[k.sortKey]) || '', (b && b[k.sortKey]) || '', k.direction, k.convert) : p),
    0,
  )
}

export const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(true), time)
  })

export const getCurrentDateISOString = (): string => {
  return new Date().toISOString()
}

export const formatDate = (dateString: string): string => {
  // Split the date string by "/"
  const parts = dateString.split('/')
  // Rearrange the parts to form the desired format "YYYY-MM-DD"
  return `${parts[2]}-${parts[1]}-${parts[0]}`
}

export const generateUniqueString = (): string => {
  const array = new Uint32Array(1)
  window.crypto.getRandomValues(array)
  const randomNumber = ((array[0] % 900000) + 100000).toString()
  return `${randomNumber}`
}

export const getRandomValue = (min: number, max: number): number => {
  const array = new Uint32Array(1)
  window.crypto.getRandomValues(array)
  const randomValue = array[0] / (0xffffffff + 1) // Normalize to [0, 1)
  return Math.floor(randomValue * (max - min + 1) + min)
}

export const convertFormatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}

export const compareValues = (itemAValue: string | number, itemBValue: string | number, order: SortOrder) => {
  const dateA = new Date(itemAValue).getTime()
  const dateB = new Date(itemBValue).getTime()
  const isDateComparison = !isNaN(dateA) && !isNaN(dateB)

  if (isDateComparison) {
    return order === 'asc' ? dateA - dateB : dateB - dateA
  }
  if (order === 'asc') {
    if (itemAValue < itemBValue) return -1
    if (itemAValue > itemBValue) return 1
  } else {
    if (itemAValue > itemBValue) return -1
    if (itemAValue < itemBValue) return 1
  }
  return 0
}

export const getDateFromIntervalString = (date: string) => {
  const datePart = date.substring(0, 10).split('-')
  return datePart[1] + '-' + datePart[2] + '-' + datePart[0]
}
