import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { WholesaleArbitragePriceSpread } from 'src/models/api/ReportMappingResponseModel'
import WholesaleArbitragePriceSpreadChart from './WholesaleArbitragePriceSpreadchart'

interface ProcessWholesaleArbitragePriceSpreadChartProps {
  data: WholesaleArbitragePriceSpread[]
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessWholesaleArbitragePriceSpreadChart: React.FC<ProcessWholesaleArbitragePriceSpreadChartProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const processedData = useMemo(() => {
    return {
      labels: data.map((item) => item.year.toString()),
      datasets: [
        {
          label: 'Generation Weighted Avg Price',
          data: data.map((item) => item.generation_weighted_avg_price),
        },
        {
          label: 'Weighted Avg Price for Charging',
          data: data.map((item) => item.weighted_avg_price_for_charging),
        },
        {
          label: 'Time Weighted Price',
          data: data.map((item) => item.time_weighted_price),
        },
      ],
    }
  }, [data])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId)
            ? { ...section, chart_data: JSON.stringify(processedData) }
            : section,
        ),
      },
    }))
  }, [processedData, setFormData, sectionId])

  return <WholesaleArbitragePriceSpreadChart data={processedData} />
}

export default ProcessWholesaleArbitragePriceSpreadChart
