import { IRadioItem } from '@aurecon-creative-technologies/styleguide'

export const ProjectType: IRadioItem[] = [
  {
    id: '1',
    label: 'Standalone BESS',
  },
  {
    id: '2',
    label: 'Hybrid Generating System',
  },
]
