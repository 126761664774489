import React, { FC } from 'react'
import Style from '../styles/Page.module.sass'

export interface IPageProps {
  children?: JSX.Element[] | JSX.Element | React.ReactNode
}

const Page: FC<IPageProps> = (props) => {
  return (
    <div className={Style.page}>
      <div className={Style.contain}>{props.children}</div>
    </div>
  )
}

export default Page
