import { Button, IHeader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import { FORECAST_TABLE_HEADERS } from 'src/config/constant'
import { ForecastingTableModelItem } from 'src/models/api/ForecastingTableModel'
import Style from '../../styles/UploadForecast.module.sass'

interface TablePreviewProps {
  data: ForecastingTableModelItem[]
}

const TablePreview: React.FC<TablePreviewProps> = ({ data }) => {
  if (data.length === 0) {
    return null
  }
  const rows = data.map((item) => [
    item['DateTime'].toString().split('T')[0],
    item['DateTime'].toString().split('T')[1],
    item['ENERGY'].toString(),
    item['RREG'].toString(),
    item['LREG'].toString(),
    item['R1'].toString(),
    item['L1'].toString(),
    item['R6'].toString(),
    item['L6'].toString(),
    item['R60'].toString(),
    item['L60'].toString(),
    item['R5m'].toString(),
    item['L5m'].toString(),
  ])

  const tableHeaders: IHeader[] = FORECAST_TABLE_HEADERS.map((header) => ({
    label: header,
    style: { width: '120px' },
  }))

  return (
    <div className={Style.noPadding} style={{ width: '100%' }}>
      <Button
        type='custom'
        cssClass={Style.leapYearText}
        label='Note: Only the first 10 lines are shown'
        icon='info'
        size='small'
      />
      <Table headers={tableHeaders}>
        {rows.map((row) => (
          <TableRow key={row?.['Time']}>
            {row.map((cell) => (
              <TableCell cellClass={Style.tableDataPadding} key={cell?.['Time']} title={cell}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default TablePreview
