import { FC, startTransition, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { NavLinks } from 'src/enums/NavLinks'
import { navLinksSelector, projectDataSelector, selectedProjectIdState } from 'src/stores/AppStore'

interface RouteParams extends Record<string, string | undefined> {
  projectId: string
}

const Dashboard: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Dashboard' })
  const projectData = useRecoilValue(projectDataSelector)
  const params = useParams<RouteParams>()
  const setSelectedProjectId = useSetRecoilState(selectedProjectIdState)
  const setNavSelector = useSetRecoilState(navLinksSelector)
  useEffect(() => {
    const projectId = Number(params.projectId)
    setSelectedProjectId(projectId)
    startTransition(() => {
      if (projectData['items'] !== undefined) {
        setNavSelector(() => getFormattedNavLinks(projectId))
      }
    })
  }, [params.projectId, setNavSelector, projectData, setSelectedProjectId])

  const getFormattedNavLinks = (projectId: number) => {
    return Object.keys(NavLinks).map((label) => ({
      label,
      href: NavLinks[label as keyof typeof NavLinks].replace(':projectId', String(projectId)),
    }))
  }

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Dashboard
