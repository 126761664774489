import { Chart, ChartConfiguration, TooltipItem, registerables } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useEffect, useRef } from 'react'

Chart.register(...registerables, ChartDataLabels)

interface SampleDispatchProfilesChartProps {
  data: ChartConfiguration<'line'>['data']
}

const SampleDispatchProfilesChart: React.FC<SampleDispatchProfilesChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart<'line'> | null>(null)

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx) return

    const modifiedData = { ...data }
    if (modifiedData.datasets) {
      modifiedData.datasets = modifiedData.datasets.map((dataset, index) => ({
        ...dataset,
        fill: index === 0,
      }))
    }

    const chartConfig: ChartConfiguration<'line'> = {
      type: 'line',
      data: modifiedData,
      options: {
        scales: {
          x: {
            title: {
              display: false,
              text: 'SETTLEMENT DATE',
            },
          },
          y: {
            title: {
              display: true,
              text: 'MW',
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'line'>) => {
                const label = tooltipItem.dataset.label || ''
                const value = tooltipItem.raw as number
                return `${label}: ${value.toFixed(2)}`
              },
            },
          },
        },
      },
    }

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    chartInstanceRef.current = new Chart(ctx, chartConfig)

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [data])

  return <canvas ref={chartRef} width={400} height={200}></canvas>
}

export default SampleDispatchProfilesChart
