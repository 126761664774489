import React, { useEffect, useState } from 'react'
import { FCASRevenueChartColors } from 'src/config/colors'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { FcasChartDetails } from 'src/models/api/ReportMappingResponseModel'
import FCASRevenueChart from './FCASRevenueChart'

interface ProcessFCASRevenueChartProps {
  rawData: FcasChartDetails
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

interface ProcessChartData {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    backgroundColor: string
  }[]
}

const ProcessFCASRevenueChart: React.FC<ProcessFCASRevenueChartProps> = ({ rawData, setFormData, sectionId }) => {
  const [chartData, setChartData] = useState<ProcessChartData>()

  useEffect(() => {
    if (!rawData) return

    const { avg_regulation_fcas_price_basis } = rawData

    const processedChartData = {
      labels: avg_regulation_fcas_price_basis.map((item) => item.year.toString()),
      datasets: [
        {
          label: 'Contingency Raise (1s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.r1_cfcas_price),
          backgroundColor: FCASRevenueChartColors.r1,
        },
        {
          label: 'Contingency Raise (5min)',
          data: avg_regulation_fcas_price_basis.map((item) => item.r5_cfcas_price),
          backgroundColor: FCASRevenueChartColors.r5,
        },
        {
          label: 'Contingency Raise (6s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.r6_cfcas_price),
          backgroundColor: FCASRevenueChartColors.r6,
        },
        {
          label: 'Contingency Raise (60s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.r60_cfcas_price),
          backgroundColor: FCASRevenueChartColors.r60,
        },
        {
          label: 'Contingency Lower (1s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.l1_cfcas_price),
          backgroundColor: FCASRevenueChartColors.l1,
        },
        {
          label: 'Contingency Lower (5min)',
          data: avg_regulation_fcas_price_basis.map((item) => item.l5_cfcas_price),
          backgroundColor: FCASRevenueChartColors.l5,
        },
        {
          label: 'Contingency Lower (6s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.l6_cfcas_price),
          backgroundColor: FCASRevenueChartColors.l6,
        },
        {
          label: 'Contingency Lower (60s)',
          data: avg_regulation_fcas_price_basis.map((item) => item.l60_cfcas_price),
          backgroundColor: FCASRevenueChartColors.l60,
        },
        {
          label: 'Regulation Raise',
          data: avg_regulation_fcas_price_basis.map((item) => item.reg_raise_fcas_price),
          backgroundColor: FCASRevenueChartColors.reg_raise,
        },
        {
          label: 'Regulation Lower',
          data: avg_regulation_fcas_price_basis.map((item) => item.reg_lower_fcas_price),
          backgroundColor: FCASRevenueChartColors.reg_lower,
        },
      ],
    }

    setChartData(processedChartData)

    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId)
            ? { ...section, chart_data: JSON.stringify(processedChartData) }
            : section,
        ),
      },
    }))
  }, [rawData, setFormData, sectionId])

  return chartData ? <FCASRevenueChart chartData={chartData} /> : null
}

export default ProcessFCASRevenueChart
