import {
  deleteAsync,
  getAsync,
  getAsyncList,
  getFileWithDownloadProgress,
  postMultiFormDataAsyncUploadProgress,
  putAsync,
} from 'src/helpers/apiRequest'
import { ForecastResponse } from 'src/models/api/Forecasting'
import { ForecastingRequestModal } from 'src/models/api/ForecastingRequestModal'
import { IBodyRequestModel } from 'src/models/api/IBodyRequestModel'
import { ForecastData } from 'src/models/api/SingleForecastModelResponse'
import { getToken } from './TokenManager'
import ForecastingTableModel from 'src/models/api/ForecastingTableModel'
import { dateToString, formatDate } from 'src/helpers/utils'

const postMultiFormData = async (
  projectId: number,
  formData: UploadForecastRequestModel,
  selectedFile: File | null,
  onUploadProgress: (progress: number) => void,
) => {
  try {
    const token = await getToken()
    const formDataToSend = new FormData()

    const fileName = await selectedFile?.name

    formDataToSend.append(
      'forecast_data',
      JSON.stringify({
        name: fileName,
        project_id: projectId,
        file_type: 1,
        interval: formData.interval,
        start_date: formData.start_date,
        period: Number(formData.period),
        description: formData.description,
        profile_type: null,
        is_leap_year_included: formData.is_leap_year_included,
      }),
    )

    if (selectedFile) {
      formDataToSend.append('forecast_file', selectedFile)
    }
    const baseUri = `/v1/forecast-data`

    const createResponse = await postMultiFormDataAsyncUploadProgress(baseUri, token, formDataToSend, onUploadProgress)
    return createResponse
  } catch (error) {
    console.error('Failed to upload forecast data:', error)
    throw new Error(`Failed to create a project: ${error}`)
  }
}

const putMultiFormData = async (
  forecastId: string | undefined,
  projectId: number,
  formData: UploadForecastRequestModel,
) => {
  try {
    const token = await getToken()
    const requestbody: UploadForecastRequestModel = {
      token: token,
      name: formData.name,
      project_id: projectId,
      file_type: 1,
      interval: formData.interval,
      start_date: formData.start_date,
      period: formData.period,
      description: formData.description,
      profile_type: null,
      is_leap_year_included: formData.is_leap_year_included,
    }
    const baseUri = `/v1/forecast-data/${forecastId}`

    const updateResponse = await putAsync(baseUri, requestbody)
    return updateResponse
  } catch (error) {
    console.error('Failed to update forecast data:', error)
    throw new Error(`Failed to create a project: ${error}`)
  }
}

export const postGeneralProfileMultiFormData = async (
  projectId: number,
  formData: UploadGeneralProfileFormStateRequestModel,
  selectedFile: File | null,
  onUploadProgress: (progress: number) => void,
) => {
  try {
    const token = await getToken()
    const formDataToSend = new FormData()

    const fileName = await selectedFile?.name

    formDataToSend.append(
      'forecast_data',
      JSON.stringify({
        name: fileName,
        project_id: projectId,
        profile_type: Number(formData.profile_type),
        file_type: 2,
        interval: formData.interval,
        start_date: formData.start_date,
        period: Number(formData.period),
        description: formData.description,
        is_leap_year_included: formData.is_leap_year_included,
      }),
    )

    if (selectedFile) {
      formDataToSend.append('forecast_file', selectedFile)
    }

    const baseUri = `/v1/forecast-data`

    const createResponse = await postMultiFormDataAsyncUploadProgress(baseUri, token, formDataToSend, onUploadProgress)
    return createResponse
  } catch (error) {
    console.error('Failed to upload forecast data:', error)
    throw new Error(`Failed to create a forecast: ${error}`)
  }
}

export const putGeneralProfileMultiFormData = async (
  forecastId: string | undefined,
  projectId: number,
  formData: UploadGeneralProfileFormStateRequestModel,
) => {
  try {
    const token = await getToken()
    const requestbody: UploadGeneralProfileFormStateRequestModel = {
      token: token,
      name: formData.name,
      project_id: projectId,
      profile_type: Number(formData.profile_type),
      file_type: 2,
      interval: formData.interval,
      start_date: formData.start_date,
      period: formData.period,
      description: formData.description,
      is_leap_year_included: formData.is_leap_year_included,
    }

    const baseUri = `/v1/forecast-data/${forecastId}`

    const updateResponse = await putAsync(baseUri, requestbody)
    return updateResponse
  } catch (error) {
    console.error('Failed to upload forecast data:', error)
    throw new Error(`Failed to create a forecast: ${error}`)
  }
}

export const fetchForecastingDetails = async (forecastId: string) => {
  try {
    const token = await getToken()
    const requestbody: ForecastingRequestModal = {
      token: token,
    }
    const baseUri = `/v1/forecast-data/${forecastId}`
    const response = await getAsync<ForecastingRequestModal, ForecastData>(baseUri, requestbody)
    return response
  } catch (error) {
    console.error('Error fetching forecasting details:', error)
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchForecastingData = async (
  selectedProjectId: number,
  page: number,
  pageSize: number,
  nameFilters: string[],
  yearsFilter: string,
  periodFilter: string,
  createdDateFilters: { startDate: Date | null; endDate: Date | null },
) => {
  try {
    const token = await getToken()
    const requestbody: ForecastingRequestModal = {
      token: token,
    }

    const requestScenarioParams: Record<string, string> = {
      project_id: selectedProjectId.toString(),
      sort_column: 'created_at',
      sort_order: 'desc',
      page: page.toString(),
      size: pageSize.toString(),
    }

    if (yearsFilter) {
      requestScenarioParams.duration_years = yearsFilter
    }

    if (periodFilter) {
      requestScenarioParams.period = periodFilter
    }

    if (createdDateFilters.startDate) {
      requestScenarioParams.date_from = formatDate(dateToString(createdDateFilters.startDate))
    }
    if (createdDateFilters.endDate) {
      requestScenarioParams.date_to = formatDate(dateToString(createdDateFilters.endDate))
    }

    const scenarioRequestParamsString = new URLSearchParams(requestScenarioParams).toString()

    const forecastFilesQueryString = nameFilters
      .map((filter) => `forecast_files=${encodeURIComponent(filter)}`)
      .join('&')

    const finalQueryString = `${forecastFilesQueryString}&${scenarioRequestParamsString}`

    const baseUri = `/v1/forecast-data`
    const apiUrl = `${baseUri}?${finalQueryString}`
    const response = await getAsyncList<ForecastingRequestModal, ForecastResponse>(`${apiUrl}`, requestbody)

    return response
  } catch (error) {
    console.error('Error fetching forecasting data:', error)
    throw new Error(`Error fetching forecasting data: ${error}`)
  }
}

export const fetchForecastingDataForFilter = async (selectedProjectId: number, page: number, pageSize: number) => {
  try {
    const token = await getToken()
    const requestbody: ForecastingRequestModal = {
      token: token,
    }

    const requestParams: Record<string, string> = {
      project_id: selectedProjectId.toString(),
      sort_column: 'created_at',
      sort_order: 'asc',
      page: page.toString(),
      size: pageSize.toString(),
    }
    const requestParamsString = new URLSearchParams(requestParams).toString()

    const baseUri = `/v1/forecast-data`
    const response = await getAsync<ForecastingRequestModal, ForecastResponse>(
      `${baseUri}?${requestParamsString}`,
      requestbody,
    )
    return response
  } catch (error) {
    console.error('Error fetching filtered forecasting data:', error)
    throw new Error(`Error fetching filtered forecasting data: ${error}`)
  }
}

export const fetchTablePreviewData = async (forecastId: number) => {
  try {
    const token = await getToken()
    const requestbody: ForecastingRequestModal = {
      token: token,
    }
    const baseUri = `/v1/forecast-data/preview`
    const response = await getAsync<ForecastingRequestModal, ForecastingTableModel[]>(
      `${baseUri}/${forecastId}?number_of_records=10`,
      requestbody,
    )
    return response['items']
  } catch (error) {
    console.error('Error fetching table preview data:', error)
    throw new Error(`Error fetching table preview data: ${error}`)
  }
}

export const downloadExecutionSummaryFile = (
  execution_scenario_id: number,
  onDownloadProgress: (progress: number) => void,
) => downloadDetailedOutputFile(execution_scenario_id, 'download-zip', onDownloadProgress)

export const downloadDetailedOutputFile = async (
  id: number,
  type: 'download-zip' | 'log',
  onDownloadProgress: (progress: number) => void,
) => {
  try {
    const baseUri = `/v1/execution/summary`
    const token = await getToken()
    const response = await getFileWithDownloadProgress(
      `${baseUri}/${type}?execution_scenario_id=${id}`,
      token,
      onDownloadProgress,
    )
    return response
  } catch (error) {
    console.error(`Error downloading ${type} file:`, error)
    throw error
  }
}

export const downloadForecastFile = (forecastId: number, onDownloadProgress: (progress: number) => void) =>
  downloadFile(forecastId, 'download', onDownloadProgress)
export const downloadForecastLog = (forecastId: number, onDownloadProgress: (progress: number) => void) =>
  downloadFile(forecastId, 'log', onDownloadProgress)

export const downloadFile = async (
  id: number,
  type: 'download' | 'log',
  onDownloadProgress: (progress: number) => void,
) => {
  try {
    const baseUri = `/v1/forecast-data`
    const token = await getToken()
    const response = await getFileWithDownloadProgress(`${baseUri}/${type}/${id}`, token, onDownloadProgress)
    return response
  } catch (error) {
    console.error(`Error ${type} file:`, error)
    throw error
  }
}

export const downloadTemplateFile = (type: string, onDownloadProgress: (progress: number) => void) => {
  const templateUrl = 'template/' + type
  return downloadTemplate(templateUrl, onDownloadProgress)
}

export const downloadTemplate = async (templateUrl: string, onDownloadProgress: (progress: number) => void) => {
  try {
    const baseUri = `/v1/forecast-data`
    const token = await getToken()
    const response = await getFileWithDownloadProgress(`${baseUri}/${templateUrl}`, token, onDownloadProgress)
    return response
  } catch (error) {
    console.error(`Error downloading template:`, error)
    throw error
  }
}

export const deleteForecast = async (forecastId: number) => {
  try {
    const token = await getToken()
    const requestbody: ForecastingRequestModal = {
      token: token,
    }
    const baseUri = `/v1/forecast-data/${forecastId}`

    const response = await deleteAsync<ForecastingRequestModal, ForecastResponse>(baseUri, requestbody)
    return response
  } catch (error) {
    console.error('Error deleting forecast:', error)
    throw error
  }
}

export interface UploadForecastRequestModel extends IBodyRequestModel {
  name: string
  description: string
  project_id?: number
  is_leap_year_included: boolean | null
  file_type?: number
  interval: string
  start_date: string
  period: string
  profile_type?: number | null
}

export interface UploadGeneralProfileFormStateRequestModel extends IBodyRequestModel {
  description: string
  interval: string
  start_date: string
  period: string
  project_id?: number
  name: string
  profile_type?: number | null
  file_type?: number
  is_leap_year_included: boolean | null
}

export { postMultiFormData, putMultiFormData }
