import { ChartData, ChartOptions } from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

interface WholesaleEnergyPriceChartProps {
  chartsData: ChartData<'line'>[]
}

const WholesaleEnergyPriceChart: React.FC<WholesaleEnergyPriceChartProps> = ({ chartsData }) => {
  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Week of the Year',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Wholesale Price',
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {chartsData.map((chartData, index) => (
        <div
          key={`chart_${chartData.labels}_${index}`}
          style={{ flex: '1 1 50%', padding: '10px', boxSizing: 'border-box' }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      ))}
    </div>
  )
}

export default WholesaleEnergyPriceChart
