import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg'

import { JIRA_SUPPORT_URL } from '../config/config'
import { appInsights } from '../api/AppInsights'

import Style from '../styles/Login.module.sass'

interface IState {
  from: { pathname: string }
}

const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const location = useLocation()
  if (appInsights) appInsights.trackPageView({ name: 'Login' })

  if (isAuthenticated) return <Navigate to='/' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  const handleLogin = () => {
    const state = location.state as IState
    const targetUrl = state ? `/#${state.from.pathname}` : '/'

    loginWithRedirect({
      appState: { targetUrl },
      connection_scope: `offline_access`,
    })
  }

  const handleRequestAccessLogin = (url: string) => {
    // * Need a api call for request access
    window.open(url)
  }

  // const version = appVersion.state === 'hasValue' ? appVersion.contents : '0.0.0.0'

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={40} width={100} />
        </div>
        <div className={Style.content}>
          <div className={Style.contentTitle}>
            <span>Welcome to</span>
            <div>ESRO</div>
          </div>

          <p>
            Energy Storage Revenue Optimiser is intended to optimise the sizing and operating strategy for a large-scale
            battery project in Australia’s National Electricity Market. The tool will model each of the potential
            market-based and contracted services that may be available to a battery project, including the implications
            and trade-offs involved in pursuing various services. The digital tool is intended to form the basis for a
            new service offering to Aurecon’s energy clients that will support them to develop better battery projects
            with improved commercial returns.
          </p>
          <Button type='custom' cssClass={Style.loginBtn} label='Login' onClick={handleLogin} />
          <Button
            type='custom'
            cssClass={Style.requestBtn}
            label='Request Access'
            onClick={() => handleRequestAccessLogin(`${JIRA_SUPPORT_URL}`)}
          />
        </div>
        <div className={Style.content} />
        <div className={Style.footer}>
          <Button
            type='custom'
            cssClass={Style.contactBtn}
            label='Contact Us'
            onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
