import React from 'react'
import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../../../styles/Reports.module.sass'

interface CommercialPerformanceDataTableProps {
  headers: string[]
  data: { label: string; value: string | number | JSX.Element | undefined }[][]
  labels: string[]
}

const CommercialPerformanceDataTable: React.FC<CommercialPerformanceDataTableProps> = ({ headers, data, labels }) => {
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table headers={headers.map((header) => ({ label: header }))}>
          {labels.map((label, rowIndex) => (
            <TableRow key={label}>
              <TableCell cellClass={Style.tableDataPadding} style={{ width: '300px' }}>
                <p>{label}</p>
              </TableCell>
              {data.map((row, colIndex) => {
                const cellData = row[rowIndex]
                return (
                  <TableCell cellClass={Style.tableDataPadding} key={`${label}-${colIndex}`}>
                    {cellData ? cellData.value : '-'}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default CommercialPerformanceDataTable
