import { FC } from 'react'

import Projects from './Projects'

const Home: FC = () => {
  return (
    <div>
      <Projects />
    </div>
  )
}

export default Home
