import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { BatteryMarketDispatchChart } from 'src/models/api/ReportMappingResponseModel'
import BatteryMarketDispatchStateChargeProfile from './BatteryMarketDispatchStateChargeProfile'

interface ProcessBatteryMarketDispatchStateChargeProfileProps {
  data: BatteryMarketDispatchChart[]
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessBatteryMarketDispatchStateChargeProfile: React.FC<ProcessBatteryMarketDispatchStateChargeProfileProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const chartData = useMemo(() => {
    return {
      labels: data.map((item) => item.time),
      datasets: [
        {
          label: 'BESS Dispatch (MW)',
          data: data.map((item) => item.meter_gen_mwh_at ?? 0),
          borderColor: '#7AB800',
          backgroundColor: '#7AB800',
          yAxisID: 'y1',
          fill: false,
        },
        {
          label: 'Reg Raise FCAS Market Cleared (MW)',
          data: data.map((item) => item.fr_mw ?? 0),
          borderColor: '#353D30',
          backgroundColor: '#353D30',
          yAxisID: 'y1',
          fill: false,
        },
        {
          label: 'Reg Lower FCAS Market Cleared (MW)',
          data: data.map((item) => item.fl_mw ?? 0),
          borderColor: '#BABFB7',
          backgroundColor: '#BABFB7',
          yAxisID: 'y1',
          fill: false,
        },
        {
          label: 'State of Charge',
          data: data.map((item) => item.soc ?? 0),
          borderColor: '#FCD450',
          backgroundColor: '#FCD450',
          yAxisID: 'y2',
          fill: false,
        },
      ],
    }
  }, [data])

  useEffect(() => {
    if (!chartData.datasets.length) {
      return
    }

    setFormData((prevFormData) => {
      const updatedReportSections = prevFormData.report_detail.report_section.map((section) => {
        if (section.section_id === Number(sectionId)) {
          let existingChartData = {}
          try {
            existingChartData = JSON.parse(section.chart_data)
          } catch (e) {
            existingChartData = {}
          }
          return {
            ...section,
            chart_data: JSON.stringify({
              ...existingChartData,
              chart3: chartData,
            }),
          }
        }
        return section
      })

      return {
        ...prevFormData,
        report_detail: {
          ...prevFormData.report_detail,
          report_section: updatedReportSections,
        },
      }
    })
  }, [chartData, sectionId, setFormData])

  return <BatteryMarketDispatchStateChargeProfile chartData={chartData} />
}

export default ProcessBatteryMarketDispatchStateChargeProfile
