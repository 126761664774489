export enum ReportsTableHeaders {
  REPORT = 'Report',
  PREFEREDSCENARIOS = 'Scenarios',
  CREATED_DATE = 'Created Date',
  UPDATED_DATE = 'Preferred Date',
  ACTION = 'Action',
}

export const AverageContingencyFCASPriceChartLabels = [
  'Average of R1 FCAS Price',
  'Average of R6 FCAS Price',
  'Average of R60 FCAS Price',
  'Average of R5 FCAS Price',
  'Average of L1 FCAS Price',
  'Average of L6 FCAS Price',
  'Average of L60 FCAS Price',
  'Average of L5 FCAS Price',
]

export const CommercialPerformanceDataTableHeadersLabels = [
  'Total net revenue',
  'Net present value',
  'Internal Rate of Return',
]
