import Page from 'src/components/Page'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import Style from '../styles/Projects.module.sass'
import { useRecoilValue } from 'recoil'
import { getSwaggerJson } from 'src/stores/AppStore'

const Swagger = () => {
  const apiDocs = useRecoilValue(getSwaggerJson)

  return (
    <Page>
      <div className={Style.wrapper}>
        {!apiDocs && (
          <>
            <h1>Recall Application API</h1>
            <p>API version not found</p>
          </>
        )}
        {apiDocs && <SwaggerUI spec={apiDocs} />}
      </div>
    </Page>
  )
}

export default Swagger
