import { ChartData, ChartOptions } from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'
import Style from '../../styles/charts/AverageEnergyChart.module.sass'

interface AverageEnergyChartProps {
  chartsData: { data: ChartData<'line'>; year: number }[]
}

const AverageEnergyChart: React.FC<AverageEnergyChartProps> = ({ chartsData }) => {
  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: 'Hour of the Day',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Average Dispatch (MW)',
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  const combinedLegendItems = React.useMemo(() => {
    const items = new Map<string, string>()

    chartsData.forEach((chartData) => {
      chartData.data.datasets.forEach((dataset) => {
        items.set(dataset.label as string, dataset.borderColor as string)
      })
    })

    return Array.from(items.entries()).map(([label, color]) => ({ text: label, strokeStyle: color }))
  }, [chartsData])

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {chartsData.map((chartData) => (
          <div
            key={chartData.data.datasets[0].label}
            style={{ flex: '1 1 50%', padding: '10px', boxSizing: 'border-box' }}
          >
            <Line data={chartData.data} options={chartOptions} />
            <div className={Style.legendWrapper}>
              <div className={Style.legendItem}>
                <span className={Style.yearLegend}></span>
                {chartData.year}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={Style.legendWrapper}>
        {combinedLegendItems.map((item) => (
          <div key={`legend_${item.text}`} className={Style.legendItem}>
            <span className={Style.yearLegend} style={{ backgroundColor: item.strokeStyle }}></span>
            {item.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AverageEnergyChart
