import { ChartData } from 'chart.js'
import React, { useEffect, useState } from 'react'
import { AverageRegulationFCASPriceChartBG, AverageRegulationFCASPriceChartColors } from 'src/config/colors'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { FcasChartDetails } from 'src/models/api/ReportMappingResponseModel'
import AverageRegulationFCASPriceChart from './AverageRegulationFCASPriceChart'
import { updateChartDataInForm } from 'src/api/ReportService'

interface ProcessAverageRegulationFCASPriceChartProps {
  data: FcasChartDetails
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessAverageRegulationFCASPriceChart: React.FC<ProcessAverageRegulationFCASPriceChartProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const { avg_regulation_fcas_price_basis } = data
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    if (!avg_regulation_fcas_price_basis || avg_regulation_fcas_price_basis.length === 0) {
      return
    }

    const labels = avg_regulation_fcas_price_basis.map((item) => item.year)
    const datasets = Object.keys(avg_regulation_fcas_price_basis[0])
      .filter((key) => key.startsWith('reg'))
      .map((key, index) => ({
        label: key === 'reg_raise_fcas_price' ? 'Raise Reg' : 'Lower Reg',
        data: avg_regulation_fcas_price_basis.map((item) => item[key as keyof typeof item]),
        borderColor: AverageRegulationFCASPriceChartColors[index],
        backgroundColor: AverageRegulationFCASPriceChartBG[index],
        yAxisID: 'y',
        fill: false,
      }))

    const newChartData = {
      labels: labels,
      datasets: datasets,
    }

    setChartData(newChartData)
  }, [avg_regulation_fcas_price_basis])

  useEffect(() => {
    if (!chartData.datasets.length) {
      return
    }
    updateChartDataInForm(setFormData, sectionId, 'chart1', chartData)
  }, [chartData, sectionId, setFormData])

  return <AverageRegulationFCASPriceChart data={chartData} />
}

export default ProcessAverageRegulationFCASPriceChart
