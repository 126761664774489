import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface BatteryMarketDispatchStateChargeProfileProps {
  chartData: {
    labels: string[]
    datasets: {
      label: string
      data: number[]
      borderColor: string
      backgroundColor: string
      yAxisID: string
      fill: boolean
    }[]
  }
}

const BatteryMarketDispatchStateChargeProfile: React.FC<BatteryMarketDispatchStateChargeProfileProps> = ({
  chartData,
}) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: 'time',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Battery Power Dispatch (MW)',
        },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Battery State of Charge (MWh)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
        },
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  return <Line data={chartData} options={options} />
}

export default BatteryMarketDispatchStateChargeProfile
