import { ReportsItem, ReportsResponse } from './ReportsResponseModel'

class ReportsModel {
  public items: ReportsItem[]
  public total: number
  public page: number
  public size: number
  public pages: number

  constructor(data: ReportsResponse) {
    this.items = data.items
    this.total = data.total
    this.page = data.page
    this.size = data.size
    this.pages = data.pages
  }

  static fromJSON(data: ReportsResponse): ReportsModel {
    return new ReportsModel(data)
  }
}

export default ReportsModel
