import { ChartData } from 'chart.js'
import React, { useEffect, useMemo } from 'react'
import { WholesaleEnergyPriceChartColors } from 'src/config/colors'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { WholesaleArbitragePriceShapeChart } from 'src/models/api/ReportMappingResponseModel'
import WholesaleEnergyPriceChart from './WholesaleEnergyPriceChart'

interface ProcessWholesaleEnergyPriceChartProps {
  data: WholesaleArbitragePriceShapeChart[]
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
  sectionId: string
}

const ProcessWholesaleEnergyPriceChart: React.FC<ProcessWholesaleEnergyPriceChartProps> = ({
  data,
  setFormData,
  sectionId,
}) => {
  const parsedData = useMemo(
    () =>
      data.map((item) => ({
        year: item.year,
        prices: JSON.parse(item.data).map((entry: { adjusted_gen_wholesale_rrp: number; week: number }) => ({
          price: entry.adjusted_gen_wholesale_rrp,
          week: entry.week,
        })),
      })),
    [data],
  )

  const formatChartData = (yearData: {
    year: number
    prices: { price: number; week: number }[]
  }): ChartData<'line'> => {
    return {
      labels: yearData.prices.map((entry) => `${entry.week}`),
      datasets: [
        {
          label: `Year ${yearData.year}`,
          data: yearData.prices.map((entry: { price: number; week: number }) => parseFloat(entry.price.toFixed(2))),
          fill: false,
          borderColor: WholesaleEnergyPriceChartColors.lineColor,
          borderWidth: 1,
          pointRadius: 0,
          showLine: true,
        },
      ],
    }
  }

  const chartsData = useMemo(() => {
    return parsedData.map((yearData) => formatChartData(yearData))
  }, [parsedData])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: prevFormData.report_detail.report_section.map((section) =>
          section.section_id === Number(sectionId) ? { ...section, chart_data: JSON.stringify(chartsData) } : section,
        ),
      },
    }))
  }, [chartsData, setFormData, sectionId])

  return <WholesaleEnergyPriceChart chartsData={chartsData} />
}

export default ProcessWholesaleEnergyPriceChart
