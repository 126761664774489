import { Chart, TooltipItem, registerables, ChartOptions } from 'chart.js'
import React, { useEffect, useRef } from 'react'
import { EnergyBreakdownBarChartColors } from 'src/config/colors'
import { ExecutionChartResponse } from 'src/models/api/DashboardResponseModel'

Chart.register(...registerables)

interface BarChartProps {
  data: ExecutionChartResponse[]
  width?: number
  height?: number
}

const EnergyBreakdownBarChart: React.FC<BarChartProps> = ({ data, width = 300, height = 200 }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<Chart | null>(null)

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d')
    if (!ctx || !data.length) return
    const duplicatedData = data.flatMap((item) => [item])
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: duplicatedData.map((item) => item.year.toString()),
        datasets: [
          {
            label: 'BOY Storage',
            data: duplicatedData.map((item) => item.boy_storage),
            backgroundColor: EnergyBreakdownBarChartColors.boyStorage,
          },
          {
            label: 'EOY Storage',
            data: duplicatedData.map((item) => item.eoy_storage_expected),
            backgroundColor: EnergyBreakdownBarChartColors.eoyStorage,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                if (tooltipItem.raw !== undefined) {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
                }
                return ''
              },
            },
          },
          legend: {
            position: 'bottom',
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: false,
            beginAtZero: true,
            ticks: {
              stepSize: 1,
            },
            title: {
              display: true,
              text: 'Year',
              font: {
                size: 14,
                weight: 500,
              },
              color: '#666666',
            },
            display: true,
          },
          y: {
            stacked: false,
            beginAtZero: true,
            ticks: {
              stepSize: 100,
              color: '#666666',
              font: {
                size: 12,
              },
            },
            title: {
              display: true,
              text: 'Storage',
              font: {
                size: 14,
                weight: 500,
              },
              color: '#666666',
            },
          },
        },
      } as ChartOptions<'bar'>,
    })

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [data, height])

  const chartContainerStyle = {
    maxHeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px',
  }

  return (
    <div style={chartContainerStyle}>
      <canvas ref={chartRef} width={width} height={height}></canvas>
    </div>
  )
}

export default EnergyBreakdownBarChart
