import { ChartData } from 'chart.js'
import {
  deleteAsync,
  getAsync,
  getFileWithDownloadProgress,
  postAsync,
  postMultiFormDataAsyncUploadProgress,
} from 'src/helpers/apiRequest'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { IBodyRequestModel } from 'src/models/api/IBodyRequestModel'
import { IResponse } from 'src/models/api/IResponse'
import { ReportDetailResponseModal } from 'src/models/api/ReportDetailResponseModal'
import { ReportMappingResponseModel } from 'src/models/api/ReportMappingResponseModel'
import { ReportRequestModal } from 'src/models/api/ReportRequestModal'
import { ReportsResponse } from 'src/models/api/ReportsResponseModel'
import { ScenariosListModel } from 'src/models/api/ScenariosListModel'
import { getToken } from './TokenManager'
import { ValidateReportRequestModel } from 'src/models/api/ValidateReportRequestModel'
import { ValidateReportResponseModel } from 'src/models/api/ValidateReportResponseModel'

export const fetchReportsList = async (
  projectId: number,
  page: number,
  pageSize: number,
  searchQuery: string,
  sortColumn: string | number,
  sortOrder: string,
) => {
  try {
    const baseUri = `/v1/reports`
    const token = await getToken()
    const requestBody: ReportRequestModal = {
      token: token,
    }
    const response: IResponse<ReportsResponse> = await getAsync<ReportRequestModal, ReportsResponse>(
      `${baseUri}?project_id=${projectId}&page=${page}&size=${pageSize}&name=${searchQuery}&sort_column=${sortColumn}&sort_order=${sortOrder}`,
      requestBody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchReportDetail = async (report_id: number) => {
  try {
    const baseUri = `/v1/reports`
    const token = await getToken()
    const requestBody: IBodyRequestModel = {
      token: token,
    }
    const response: IResponse<ReportDetailResponseModal> = await getAsync<IBodyRequestModel, ReportDetailResponseModal>(
      `${baseUri}/${report_id}`,
      requestBody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const fetchReportsDetails = async (
  execution_scenario_association_id: number,
  projectId: number,
  clone_report_id: number,
  preferred_date: string,
) => {
  try {
    const baseUri = `/v1/reports/execution-scenario`
    const token = await getToken()
    const requestBody: ReportRequestModal = {
      token: token,
    }
    const response: IResponse<ReportMappingResponseModel> = await getAsync<
      ReportRequestModal,
      ReportMappingResponseModel
    >(
      `${baseUri}/${execution_scenario_association_id}?project_id=${projectId}&clone_report_id=${clone_report_id}&preferred_date=${preferred_date}`,
      requestBody,
    )
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

export const generateReport = async (formData: GenerateReportRequest) => {
  try {
    const token = await getToken()
    const requestBody: ReportRequestModal = {
      token: token,
      ...formData,
    }
    const baseUri = `/v1/reports`
    const response = await postAsync<ReportRequestModal, ScenariosListModel>(baseUri, requestBody)
    return response
  } catch (error) {
    console.error('Error deleting reports:', error)
    throw error
  }
}

export const deleteReport = async (reportsId: number) => {
  try {
    const token = await getToken()
    const requestBody: ReportRequestModal = {
      token: token,
    }
    const baseUri = `/v1/reports/${reportsId}`

    const response = await deleteAsync<ReportRequestModal, ScenariosListModel>(baseUri, requestBody)
    return response
  } catch (error) {
    console.error('Error deleting reports:', error)
    throw error
  }
}

export interface FetchReportMappingDataRequestModel extends IBodyRequestModel {
  name: string
  execution_scenario_association_id: string | number | null
  preferred_date: string
  project_id?: number
  clone_report_id?: string | number | null
  startDate?: string | Date
  endDate?: string | Date
}

export const updateChartDataInForm = (
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>,
  sectionId: string,
  chartKey: string,
  chartData: ChartData<'line'>,
) => {
  setFormData((prevFormData) => {
    const updatedReportSections = prevFormData.report_detail.report_section.map((section) => {
      if (section.section_id === Number(sectionId)) {
        let existingChartData = {}
        try {
          existingChartData = JSON.parse(section.chart_data)
        } catch (e) {
          existingChartData = {}
        }
        return {
          ...section,
          chart_data: JSON.stringify({
            ...existingChartData,
            [chartKey]: chartData,
          }),
        }
      }
      return section
    })

    return {
      ...prevFormData,
      report_detail: {
        ...prevFormData.report_detail,
        report_section: updatedReportSections,
      },
    }
  })
}

export const uploadReportPDFAsMultipart = async (
  report_id: string,
  formData: FormData,
  generatedFile: File | null,
  onUploadProgress: (progress: number) => void,
) => {
  try {
    const token = await getToken()
    const baseUri = `/v1/reports/upload/${report_id}`
    if (generatedFile) {
      formData.append('report_file', generatedFile)
    }

    const response = await postMultiFormDataAsyncUploadProgress(baseUri, token, formData, onUploadProgress)
    return response
  } catch (error) {
    console.error('Error uploading reports:', error)
    throw error
  }
}

export const downloadReportPDFFile = (report_id: string, onDownloadProgress: (progress: number) => void) =>
  downloadReportPDF(report_id, onDownloadProgress)

export const downloadReportPDF = async (report_id: string, onDownloadProgress: (progress: number) => void) => {
  try {
    const token = await getToken()
    const baseUri = `/v1/reports/download/${report_id}`
    const response = await getFileWithDownloadProgress(baseUri, token, onDownloadProgress)
    return response
  } catch (error) {
    console.error('Error downloading reports:', error)
    throw error
  }
}

export const validateReportsDetails = async (formData: ValidateReportRequestModel) => {
  try {
    const baseUri = `/v1/reports/validate`
    const token = await getToken()
    const requestBody: ReportRequestModal = {
      token: token,
      ...formData,
    }
    const response = await postAsync<ReportRequestModal, ValidateReportResponseModel>(baseUri, requestBody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}
