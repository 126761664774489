import { ChartConfiguration } from 'chart.js/auto'
import React, { useEffect, useMemo } from 'react'
import { GenerateReportRequest } from 'src/models/api/GenerateReportRequestFormData'
import { ChartSampleDispatchProfiles, SampleDispatchProfiles } from 'src/models/api/ReportMappingResponseModel'
import SampleDispatchProfilesChart from './SampleDispatchProfilesChart'
import { updateChartDataInForm } from 'src/api/ReportService'
import { getDateFromIntervalString } from 'src/helpers/utils'

interface SampleDispatchProfilesDataProcessorProps {
  data: SampleDispatchProfiles[]
  sectionId: string
  setFormData: React.Dispatch<React.SetStateAction<GenerateReportRequest>>
}

const consolidateDailyAverages = (data: SampleDispatchProfiles[]): ChartSampleDispatchProfiles[] => {
  const dailyData: {
    [key: string]: { totalLoad: number; totalGen: number; totalRreg: number; totalLreg: number; count: number }
  } = {}

  data.forEach((item) => {
    const date = getDateFromIntervalString(item.datetime) // Get only the date part
    if (!dailyData[date]) {
      dailyData[date] = { totalLoad: 0, totalGen: 0, totalRreg: 0, totalLreg: 0, count: 0 }
    }
    dailyData[date].totalLoad += Number(item.energy_load) || 0
    dailyData[date].totalGen += Number(item.energy_gen) || 0
    dailyData[date].totalRreg += Number(item.rreg) || 0
    dailyData[date].totalLreg += Number(item.lreg) || 0
    dailyData[date].count += 1
  })

  return Object.keys(dailyData).map((date) => ({
    datetime: new Date(date),
    energy_load: dailyData[date].totalLoad / dailyData[date].count,
    energy_gen: dailyData[date].totalGen / dailyData[date].count,
    rreg: dailyData[date].totalRreg / dailyData[date].count,
    lreg: dailyData[date].totalLreg / dailyData[date].count,
    day: new Date(date).getDate(),
    soc: 0,
    mw_net: 0,
  }))
}

const generateLabels = (data: ChartSampleDispatchProfiles[]): string[] =>
  data.map((item) => new Date(item.datetime).toLocaleDateString())

const generateDatasets = (data: ChartSampleDispatchProfiles[]): ChartConfiguration<'line'>['data']['datasets'] => {
  const keys: Array<keyof SampleDispatchProfiles> = ['energy_load', 'energy_gen', 'rreg', 'lreg']

  const labelsForKeys = ['Energy Load', 'Energy Gen', 'RReg', 'LReg']

  return keys.map((key, index) => ({
    label: labelsForKeys[index],
    data: data.map((item) => Number(item[key]) || 0),
    fill: false,
    tension: 0.4,
  }))
}

const ProcessSampleDispatchProfilesData: React.FC<SampleDispatchProfilesDataProcessorProps> = ({
  data,
  sectionId,
  setFormData,
}) => {
  const consolidatedData = useMemo(() => consolidateDailyAverages(data), [data])

  const chartData = useMemo(() => {
    return {
      labels: generateLabels(consolidatedData),
      datasets: generateDatasets(consolidatedData),
    }
  }, [consolidatedData])

  useEffect(() => {
    updateChartDataInForm(setFormData, sectionId, 'chart1', chartData)
  }, [chartData, sectionId, setFormData])

  return (
    <div>
      <SampleDispatchProfilesChart data={chartData} />
    </div>
  )
}

export default ProcessSampleDispatchProfilesData
