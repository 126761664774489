import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import React from 'react'
import Style from '../../../styles/Reports.module.sass'
import { TechnicalAssumption } from 'src/models/api/GenerateReportRequestFormData'

interface TechnicalAssumptionsProps {
  data: TechnicalAssumption[]
  projectType: number | undefined
}

const ViewTechnicalAssumptions: React.FC<TechnicalAssumptionsProps> = ({ data, projectType }) => {
  return (
    <Container fluid>
      <div className={Style.tbl_container}>
        <Table
          headers={[
            { label: 'Year' },
            { label: 'Energy Storage Retention' },
            { label: 'Round Trip Efficiency' },
            ...(projectType !== 1 ? [{ label: 'RE generation (MWh)' }] : []),
          ]}
        >
          {data.map((item) => (
            <TableRow key={item.operating_year}>
              <TableCell cellClass={Style.tableDataPadding}>
                <p>{item.operating_year}</p>
              </TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{item.energy_storage_retention}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{item.round_trip_efficiency}</TableCell>
              {projectType !== 1 && (
                <TableCell cellClass={Style.tableDataPadding}>
                  <p>{item.re_generation}</p>
                </TableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </div>
    </Container>
  )
}

export default ViewTechnicalAssumptions
