import StepLine from 'src/components/StepLine'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import { Step5 } from './Step5'
import { Step6 } from './Step6'
import { Step7 } from './Step7'
import { ScenarioFormData } from 'src/models/ScenarioFormData'
interface ScenarioStepLineProps {
  step: number
  steps: string[]
  formType: boolean
  updateFormData: ScenarioFormData
  setUpdateFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  error: Record<string, string>
  hideLines: boolean
}

export const ScenarioStepLine: React.FC<ScenarioStepLineProps> = ({
  step,
  steps,
  updateFormData,
  formType,
  setUpdateFormData,
  error = {},
  hideLines,
}) => {
  return (
    <>
      {hideLines && <StepLine steps={steps} currentStep={step - 1} />}
      {(() => {
        switch (step) {
          case 1:
            return (
              <Step1 formData={updateFormData} formTypeData={formType} setFormData={setUpdateFormData} errors={error} />
            )
          case 2:
            return <Step2 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          case 3:
            return <Step3 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          case 4:
            return <Step4 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          case 5:
            return <Step5 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          case 6:
            return <Step6 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          case 7:
            return <Step7 formData={updateFormData} setFormData={setUpdateFormData} errors={error} />
          default:
            return null
        }
      })()}
    </>
  )
}
