import { TokenManager } from './TokenManager'

export const getSecret = async () => {
  const tokenManager = TokenManager()
  const token = await tokenManager.requestToken()

  const url = `/v1/protected/secret`

  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Cache-Control': `no-cache`,
    },
  }

  try {
    const response = await fetch(url, config)
    if (!response.ok || !response.body) return null
    return await response.json()
  } catch (err) {
    console.error(err)
    return null
  }
}
