import React, { ReactNode, startTransition, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import Footer from 'src/components/Footer'
import Navbar from 'src/components/Navbar'

import Page from 'src/components/Page'
import { appInsights } from '../api/AppInsights'
import {
  AppVersion,
  inProductedSecret,
  inProductedSecretState,
  navbarProjectDataSelector,
  selectedProjectIdState,
} from '../stores/AppStore'
import { AllProjectApiResponse } from 'src/models/api/Projects'
import { useLocation, useNavigate } from 'react-router-dom'
interface LayoutProps {
  children: ReactNode
}

interface IDropdownItemProps {
  id: number
  label: string
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const emptyProjects: IDropdownItemProps[] = useMemo(
    () => [
      {
        id: 0,
        label: 'No Projects',
      },
    ],
    [],
  )

  const version = useRecoilValueLoadable(AppVersion)
  const projectData = useRecoilValue(navbarProjectDataSelector)
  const [projects, setProjects] = useState<IDropdownItemProps[]>(emptyProjects)
  const [selectedProjectId, setSelectedProjectId] = useRecoilState(selectedProjectIdState)
  const setInProductedSecret = useSetRecoilState(inProductedSecretState)
  const fetchedSecretData = useRecoilValue(inProductedSecret)

  useEffect(() => {
    const responseData = fetchedSecretData['data']
    setInProductedSecret(responseData)
  }, [fetchedSecretData, setInProductedSecret])

  const versionValue = useMemo(() => {
    if (version.state !== 'hasValue' || !version.contents) return null
    return version.contents
  }, [version])

  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'layout' })
  }, [])

  const handleSelectItem = (projectKeyId: string | number) => {
    setSelectedProjectId(Number(projectKeyId))
    const pathParts = location.pathname.split('/').filter(Boolean)
    pathParts[1] = String(projectKeyId)
    const newPath = `/${pathParts.join('/')}`
    navigate(newPath)
    if (location.pathname === '/dashboard/' + selectedProjectId) {
      navigate(0)
    }
  }

  useEffect(() => {
    startTransition(() => {
      if (projectData && typeof projectData === 'object') {
        const projectDataArray = Object.values(projectData).filter(
          (item) => typeof item === 'object',
        ) as Array<AllProjectApiResponse>
        const projectItems = projectDataArray.map((item) => ({
          id: item.id,
          label: item.name,
        }))
        setProjects(projectItems)
      } else {
        setProjects(emptyProjects)
      }
    })
  }, [projectData, emptyProjects])

  return (
    <div>
      <Navbar
        placeholder={'Select Project here'}
        dropdownItems={projects}
        selectedItem={selectedProjectId}
        onSelectItem={handleSelectItem}
      />
      <Page>
        <main>{children}</main>
      </Page>
      <Footer appversion={versionValue} />
    </div>
  )
}

export default Layout
