import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  Point,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface AverageRegulationFCASPriceChartProps {
  data: ChartData<'line', (number | Point | null)[], unknown>
}

const AverageRegulationFCASPriceChart: React.FC<AverageRegulationFCASPriceChartProps> = ({ data }) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: '$/MWh',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: true,
        text: 'Average Regulation FCAS price basis',
        font: {
          size: 14,
          weight: 'normal',
        },
        padding: {
          top: 10,
          bottom: 20,
        },
      },
      datalabels: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  }

  return <Line data={data} options={options} />
}

export default AverageRegulationFCASPriceChart
