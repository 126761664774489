import { Grid, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  BessExpectedPerformanceCyclePerDay,
  CapexValue,
  InertiaContract,
  LossFactoryTableHeader,
  NmsTableHeader,
  NonMarket,
  ProjectInput,
  ProjectInputLgc,
  QuarterCapVolume,
  QuarterCapVolumeStep7,
  RepexValue,
  SrasContract,
  VcasContract,
} from 'src/enums/ScenarioEnums'
import { ForecastInfoScenario, GenerationInfoScenario } from 'src/models/api/ForecastModalResponse'
import { CommercialParameters } from 'src/models/ScenarioDefaultValues'
import { ScenarioFormData } from 'src/models/ScenarioFormData'
import {
  forecastDataSelector,
  getallForecastWithFileTypeDetails,
  getallForecastWithGenerationFileTypeDetails,
} from 'src/stores/AppStore'
import Style from '../../styles/CreateScenario.module.sass'
import { getCapacityLabel } from 'src/helpers/scenarioHelpers'

const quarterCapVolumeHeaders = [
  { label: QuarterCapVolume.YEAR },
  { label: QuarterCapVolume.Q1_CAP_VOLUME },
  { label: QuarterCapVolume.Q1_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q1_STRIKE_PRICE },
  { label: QuarterCapVolume.Q2_CAP_VOLUME },
  { label: QuarterCapVolume.Q2_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q2_STRIKE_PRICE },
  { label: QuarterCapVolume.Q3_CAP_VOLUME },
  { label: QuarterCapVolume.Q3_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q3_STRIKE_PRICE },
  { label: QuarterCapVolume.Q4_CAP_VOLUME },
  { label: QuarterCapVolume.Q4_CAP_PRICE },
  { label: QuarterCapVolumeStep7.Q4_STRIKE_PRICE },
]

export const capexValueHeaders = [{ label: CapexValue.YEAR }, { label: CapexValue.VALUE }]
export const capexValueSummaryHeadersHybrid = [
  { label: CapexValue.CAPEX },
  { label: CapexValue.BESS },
  { label: CapexValue.REGENERATOR },
  { label: CapexValue.COMMONEQUIPMENT },
]
export const repexValueHeaders = [{ label: RepexValue.YEAR }, { label: RepexValue.VALUE }]
export const bessExpectedPerformanceHeaders = [
  { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
  { label: BessExpectedPerformanceCyclePerDay.ENERGYDISCHARGECAPACITYRETENTION },
  { label: BessExpectedPerformanceCyclePerDay.RTE },
]

export const projectCommercialParametersHeaders = [
  { label: ProjectInput.COMMERCIAL_PARAMETERS },
  { label: ProjectInput.VALUE },
]

export const srasContractHeaders = [
  { label: SrasContract.OPERATING_YEAR },
  { label: SrasContract.SRAS_RESERVED_ENERGY_STORAGE_CAPACITY },
  { label: SrasContract.SRAS_CONTRACT_VALUE },
]

export const vcasContractHeaders = [
  { label: VcasContract.OPERATING_YEAR },
  { label: VcasContract.REAL_POWER_CAPABILITY_VCAS },
  { label: VcasContract.VCAS_CONTRACT_VALUE },
]

const inertiaContractHeaders = [
  { label: InertiaContract.OPERATING_YEAR },
  { label: InertiaContract.CONTRACTED_INERTIA_VALUE },
]

const lossFactoryTableHeaders = [
  { label: LossFactoryTableHeader.OPERATING_YEAR },
  { label: LossFactoryTableHeader.GENERATION_MLF },
  { label: LossFactoryTableHeader.LOAD_MLF },
]

const nonMarketReservationHeaders = [
  { label: NonMarket.OPERATING_YEAR },
  { label: NonMarket.DISCHARGE_POWER_RESERVATION },
  { label: NonMarket.CHARGE_POWER_RESERVATION },
  { label: NonMarket.ENERGY_RESERVATION },
]

const getCoupledTypeLabel = (type: string): string => {
  switch (type) {
    case '1':
      return 'AC'
    case '2':
      return 'DC'
    default:
      return ''
  }
}
const getHybridGenerationTypeLabel = (type: string): string => {
  switch (type) {
    case '1':
      return 'Wind'
    case '2':
      return 'Solar'
    default:
      return ''
  }
}

const nmsTableHeader = [{ label: NmsTableHeader.OPERATING_YEAR }, { label: NmsTableHeader.NMSVALUE }]

interface Step7Props {
  formData: ScenarioFormData
  setFormData?: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors?: Record<string, string>
}

export const Step7: React.FC<Step7Props> = ({ formData }) => {
  const fetchForecastData = useRecoilValue(forecastDataSelector)
  const [forecastList, setForecastList] = useState<{ id: string; label: string }[]>([])
  const [generationList, setGenerationList] = useState<{ id: string; label: string }[]>([])
  const fetchForecastDataDetails = useRecoilValue(getallForecastWithFileTypeDetails)
  const fetchGenerationDataDetails = useRecoilValue(getallForecastWithGenerationFileTypeDetails)
  useEffect(() => {
    let maxGenerationTimeInterval = 0
    let maxGenerationPeriod = 0
    let maxForecastTimeInterval = 0
    let maxForecastPeriod = 0
    if (fetchForecastDataDetails && typeof fetchForecastDataDetails === 'object') {
      const forecastDataArray = Object.values(fetchForecastDataDetails).filter(
        (item) => typeof item === 'object',
      ) as Array<ForecastInfoScenario>

      const forecastFormatted = forecastDataArray
        .filter((item) => item.file_processing_status.name === 'SUCCESSFUL')
        .map((item) => {
          if (item.interval > maxForecastTimeInterval) {
            maxForecastTimeInterval = item.interval
          }
          if (item.period > maxForecastPeriod) {
            maxForecastPeriod = item.period
          }
          return {
            id: item.id.toString(),
            label: item.file_name,
            start_date: item.start_date,
            interval: item.interval,
            period: item.period,
          }
        })

      setForecastList(forecastFormatted)
    }
    if (fetchGenerationDataDetails && typeof fetchGenerationDataDetails === 'object') {
      const generationDataArray = Object.values(fetchGenerationDataDetails).filter(
        (item) => typeof item === 'object',
      ) as Array<GenerationInfoScenario>

      const generationFormatted = generationDataArray
        .filter((item) => item.file_processing_status.name === 'SUCCESSFUL')
        .map((item) => {
          if (item.interval > maxGenerationTimeInterval) {
            maxGenerationTimeInterval = item.interval
          }
          if (item.period > maxGenerationPeriod) {
            maxGenerationPeriod = item.period
          }
          return {
            id: item.id.toString(),
            label: item.file_name,
            start_date: item.start_date,
            interval: item.interval,
            period: item.period,
            profile_type: item.profile_type,
          }
        })

      setGenerationList(generationFormatted)
    }
  }, [fetchForecastData, fetchForecastDataDetails, fetchGenerationDataDetails])
  return (
    <div className='mainContainerBlock'>
      <h2 className={Style.heading}>Scenario Summary</h2>
      <Grid row gap={12}>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Scenario Name:</b> {formData.name}{' '}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Associated Forecast File </b>{' '}
            {forecastList
              .filter((item) => item.id === formData.forecast_data_id)
              .map((item) => (
                <span key={item.id}>{item.label}</span>
              ))}
          </p>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <Grid item lg={6} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Generating Profile </b>{' '}
              {generationList
                .filter((item) => item.id === formData.generating_profile_id)
                .map((item) => (
                  <span key={item.id}>{item.label}</span>
                ))}
            </p>
          </Grid>
        )}
        <Grid item lg={12}>
          <p className={Style.summaryContent}>
            <b>Description </b> {formData.description}{' '}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Project life (years) </b> {formData.project_life_years}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>First year of commercial operation: </b> {formData.first_year_of_commercial_operation}{' '}
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Year of deployment </b> {formData.year_of_deployment}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max Energy Output to Grid </b> {formData.max_energy_output_to_grid}{' '}
            {formData.max_energy_output_to_grid_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max Energy Input from Grid </b> {formData.max_energy_input_from_grid}{' '}
            {formData.max_energy_input_from_grid_unit}
          </p>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>RE Type</b> {getHybridGenerationTypeLabel(formData.hybrid_generation_type)}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>DC or AC Coupled</b> {getCoupledTypeLabel(formData.coupled_type)}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>{getCapacityLabel(formData.coupled_type, 'Out')} </b> {formData.max_bess_inverter_capacity_out}{' '}
                {formData.max_energy_input_from_grid_unit}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>{getCapacityLabel(formData.coupled_type, 'In')} </b> {formData.max_bess_inverter_capacity_in}{' '}
                {formData.max_energy_input_from_grid_unit}
              </p>
            </Grid>
            <Grid item lg={6} cssClass={Style.pv0}>
              <p className={Style.summaryContent}>
                <b>Max VRE Inverter Capacity Out </b> {formData.max_vre_inverter_capacity_out}{' '}
                {formData.max_vre_inverter_capacity_out_unit}
              </p>
            </Grid>
          </>
        )}
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max discharge capacity of the BESS </b> {formData.rated_discharge_power}{' '}
            {formData.rated_discharge_power_unit}
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Max charge capacity of the BESS </b> {formData.rated_charge_power} {formData.rated_charge_power_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>BOL storage capacity </b> {formData.bol_storage_capacity} {formData.BOL_storage_capacity_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Inverter capacity </b> {formData.inverter_capacity} {formData.inverter_capacity_unit}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Solve Interval Hours </b> {formData.solve_interval_hours}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Window Hours </b> {formData.window_hours}
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Sampling Interval </b> {formData.time_res}
          </p>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid item lg={12}>
        <h2>Market Inputs</h2>
      </Grid>
      <Grid row gap={6}>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (R1) </b>
            {formData?.r1_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (R6) </b>
            {formData?.r6_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (R60) </b>
            {formData?.r60_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (R5) </b>
            {formData?.r5_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (L1) </b>
            {formData?.l1_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (L6) </b>
            {formData?.l6_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (L60) </b>
            {formData?.l60_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>CFCAS Market (L5) </b>
            {formData?.l5_max_registered_capacity} % of rated capacity
          </p>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Grid row gap={12} cssClass={Style.mt40}>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (Reg raise) </b> {formData.reg_raise_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (Reg lower) </b> {formData.reg_lower_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R1) </b> {formData.r1_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R6) </b> {formData.r6_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R60) </b> {formData.r60_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (R5) </b> {formData.r5_max_enablement} Max Enablement (MW)
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L1) </b> {formData.l1_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L6) </b> {formData.l6_max_enablement} Max Enablement (MW)
          </p>
        </Grid>

        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L60) </b> {formData.l60_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
        <Grid item lg={6} cssClass={Style.pv0}>
          <p className={Style.summaryContent}>
            <b>Market (L5) </b> {formData.l5_max_enablement} Max Enablement (MW)
          </p>
        </Grid>
      </Grid>
      <br />
      <div className={Style.mt40}>
        <Grid row gap={12}>
          <Grid item lg={12} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Raise Reg Utilisation </b> {formData.rreg_utilisation} Avg MW per MW enabled
            </p>
          </Grid>
          <Grid item lg={12} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Lower Reg Utilisation </b> {formData.lreg_utilisation} Avg MW per MW enabled
            </p>
          </Grid>
          <Grid item lg={12} cssClass={Style.pv0}>
            <p className={Style.summaryContent}>
              <b>Inertia Utilisation </b> {formData.inertia_utilisation} average MW per MW.s per MVA
            </p>
          </Grid>
        </Grid>
      </div>
      <br /> <br /> <br />
      <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
        <Table headers={lossFactoryTableHeaders}>
          {formData.loss_factors.slice(0, parseInt(formData.project_life_years)).map((lossFactor) => (
            <TableRow key={lossFactor.year}>
              <TableCell cellClass={Style.tableDataPadding}>{lossFactor.operating_year}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{lossFactor.generation_mlf}</TableCell>
              <TableCell cellClass={Style.tableDataPadding}>{lossFactor.load_mlf}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
      <br />
      <br />
      <Grid row gap={0}>
        <Grid item lg={12}>
          <h2>Cap Contracts</h2>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Cap contracting is intended </b> {formData.Cap_contracting_is_intended ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={Style.pv0}>
          <Table headers={quarterCapVolumeHeaders}>
            {formData.cap_contracts.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q1_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q2_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q3_cap_strike_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_volume}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.q4_cap_strike_price}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid item lg={12}>
        <h2>Network Support Services</h2>
      </Grid>
      <Grid row gap={0}>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Any network support services are intended </b>{' '}
            {formData.network_support_services_intended ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Inertia contract </b> {formData.Inertia_contract ? 'Yes' : 'No'}
          </p>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Selected Inertia Constant (MWs/MVA) </b> {formData.selected_inertia_constant}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={inertiaContractHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.contracted_inertia_value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={nonMarketReservationHeaders}>
            {formData.network_support_services.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.discharge_power_res_non_market_service}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.charge_power_res_non_market_service}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.energy_res_non_market_service}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>

        <br />
        <br />
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={nmsTableHeader}>
            {formData.loss_factors.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.nms_valperhour}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12}>
          <h2>General Input</h2>
        </Grid>
        <Grid item lg={6}>
          <p className={Style.summaryContent}>
            <b>Standby Power Consumption </b> {formData.standby_power_consumption}{' '}
            {formData.standby_power_consumption_unit === '2'
              ? 'KW / MWh installed capacity'
              : 'MW / MWh installed capacity'}
          </p>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={[{ label: ProjectInputLgc.YEAR }, { label: ProjectInputLgc.Defaultwarrantedcycling }]}>
            {formData.commercial_parameters.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.default_warranted_cycling}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12}>
          <h2>BESS Performance</h2>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={bessExpectedPerformanceHeaders}>
            {formData.bess_inputs.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.energy_discharge_capacity}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.rte}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        {String(formData.scenario_project_type) === '2' && (
          <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
            <Grid item lg={12}>
              <h2>Efficiency Parameter</h2>
            </Grid>
            <Table
              headers={[
                { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
                ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                ['1', '2'].includes(String(formData.coupled_type))
                  ? [
                      { label: BessExpectedPerformanceCyclePerDay.GENERATION_EFFICIENCY },
                      { label: BessExpectedPerformanceCyclePerDay.AC_EFFICIENCY },
                    ]
                  : []),
                ...(['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                String(formData.coupled_type) === '1'
                  ? [{ label: BessExpectedPerformanceCyclePerDay.CROSS_TRANSFER_EFFICIENCY }]
                  : [{ label: BessExpectedPerformanceCyclePerDay.DC_EFFICIENCY }]),
              ]}
            >
              {formData.bess_inputs.map((data) => (
                <TableRow key={data.year}>
                  <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>

                  {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                    ['1', '2'].includes(String(formData.coupled_type)) && (
                      <>
                        <TableCell cellClass={Style.tableDataPadding}>{data.generation_efficieny}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{data.ac_charging_efficieny}</TableCell>
                      </>
                    )}

                  {['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                  String(formData.coupled_type) === '1' ? (
                    <TableCell cellClass={Style.tableDataPadding}>{data.transfer_efficieny}</TableCell>
                  ) : (
                    <TableCell cellClass={Style.tableDataPadding}>{data.dc_charging_efficieny}</TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
          </Grid>
        )}
        <Grid item lg={12}>
          <h2>Project Input</h2>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table headers={projectCommercialParametersHeaders}>
            {CommercialParameters.map((data) => (
              <TableRow key={data.keyName}>
                <TableCell cellClass={Style.tableDataPadding}>{data.label}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{formData[data.keyName]}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Grid item lg={12}>
            <h2>Capex</h2>
          </Grid>
          <Table
            headers={
              String(formData.scenario_project_type) === '1' ? capexValueHeaders : capexValueSummaryHeadersHybrid
            }
          >
            {formData.capex.map((data) => (
              <TableRow key={data.year}>
                {String(formData.scenario_project_type) === '1' ? (
                  <>
                    <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.capex_estimate}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.bess_estimate}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.re_generator_estimate}</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>{data.common_equipment}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Grid item lg={12}>
            <h2>Repex</h2>
          </Grid>
          <Table headers={repexValueHeaders}>
            {formData.repex.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.mid_life_repex}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12}>
          <h2>Others</h2>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Table
            headers={[
              { label: ProjectInputLgc.YEAR },
              ...(String(formData.scenario_project_type) === '2' ? [{ label: ProjectInputLgc.REGLTSA }] : []),
              {
                label: formData.is_manual_ltsa_input ? ProjectInputLgc.LTSA_MANUAL : ProjectInputLgc.LTSA_CALCULATED,
              },
              {
                label: formData.is_manual_warranty_input
                  ? ProjectInputLgc.WARRANTY_MANUAL
                  : ProjectInputLgc.WARRANTY_CALCULATED,
              },
              { label: ProjectInputLgc.TUOS },
              { label: ProjectInputLgc.OTHER },
            ]}
          >
            {formData.commercial_parameters.map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                {String(formData.scenario_project_type) === '2' && (
                  <TableCell cellClass={Style.tableDataPadding}>
                    {data.re_generator_long_term_service_agreement_cost}
                  </TableCell>
                )}
                <TableCell cellClass={Style.tableDataPadding}>
                  {formData.is_manual_ltsa_input
                    ? data.manual_long_term_service_agreement_cost
                    : data.long_term_service_agreement_cost_percentage}
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  {formData.is_manual_warranty_input
                    ? data.manual_warranty_cost_absolute
                    : data.warranty_cost_percentage}
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.tuos_cost}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.other_o_and_m_cost}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
          <Grid item lg={12}>
            <h2>LGC</h2>
          </Grid>
          <Table
            headers={[
              { label: ProjectInputLgc.YEAR },
              { label: ProjectInputLgc.LGC },
              { label: ProjectInputLgc.LGC_FRACTION },
            ]}
          >
            {formData.commercial_parameters.map((data) => (
              <TableRow key={data.year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.lgc_price}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{data.lgc_fraction}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
