import { deleteAsync, getAsync, postMultiFormDataAsync, putMultiFormDataAsync } from 'src/helpers/apiRequest'
import { CreateProjectRequestModel } from 'src/models/api/CreateProjectRequestModel'
import { ApiResponse } from 'src/models/api/Projects'
import { ProjectsRequestModal } from 'src/models/api/ProjectsRequestModal'
import { SingleProjectModalResponse } from 'src/models/api/SingleProjectModalResponse'
import { getToken } from './TokenManager'

const fetchProjectDetails = async (projectId: string) => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/projects/${projectId}`
    const response = await getAsync<ProjectsRequestModal, SingleProjectModalResponse>(baseUri, requestbody)
    return response
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
}

const postMultiFormData = async (formData: CreateProjectRequestModel, selectedFile: File | null) => {
  try {
    const token = await getToken()
    const formDataToSend = new FormData()
    formDataToSend.append(
      'project',
      JSON.stringify({
        name: formData.projectName,
        project_number: formData.projectNumber,
        client_name: formData.clientName,
        project_type: Number(formData.project_type),
        is_confidential: formData.is_confidential,
        description: formData.projectDesc,
      }),
    )

    if (selectedFile) {
      formDataToSend.append('file', selectedFile)
    }

    const baseUri = `/v1/projects`

    const createResponse = await postMultiFormDataAsync(baseUri, token, formDataToSend)
    return createResponse
  } catch (error) {
    // eslint-disable-next-line
    const err = error as any // Type assertion
    if (err.response && err.response.status === 400) {
      const errorMessage = err.response.data.detail
      throw new ProjectCreationError(`Failed to create a project: ${errorMessage}`)
    } else {
      throw new Error(`Failed to create a project: ${err.message}`)
    }
  }
}

const putMultiFormData = async (projectId: string, formData: CreateProjectRequestModel, selectedFile: File | null) => {
  try {
    const token = await getToken()
    // Construct FormData object
    const formDataToSend = new FormData()
    formDataToSend.append(
      'project',
      JSON.stringify({
        name: formData.projectName,
        project_number: formData.projectNumber,
        client_name: formData.clientName,
        project_type: Number(formData.project_type),
        is_confidential: formData.is_confidential,
        description: formData.projectDesc,
      }),
    )
    if (selectedFile) {
      formDataToSend.append('file', selectedFile)
    }

    const baseUri = `/v1/projects/${projectId}`
    const updateResponse = await putMultiFormDataAsync(baseUri, token, formDataToSend)
    return updateResponse
  } catch (error) {
    // eslint-disable-next-line
    const err = error as any // Type assertion
    if (err.response && err.response.status === 400) {
      const errorMessage = err.response.data.detail
      throw new ProjectCreationError(`Failed to create a project: ${errorMessage}`)
    } else {
      throw new Error(`Failed to create a project: ${err.message}`)
    }
  }
}

export const deleteProject = async (projectId: string) => {
  try {
    const token = await getToken()
    const requestbody: ProjectsRequestModal = {
      token: token,
    }
    const baseUri = `/v1/projects/${projectId}`

    // Call deleteAsync function to delete the project
    const response = await deleteAsync<ProjectsRequestModal, ApiResponse>(baseUri, requestbody)
    // Handle response or success message
    return response
  } catch (error) {
    console.error('Error deleting project:', error)
    throw error
  }
}

// Custom error type for project creation error
class ProjectCreationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ProjectCreationError'
  }
}

export { putMultiFormData, postMultiFormData, fetchProjectDetails }
