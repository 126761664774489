import {
  Accordion,
  AccordionPanel,
  Button,
  Container,
  Grid,
  Loader,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { fetchExecutionsDetails } from 'src/api/ExecutionsService'
import { downloadForecastFile, fetchTablePreviewData } from 'src/api/ForecastService'
import {
  BessExpectedPerformanceCyclePerDay,
  InertiaContract,
  NmsTableHeader,
  NonMarket,
  ProjectInputLgc,
  QuarterCapVolume,
} from 'src/enums/ScenarioEnums'
import { isRoleEmpty } from 'src/helpers/appRoles'
import {
  CapContract,
  ExecutionsDetailsResponseModel,
  MarginalLossFactor,
  MarketInput,
  MessageJson,
} from 'src/models/api/ExecutionsDetailsResponseModel'
import { ForecastingTableModelItem } from 'src/models/api/ForecastingTableModel'
import { IResponse } from 'src/models/api/IResponse'
import { inProductedSecretState, selectedProjectIdState } from 'src/stores/AppStore'
import Style from '../../styles/Execution.module.sass'
import TablePreview from '../Forecasting/TablePreview'
import {
  bessExpectedPerformanceHeaders,
  capexValueHeaders,
  capexValueSummaryHeadersHybrid,
  projectCommercialParametersHeaders,
  repexValueHeaders,
} from '../scenario/Step7'

const quarterExecutionsCapVolumeHeaders = [
  { label: QuarterCapVolume.YEAR },
  { label: QuarterCapVolume.Q1_CAP_VOLUME },
  { label: QuarterCapVolume.Q1_CAP_PRICE },
  { label: QuarterCapVolume.Q1_STRIKE_PRICE },
  { label: QuarterCapVolume.Q2_CAP_VOLUME },
  { label: QuarterCapVolume.Q2_CAP_PRICE },
  { label: QuarterCapVolume.Q2_STRIKE_PRICE },
  { label: QuarterCapVolume.Q3_CAP_VOLUME },
  { label: QuarterCapVolume.Q3_CAP_PRICE },
  { label: QuarterCapVolume.Q3_STRIKE_PRICE },
  { label: QuarterCapVolume.Q4_CAP_VOLUME },
  { label: QuarterCapVolume.Q4_CAP_PRICE },
  { label: QuarterCapVolume.Q3_STRIKE_PRICE },
]
const inertContractHeaders = [
  { label: InertiaContract.OPERATING_YEAR },
  { label: InertiaContract.CONTRACTED_INERTIA_VALUE },
]

const nonMarketReserveHeaders = [
  { label: NonMarket.OPERATING_YEAR },
  { label: NonMarket.DISCHARGE_POWER_RESERVATION },
  { label: NonMarket.CHARGE_POWER_RESERVATION },
  { label: NonMarket.ENERGY_RESERVATION },
]

const nmsTabHeader = [{ label: NmsTableHeader.OPERATING_YEAR }, { label: NmsTableHeader.NMSVALUE }]

const ExecutionsDetail = () => {
  const { scenario_id = '' } = useParams<{ scenario_id: string }>()
  const navigate = useNavigate()
  const projectId = useRecoilValue(selectedProjectIdState)
  const SecretState = useRecoilValue(inProductedSecretState)

  const [executionsMessageJSON, setExecutionsMessageJSON] = useState<MessageJson | null>(null)
  const [executionsMarketInput, setExecutionsMarketInput] = useState<MarketInput | null>(null)
  const [executionsMLFData, setExecutionsMLFData] = useState<MarginalLossFactor[] | null>(null)
  const [tableData, setTableData] = useState<ForecastingTableModelItem[] | null>(null)
  const [selected, setSelected] = useState('')
  const [downloadProgress, setDownloadProgress] = useState<number | null>(null)
  const [scenario_forecast_id, setScenario_forecast_id] = useState<number | null>(null)
  const [executionsDetailsData, setExecutionsDetailsData] = useState<IResponse<ExecutionsDetailsResponseModel> | null>(
    null,
  )
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await fetchExecutionsDetails(Number(scenario_id))
        if (response) {
          setExecutionsDetailsData(response)
          const messageJSON = response?.['message_json']
          setExecutionsMessageJSON(messageJSON)
          if (messageJSON?.scenario_data?.scenario_detail) {
            setExecutionsMarketInput(messageJSON.scenario_data?.scenario_detail?.market_inputs[0])
            setExecutionsMLFData(messageJSON.scenario_data?.scenario_detail?.marginal_loss_factors)
          }
          const forecastDataId = response?.['scenario'].forecast_data.id
          setScenario_forecast_id(forecastDataId)
          if (forecastDataId) {
            const fetchResponse = await fetchTablePreviewData(forecastDataId)
            if (fetchResponse) {
              setTableData(fetchResponse)
            }
          }
        }
      } catch (error) {
        console.error('Error fetching execution details:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [scenario_id, scenario_forecast_id])

  useEffect(() => {
    if (appInsights) appInsights.trackPageView({ name: 'ExecutionsDetail' })
  }, [])

  const togglePanel = (panel: string) => {
    setSelected(panel === selected ? '' : panel)
  }

  const handleDownloadClick = async () => {
    try {
      if (scenario_forecast_id) {
        await downloadForecastFile(scenario_forecast_id, (progress) => {
          setDownloadProgress(progress)
        })
        setDownloadProgress(null)
      }
    } catch (error) {
      console.error('Failed to download forecast:', error, downloadProgress)
      setDownloadProgress(null)
    }
  }

  const handleGoBack = () => {
    navigate(`/dashboard/${projectId}/executionlist`)
  }
  if (isLoading) {
    return (
      <div className='displayCenter'>
        <Loader />
      </div>
    )
  }
  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <div className={Style.go_back}>
              <Button
                type='custom'
                cssClass={Style.goBackBtn}
                label='Go back'
                icon='arrow_back_ios'
                size='small'
                onClick={handleGoBack}
              />
              <h2>Scenarios - {executionsDetailsData?.['scenario']?.['name']}</h2>
            </div>
            {SecretState && !isRoleEmpty(SecretState.roles) && (
              <Button
                type='custom'
                cssClass={Style.newExecBtn}
                label='Download Forecast Files'
                onClick={handleDownloadClick}
              />
            )}
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <section className={Style.detail_wrapper}>
          <Container fluid cssClass={Style.paddingX}>
            <Grid row gap={6}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={Style.grid_cell}>
                  <h4>Scenario name</h4>
                  <p>{executionsDetailsData?.['scenario']?.['name']}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={Style.grid_cell}>
                  <h4>Scenario ID</h4>
                  <p>{executionsDetailsData?.['scenario']?.['id']}</p>
                </div>
              </Grid>
              <Grid item lg={6} cssClass={Style.pv0}>
                <div className={Style.grid_cell}>
                  <h4>Associated Forecast File </h4>
                  <p>{executionsMessageJSON?.scenario_data.forecast_data.file_name}</p>
                </div>
              </Grid>
              {String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '2' && (
                <Grid item lg={6} cssClass={Style.pv0}>
                  <div className={Style.grid_cell}>
                    <h4>Generating Profile </h4>
                    <p>{executionsMessageJSON?.scenario_data.generating_profile_data.file_name}</p>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={Style.grid_cell}>
                  <h4>Description</h4>
                  <p>{executionsDetailsData?.['scenario']?.['description']}</p>
                </div>
              </Grid>
            </Grid>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Project life (years) </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.project_life_years}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>First year of commercial operation </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.first_year_of_commercial_operation}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max Energy Output to Grid </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.max_energy_output_to_grid} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max Energy Output from Grid </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.max_energy_input_from_grid} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max discharge capacity of the BESS </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.rated_discharge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Max charge capacity of the BESS </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.rated_charge_power} MW</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>BOL storage capacity </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.bol_storage_capacity} MWh</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Inverter capacity </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.inverter_capacity} MVA</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Solve Interval Hours </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.solve_interval_hours}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Window Hours </h4> <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.window_hours}</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Sampling Interval </h4> <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.time_res}</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R1) </h4>
                    <p>{executionsMarketInput?.r1_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R6) </h4>
                    <p>{executionsMarketInput?.r6_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R60) </h4>
                    <p>{executionsMarketInput?.r60_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (R5) </h4>
                    <p>{executionsMarketInput?.r5_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L1) </h4>
                    <p>{executionsMarketInput?.l1_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L6) </h4>
                    <p>{executionsMarketInput?.l6_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L60) </h4>
                    <p>{executionsMarketInput?.l60_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>CFCAS Market (L5) </h4>
                    <p>{executionsMarketInput?.l5_max_registered_capacity} of rated capacity</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (Reg raise) </h4>
                    <p>{executionsMarketInput?.reg_raise_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (Reg lower) </h4>
                    <p>{executionsMarketInput?.reg_lower_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R1) </h4>
                    <p>{executionsMarketInput?.r1_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R6) </h4>
                    <p>{executionsMarketInput?.r6_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R60) </h4>
                    <p>{executionsMarketInput?.r60_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (R5) </h4>
                    <p>{executionsMarketInput?.r5_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L1) </h4>
                    <p>{executionsMarketInput?.l1_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L6) </h4>
                    <p>{executionsMarketInput?.l6_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L60) </h4>
                    <p>{executionsMarketInput?.l60_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Market (L5) </h4>
                    <p>{executionsMarketInput?.l5_max_enablement} Max Enablement (MW)</p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              <Grid row gap={12}>
                <Grid item lg={12} cssClass={Style.pv0}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Raise Reg Utilisation </h4>
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.rreg_utilisation} {''} Avg MW per MW
                      enabled
                    </p>
                  </div>
                </Grid>
                <Grid item lg={12} cssClass={Style.pv0}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Lower Reg Utilisation </h4>
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.lreg_utilisation} {''} Avg MW per MW
                      enabled
                    </p>
                  </div>
                </Grid>
                <Grid item lg={12} cssClass={Style.pv0}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Inertia Utilisation </h4>{' '}
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.inertia_utilisation} {''} average MW per
                      MW.s per MVA
                    </p>
                  </div>
                </Grid>
              </Grid>
            </section>
            <section className={Style.margin_tb_4}>
              {executionsMLFData?.map((item) => (
                <React.Fragment key={item?.id}>
                  <Grid row gap={6}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Operating</h4>
                        <p>{item?.operating_year}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Generation MLF</h4>
                        <p>{item?.generation_mlf}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                      <div className={Style.inline_grid_cell}>
                        <h4>Load MLF</h4>
                        <p>{item?.load_mlf}</p>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </section>

            <section className={Style.margin_tb_4}>
              <Grid row gap={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Cap contracting is intended </h4>
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.is_cap_contract_intended ? 'Yes' : 'No'}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <div className={Style.tbl_container}>
                <Table headers={quarterExecutionsCapVolumeHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.cap_contracts.map((item: CapContract) => (
                    <TableRow key={item.id}>
                      <TableCell align='center'>{item.year}</TableCell>
                      <TableCell align='center'>{item.q1_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q1_cap_price}</TableCell>
                      <TableCell align='center'>{item.q1_cap_strike_price}</TableCell>
                      <TableCell align='center'>{item.q2_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q2_cap_price}</TableCell>
                      <TableCell align='center'>{item.q2_cap_strike_price}</TableCell>
                      <TableCell align='center'>{item.q3_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q3_cap_price}</TableCell>
                      <TableCell align='center'>{item.q3_cap_strike_price}</TableCell>
                      <TableCell align='center'>{item.q4_cap_volume}</TableCell>
                      <TableCell align='center'>{item.q4_cap_price}</TableCell>
                      <TableCell align='center'>{item.q4_cap_strike_price}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </div>
              <br />
              <br />
              <Grid item lg={12}>
                <h2>Network Support Services</h2>
              </Grid>
              <Grid row gap={6}>
                <Grid item lg={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Any network support services are intended </h4>
                    <p>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.is_network_services_intended
                        ? 'Yes'
                        : 'No'}
                    </p>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Inertia contract </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.inertia_contract ? 'Yes' : 'No'}</p>
                  </div>
                </Grid>
                <br />
                <br />
                <Grid item lg={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>Selected Inertia Constant (MWs/MVA) </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.selected_inertia_constant}</p>
                  </div>
                </Grid>
                {/* <Grid item lg={6}>
                  <div className={Style.inline_grid_cell}>
                    <h4>RoCoF design criteria (Hz/s) </h4>
                    <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.rocof_design_criteria}</p>
                  </div>
                </Grid> */}
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={inertContractHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.contracted_inertia_value}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={nonMarketReserveHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.network_support_services.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        {data.discharge_power_res_non_market_service}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        {data.charge_power_res_non_market_service}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.energy_res_non_market_service}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <section className={Style.margin_tb_4}>
                <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                  <Table headers={nmsTabHeader}>
                    {executionsMLFData?.map((data) => (
                      <TableRow key={data.year}>
                        <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                        <TableCell cellClass={Style.tableDataPadding}>{data.nms_valperhour}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </section>
              <Grid item lg={12}>
                <h2>General Input</h2>
              </Grid>
              <Grid item lg={6}>
                <div className={Style.inline_grid_cell}>
                  <h4>Standby Power Consumption </h4>
                  <p>{executionsMessageJSON?.scenario_data?.scenario_detail?.standby_power_consumption} MW</p>
                </div>
              </Grid>
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={[{ label: ProjectInputLgc.YEAR }, { label: ProjectInputLgc.Defaultwarrantedcycling }]}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.commercial_parameters.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.default_warranted_cycling}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12}>
                <h2>BESS Performance</h2>
              </Grid>
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={bessExpectedPerformanceHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.bess_inputs.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.energy_discharge_capacity}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.rte}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12}>
                <h2>Efficiency Parameter</h2>
              </Grid>
              {String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '2' && (
                <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                  <Table
                    headers={[
                      { label: BessExpectedPerformanceCyclePerDay.OPERATING_YEAR },
                      ...(['1', '2'].includes(
                        String(executionsMessageJSON?.scenario_data?.scenario_detail?.hybrid_generation_type),
                      ) &&
                      ['1', '2'].includes(String(executionsMessageJSON?.scenario_data?.scenario_detail?.coupled_type))
                        ? [
                            { label: BessExpectedPerformanceCyclePerDay.GENERATION_EFFICIENCY },
                            { label: BessExpectedPerformanceCyclePerDay.AC_EFFICIENCY },
                          ]
                        : []),
                      ...(['1', '2'].includes(
                        String(executionsMessageJSON?.scenario_data?.scenario_detail?.hybrid_generation_type),
                      ) && String(executionsMessageJSON?.scenario_data?.scenario_detail?.coupled_type) === '1'
                        ? [{ label: BessExpectedPerformanceCyclePerDay.CROSS_TRANSFER_EFFICIENCY }]
                        : [{ label: BessExpectedPerformanceCyclePerDay.DC_EFFICIENCY }]),
                    ]}
                  >
                    {executionsMessageJSON?.scenario_data?.scenario_detail?.bess_inputs.map((data) => (
                      <TableRow key={data.year}>
                        <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>

                        {['1', '2'].includes(
                          String(executionsMessageJSON?.scenario_data?.scenario_detail?.hybrid_generation_type),
                        ) &&
                          ['1', '2'].includes(
                            String(executionsMessageJSON?.scenario_data?.scenario_detail?.coupled_type),
                          ) && (
                            <>
                              <TableCell cellClass={Style.tableDataPadding}>{data.generation_efficieny}</TableCell>
                              <TableCell cellClass={Style.tableDataPadding}>{data.ac_charging_efficieny}</TableCell>
                            </>
                          )}

                        {['1', '2'].includes(
                          String(executionsMessageJSON?.scenario_data?.scenario_detail?.hybrid_generation_type),
                        ) && String(executionsMessageJSON?.scenario_data?.scenario_detail?.coupled_type) === '1' ? (
                          <TableCell cellClass={Style.tableDataPadding}>{data.transfer_efficieny}</TableCell>
                        ) : (
                          <TableCell cellClass={Style.tableDataPadding}>{data.dc_charging_efficieny}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              )}
              <Grid item lg={12}>
                <h2>Project Input</h2>
              </Grid>
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={projectCommercialParametersHeaders}>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>
                      O&M + Repex Inflation Rate (annual, real basis)
                    </TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.o_and_m_inflation_rate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>
                      Percent of Perfect - Wholesale Energy Generation
                    </TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.pop_wholesale_energy_generation}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>Percent of Perfect - Wholesale Energy Load</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.pop_wholesale_energy_load}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>Percent of Perfect - Reg FCAS</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.pop_reg_fcas}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>Percent of Perfect - Contingency FCAS</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.pop_contingency_fcas}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>Standard Inflation Rate</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.standard_inflation_rate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell cellClass={Style.tableDataPadding}>Discount rate</TableCell>
                    <TableCell cellClass={Style.tableDataPadding}>
                      {executionsMessageJSON?.scenario_data?.scenario_detail?.npv_discount_rate}
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table
                  headers={
                    String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '1'
                      ? capexValueHeaders
                      : capexValueSummaryHeadersHybrid
                  }
                >
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.capex.map((data) => (
                    <TableRow key={data.year}>
                      {String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '1' ? (
                        <>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>{data.year}</TableCell>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>
                            {data.capex_estimate}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>{data.year}</TableCell>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>{data.bess_estimate}</TableCell>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>
                            {data.re_generator_estimate}
                          </TableCell>
                          <TableCell cellClass={Style.executionDetailsTableDataPadding}>
                            {data.common_equipment}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table headers={repexValueHeaders}>
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.repex.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.mid_life_repex}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12}>
                <h2>Others</h2>
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Table
                  headers={[
                    { label: ProjectInputLgc.YEAR },
                    ...(String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '2'
                      ? [{ label: ProjectInputLgc.BESSLTSA }, { label: ProjectInputLgc.REGLTSA }]
                      : []),
                    {
                      label: executionsMessageJSON?.scenario_data?.scenario_detail?.is_manual_ltsa_input
                        ? ProjectInputLgc.LTSA_MANUAL
                        : ProjectInputLgc.LTSA_CALCULATED,
                    },
                    {
                      label: executionsMessageJSON?.scenario_data?.scenario_detail?.is_manual_warranty_input
                        ? ProjectInputLgc.WARRANTY_MANUAL
                        : ProjectInputLgc.WARRANTY_CALCULATED,
                    },
                    { label: ProjectInputLgc.TUOS },
                    { label: ProjectInputLgc.OTHER },
                  ]}
                >
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.commercial_parameters.map((data) => (
                    <TableRow key={data.operating_year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      {String(executionsMessageJSON?.scenario_data?.scenario_detail?.scenario_project_type) === '2' && (
                        <>
                          <TableCell cellClass={Style.tableDataPadding}>
                            {data.bess_long_term_service_agreement_cost}
                          </TableCell>
                          <TableCell cellClass={Style.tableDataPadding}>
                            {data.re_generator_long_term_service_agreement_cost}
                          </TableCell>
                        </>
                      )}
                      <TableCell cellClass={Style.tableDataPadding}>
                        {!executionsMessageJSON?.scenario_data?.scenario_detail?.is_manual_ltsa_input
                          ? data.long_term_service_agreement_cost_percentage
                          : data.manual_long_term_service_agreement_cost}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        {!executionsMessageJSON?.scenario_data?.scenario_detail?.is_manual_warranty_input
                          ? data.warranty_cost_percentage
                          : data.manual_warranty_cost_absolute}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.tuos_cost}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.other_o_and_m_cost}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <br />
              <br />
              <Grid item lg={12} cssClass={(Style.pv0, Style.tableMarginBtm)}>
                <Grid item lg={12}>
                  <h2>LGC</h2>
                </Grid>
                <Table
                  headers={[
                    { label: ProjectInputLgc.YEAR },
                    { label: ProjectInputLgc.LGC },
                    { label: ProjectInputLgc.LGC_FRACTION },
                  ]}
                >
                  {executionsMessageJSON?.scenario_data?.scenario_detail?.commercial_parameters.map((data) => (
                    <TableRow key={data.year}>
                      <TableCell cellClass={Style.tableDataPadding}>{data.operating_year}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.lgc_price}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{data.lgc_fraction}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <Accordion activePanelIds={selected} onPanelToggle={togglePanel}>
                <AccordionPanel
                  cssHeaderClass={Style.expandMore}
                  panelId='p1'
                  label={<h4 className={Style.txtLabel}>Forecast file preview</h4>}
                >
                  {tableData && <TablePreview data={tableData} />}
                </AccordionPanel>
              </Accordion>
            </section>
          </Container>
        </section>
      </div>
    </Container>
  )
}

export default ExecutionsDetail
