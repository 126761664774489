export const SUPPORT_EMAIL = 'app.support@aurecongroup.com'
export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/???'
export const ABOUT_URL = 'https://aurecongroup.sharepoint.com/sites/Hive-Digital/SitePages/???????.aspx'
export const REQUEST_ACCESS_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/???'

const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 'NLeF6b4djXautbyrhHoUMiMFSnHP2lQk',
  AUTH0_AUDIENCE: 'https://esro.aurecongroup.digital',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  API_BASE_URL: '/',
}

export default config
