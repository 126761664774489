import { useToast } from '@aurecon-creative-technologies/styleguide'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { deleteReport } from 'src/api/ReportService'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { fetchReportsDataSelector } from 'src/stores/AppStore'

interface ReportDeleteProps {
  reportId?: number
  onClose: () => void
}

const ReportDelete: React.FC<ReportDeleteProps> = ({ reportId, onClose }) => {
  if (appInsights) appInsights.trackPageView({ name: 'DeleteReport' })
  const showToast = useToast()
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(fetchReportsDataSelector)

  const handleCancel = () => {
    onClose()
  }

  const handleDelete = async () => {
    if (reportId) {
      const response = await deleteReport(reportId)
      if (response) {
        showToast.addToast({
          type: 'success',
          message: 'Report deleted successfully',
          timeout: 3000,
        })
        refreshFetchItems()
      } else {
        showToast.addToast({
          type: 'error',
          message: 'Failed to delete the report',
          timeout: 3000,
        })
      }
    }
    onClose()
  }
  return (
    <ConfirmationModal
      title='Confirm Deleting Files'
      message='This action cannot be undone. Are you sure you want to continue?'
      onCancel={handleCancel}
      onConfirm={handleDelete}
    />
  )
}

export default ReportDelete
