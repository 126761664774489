import {
  Container,
  Loader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/Dashboard.module.sass'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import { dashboardCurrentPage, dashboardOverallSummary, dashboardSortOrderState } from 'src/stores/AppStore'
import React, { startTransition, useCallback, useEffect, useMemo, useState } from 'react'
import { DEFAULT_PAGINATION_DISPLAY_PAGES } from 'src/config/constant'
import { dateToString } from 'src/helpers/utils'
import { OverallSummaryItem } from 'src/models/api/DashboardResponseModel'
type SortOrder = 'asc' | 'none' | 'desc'
type SortKey =
  | 'scenario.name'
  | 'scenario.id'
  | 'npv_and_irr_calculation.irr_percentage'
  | 'npv_and_irr_calculation.net_npv'
  | 'scenario.description'
  | 'npv_and_irr_calculation.eol_storage_capacity'
  | 'npv_and_irr_calculation.avg_of_annual_avg_cycles_daily'
  | 'created_at'

const OverallSummary = () => {
  const [currentPage, setCurrentPage] = useRecoilState(dashboardCurrentPage)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [overallSummary, setOverallSummary] = useState<OverallSummaryItem[]>([])
  const getOverallSummary = useRecoilValueLoadable(dashboardOverallSummary)
  const sortColumnState = useRecoilState(dashboardSortOrderState)
  const [sortConfig, setSortConfig] = useState<{ key: SortKey; order: SortOrder }>({ key: 'created_at', order: 'asc' })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (getOverallSummary.state === 'hasValue' && getOverallSummary.contents) {
      startTransition(() => {
        if (getOverallSummary.contents['items'] !== undefined) {
          setOverallSummary(getOverallSummary.contents['items'])
          setTotalCount(getOverallSummary.contents['total'])
          setTotalPages(getOverallSummary.contents['pages'])
        } else {
          setOverallSummary([])
          setTotalPages(1)
        }
      })
    }
  }, [getOverallSummary, sortColumnState])

  useEffect(() => {
    if (getOverallSummary.state === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [getOverallSummary.state])

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page === 0 ? 1 : page)
  }

  const handleSort = useCallback((key: SortKey) => {
    setSortConfig((prevConfig) => {
      const newOrder = prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc'
      return { key, order: newOrder }
    })
  }, [])

  const sortedItems = useMemo(() => {
    const indexedSummary = overallSummary.map((item, index) => ({
      ...item,
      originalIndex: index,
    }))

    const getValue = (item: OverallSummaryItem, key: SortKey) => {
      switch (key) {
        case 'scenario.name':
          return item.scenario.name
        case 'scenario.id':
          return item.scenario.id
        case 'npv_and_irr_calculation.irr_percentage':
          return item.npv_and_irr_calculation.irr_percentage
        case 'npv_and_irr_calculation.net_npv':
          return item.npv_and_irr_calculation.net_npv
        case 'scenario.description':
          return item.scenario.description
        case 'npv_and_irr_calculation.eol_storage_capacity':
          return item.npv_and_irr_calculation.eol_storage_capacity
        case 'npv_and_irr_calculation.avg_of_annual_avg_cycles_daily':
          return item.npv_and_irr_calculation.avg_of_annual_avg_cycles_daily
        case 'created_at':
          return item.created_at
        default:
          return ''
      }
    }

    const sorted = [...indexedSummary].sort((a, b) => {
      if (sortColumnState.toString()) {
        return sortConfig.order === 'asc' ? a.originalIndex - b.originalIndex : b.originalIndex - a.originalIndex
      }

      const itemAValue = getValue(a, sortConfig.key)
      const itemBValue = getValue(b, sortConfig.key)

      if (sortConfig.order === 'asc') {
        if (itemAValue < itemBValue) return -1
        if (itemAValue > itemBValue) return 1
        return 0
      } else {
        if (itemAValue > itemBValue) return -1
        if (itemAValue < itemBValue) return 1
        return 0
      }
    })

    return sorted
  }, [overallSummary, sortConfig, sortColumnState])

  return (
    <Container fluid>
      <div className={Style.grid_container}>
        {isLoading && (
          <div className={Style.loaderWrapper}>
            <Loader />
          </div>
        )}
        {!isLoading && overallSummary.length > 0 ? (
          <>
            <div className={Style.overflowx}>
              <Table
                headers={[
                  {
                    label: 'Scenario Name',
                    sort: sortConfig.key === 'scenario.name' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('scenario.name'),
                  },
                  {
                    label: 'Scenario',
                    sort: sortConfig.key === 'scenario.id' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('scenario.id'),
                  },
                  {
                    label: 'IRR',
                    sort:
                      sortConfig.key === 'npv_and_irr_calculation.irr_percentage'
                        ? (sortConfig.order as SortOrder)
                        : 'none',
                    onSort: () => handleSort('npv_and_irr_calculation.irr_percentage'),
                  },
                  {
                    label: 'NPV',
                    sort:
                      sortConfig.key === 'npv_and_irr_calculation.net_npv' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('npv_and_irr_calculation.net_npv'),
                  },
                  {
                    label: 'Description',
                  },
                  {
                    label: 'EOL Storage Capacity',
                    sort:
                      sortConfig.key === 'npv_and_irr_calculation.eol_storage_capacity'
                        ? (sortConfig.order as SortOrder)
                        : 'none',
                    onSort: () => handleSort('npv_and_irr_calculation.eol_storage_capacity'),
                  },
                  {
                    label: 'Date',
                    sort: sortConfig.key === 'created_at' ? (sortConfig.order as SortOrder) : 'none',
                    onSort: () => handleSort('created_at'),
                  },
                ]}
              >
                {!isLoading &&
                  sortedItems.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell cellClass={Style.tableDataPadding}>{row.scenario?.name}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{row.scenario.id}</TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        {row?.npv_and_irr_calculation?.irr_percentage}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{row.npv_and_irr_calculation?.net_npv}</TableCell>
                      <TableCell cellClass={(Style.tableDataPadding, Style.scenariodesc)}>
                        <Tooltip
                          show={row.scenario?.description}
                          offset={{
                            left: 2,
                            top: 0,
                          }}
                        >
                          <div
                            className={Style.tooltipScenarioDescription}
                            style={{
                              width: '300px',
                              display: 'inline-block',
                            }}
                          >
                            {row.scenario?.description}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>
                        {row.npv_and_irr_calculation?.eol_storage_capacity}
                      </TableCell>
                      <TableCell cellClass={Style.tableDataPadding}>{dateToString(row.created_at)}</TableCell>
                    </TableRow>
                  ))}
              </Table>
            </div>
            {!isLoading && overallSummary.length !== 0 && (
              <div className={Style.paginationCont}>
                <div className={Style.paginationButtons}>
                  <Pagination
                    page={currentPage}
                    pageCount={totalPages}
                    displayPages={DEFAULT_PAGINATION_DISPLAY_PAGES}
                    onChange={handlePaginationClick}
                    prevButtonTitle='Previous page'
                    nextButtonTitle='Next page'
                    cssClass={Style.paginatCont}
                    showJumpToInput
                  />
                </div>
                <div className={Style.totalPageCount}>
                  {currentPage} - {totalPages} of {totalCount}
                </div>
              </div>
            )}
          </>
        ) : (
          !isLoading && <div className={Style.noRecords}>No records found</div>
        )}
      </div>
    </Container>
  )
}

export default OverallSummary
