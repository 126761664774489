import axios, { AxiosError, AxiosProgressEvent, AxiosResponse, CancelToken } from 'axios'
import { HttpContentType, HttpMethod } from '../enums/ApiRequestConstants'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import { IResponse, IResponseTypes, WrapIResponse } from '../models/api/IResponse'
import { downloadAction } from './downloadFile'
interface ErrorResponse {
  detail: string
}
const responseFactory = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [HttpContentType.JSON]: (response: Response): Promise<any> => response.json(),
  [HttpContentType.TEXT]: async (response: Response): Promise<IResponse<string>> =>
    WrapIResponse(await response.text(), response.status),
  [HttpContentType.BLOB]: async (response: Response): Promise<IResponse<Blob>> =>
    WrapIResponse(await response.blob(), response.status),
}

export const headersWithToken = (token: string | undefined, contentType = HttpContentType.JSON): HeadersInit => {
  const headers = {
    Authorization: `Bearer ${token}`,
  }

  if (contentType !== HttpContentType.BLOB) {
    headers['Content-Type'] = contentType
  }

  return headers
}

export const getAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.GET, contentType, cancel)

export const getAsyncList = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsyncList(url, data, HttpMethod.GET, contentType, cancel)

export const postAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.POST, contentType, cancel)

export const putAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.PUT, contentType, cancel)

export const patchAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.PATCH, contentType, cancel)

export const deleteAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  url: string,
  data: TRequest,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => handleRequestAsync(url, data, HttpMethod.DELETE, contentType, cancel)

export const postMultiFormDataAsync = async <TData extends IResponseTypes>(
  url: string,
  token: string,
  data: FormData,
  method = HttpMethod.POST,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  /**
   * This method to hhandle the create form post request to the api with image as a form data
   */
  const postResponse = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
    method,
    body: data,
    signal: cancel?.signal,
  })
  const status = postResponse.status
  if (postResponse.ok) {
    const postResult: TData = await postResponse.json()
    return { success: true, data: postResult, status }
  }

  const errorDetails = await postResponse.json()
  return {
    success: false,
    message: `${postResponse.statusText}: ${errorDetails.detail || 'An error occurred'}`,
    status,
  }
}

/**
 * postMultiFormDataAsyncUploadProgress is to post api call with csv files and it returns progress as well
 */
const postMultiFormDataAsyncUploadProgress = async <TData extends IResponseTypes>(
  url: string,
  token: string,
  formData: FormData,
  onUploadProgress: (progress: number) => void,
  cancelToken?: CancelToken,
): Promise<IResponse<TData>> => {
  try {
    const response: AxiosResponse<TData> = await axios.post(url, formData, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: cancelToken,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => handleProgressEvent(progressEvent, onUploadProgress),
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => handleProgressEvent(progressEvent, onUploadProgress),
    })

    return { success: true, data: response.data, status: response.status }
  } catch (error) {
    if (error instanceof AxiosError) {
      return handleAxiosError<TData>(error)
    } else {
      return handleGenericError<TData>()
    }
  }
}

export const getFileWithDownloadProgress = async <TData extends IResponseTypes>(
  url: string,
  token: string,
  onDownloadProgress: (progress: number) => void,
  cancelToken?: CancelToken,
): Promise<IResponse<TData>> => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(url, {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: cancelToken,
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => handleProgressEvent(progressEvent, onDownloadProgress),
    })

    const contentDisposition = response.headers['content-disposition']
    const fileName = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/"/g, '')
      : 'downloadedFile.csv'
    downloadAction(new Blob([response.data]), fileName)
    return { success: true, data: response.data as any, status: response.status }
  } catch (error) {
    if (error instanceof AxiosError) {
      return handleAxiosError<TData>(error)
    } else {
      return handleGenericError<TData>()
    }
  }
}

export const putMultiFormDataAsync = async <TData extends IResponseTypes>(
  url: string,
  token: string,
  data: FormData,
  method = HttpMethod.PUT,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  /**
   * This method to handle to update form put request to the api with image as a form data
   */
  try {
    const putResponse = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      method,
      body: data,
      signal: cancel?.signal,
    })

    if (putResponse.ok) {
      const postResult: TData = await putResponse.json()
      return { success: true, data: postResult, status: putResponse.status }
    }

    const errorDetails = await putResponse.json()
    return {
      success: false,
      message: `${putResponse.statusText}: ${errorDetails.detail || 'An error occurred'}`,
      status: putResponse.status,
    }
  } catch (error) {
    return {
      success: false,
      message: 'An error occurred while processing the request',
      status: 500,
    }
  }
}

const handleProgressEvent = (progressEvent: AxiosProgressEvent, onProgress: (progress: number) => void) => {
  if (progressEvent.total !== null && progressEvent.total !== undefined) {
    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    onProgress(progress)
  }
}

const handleRequestAsync = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  apiUrl: string,
  data: TRequest,
  method: string,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  const token = data.token
  data.token = undefined

  const response = await fetch(apiUrl, {
    headers: headersWithToken(token, contentType),
    method: method,
    body: method !== HttpMethod.GET ? JSON.stringify(data) : null,
    signal: cancel?.signal,
  })
  const result: IResponse<TData> = {
    status: response.status,
    success: response.ok,
    message: response.ok ? undefined : response.statusText,
    ...(await responseFactory[contentType ?? HttpContentType.JSON](response)),
  }
  return result
}

const handleRequestAsyncList = async <TRequest extends IBodyRequestModel, TData extends IResponseTypes>(
  apiUrl: string,
  data: TRequest,
  method: string,
  contentType?: string,
  cancel?: AbortController,
): Promise<IResponse<TData>> => {
  const token = data.token
  data.token = undefined

  const response = await fetch(apiUrl, {
    headers: headersWithToken(token, contentType),
    method: method,
    body: method !== HttpMethod.GET ? JSON.stringify(data) : null,
    signal: cancel?.signal,
  })

  const result: IResponse<TData> = response.ok
    ? await responseFactory[contentType ?? HttpContentType.JSON](response)
    : { success: false, message: response.statusText }
  return result
}

const handleAxiosError = <TData extends IResponseTypes>(error: AxiosError<ErrorResponse>): IResponse<TData> => {
  if (error.response) {
    const errorData = error.response.data
    return {
      success: false,
      message: `${error.response.statusText}: ${errorData.detail || 'An error occurred'}`,
      status: error.response.status,
    }
  } else {
    return {
      success: false,
      message: 'An error occurred while processing the request',
      status: error.status ?? 500,
    }
  }
}

const handleGenericError = <TData extends IResponseTypes>(): IResponse<TData> => ({
  success: false,
  message: 'An error occurred while processing the request',
  status: 500,
})

export { postMultiFormDataAsyncUploadProgress }
