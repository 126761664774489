import { Container, Dropdown, SearchBox, Text } from '@aurecon-creative-technologies/styleguide'
import React, { FC } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { projectSortColumnState, projectSortOrderState, searchQueryState } from 'src/stores/AppStore'
import Style from '../styles/SearchFilter.module.sass'
import classNames from 'classnames'
import { ReactComponent as AsecIcon } from '../assets/asecicon.svg'
import { ReactComponent as DescIcon } from '../assets/descicon.svg'
import { debounce } from 'lodash'

interface IDropdownItemProps {
  id: string
  label: string
}

interface ISearchFilterProps {
  placeholder: string
  dropdownItems: IDropdownItemProps[]
  sortText: string
}

const SortButton: FC<{ sortOrder: string | null; onClick: () => void }> = ({ sortOrder, onClick }) => {
  return (
    <button onClick={onClick} className={Style.sortBtn} aria-label='Sort order'>
      {sortOrder === 'desc' && <AsecIcon height='20' />}
      {sortOrder === 'asc' && <DescIcon height='20' />}
      {sortOrder === null && <span style={{ width: '20px', height: '20px' }}></span>}{' '}
    </button>
  )
}

const SearchFilter: FC<ISearchFilterProps> = (props) => {
  const [sortOrder, setSortOrder] = useRecoilState(projectSortOrderState)
  const [selectedItem, setSelectedItem] = useRecoilState(projectSortColumnState)
  const setSearchQuery = useSetRecoilState(searchQueryState)

  const handleSortButtonClick = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortOrder(newSortOrder)
  }

  const handleDropdownChange = (item: string | number) => {
    setSelectedItem(item)
  }

  const handleSearch = (query: string) => {
    setSearchQuery(query)
  }

  const handleSearchChange = debounce((query: string) => {
    setSearchQuery(query)
  }, 300)

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  React.useEffect(() => {
    setSearchQuery('')
  }, [setSearchQuery])

  return (
    <Container fluid cssClass={classNames(Style.topContainer, Style.padingX, 'topSearchContainer')}>
      <div className={Style.searchContainer}>
        <SearchBox
          cssClass='search_box'
          placeholder={props.placeholder}
          onSearch={handleSearch}
          onChange={handleSearchChange}
          onClear={handleClearSearch}
        />
      </div>

      <div className={classNames(Style.rightSortContainer)}>
        <Text type='h4' cssClass={classNames(Style.sortText)}>
          {props.sortText}
        </Text>
        <Dropdown
          placeholder={'Created Date'}
          items={props.dropdownItems}
          selectedItem={selectedItem}
          onSelectItem={handleDropdownChange}
          optionsHeight={300}
          cssClass='is-custom'
        />
        <SortButton sortOrder={sortOrder} onClick={handleSortButtonClick} />
      </div>
    </Container>
  )
}

export default SearchFilter
