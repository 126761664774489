// src/components/ProcessPreferredScenarioDashboardChart.tsx
import React, { useMemo } from 'react'
import { NpvChart } from 'src/models/api/ReportMappingResponseModel'
import PreferredScenarioResultsChart from './PreferedScenarioResultsChart'
import { getCumulativeData } from 'src/helpers/chartUtils'

interface WaterfallChartDataProcessorProps {
  data: NpvChart
}

const ProcessPreferredScenarioDashboardChart: React.FC<WaterfallChartDataProcessorProps> = ({ data }) => {
  const cumulativeData = useMemo(() => getCumulativeData(data), [data])

  return <PreferredScenarioResultsChart cumulativeData={cumulativeData} pageType='dashboard' />
}

export default ProcessPreferredScenarioDashboardChart
