export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

export const HttpContentType = {
  JSON: 'application/json',
  TEXT: 'text/plain',
  BLOB: 'blob',
}
