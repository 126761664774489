import { ExecutionItem, ExecutionResponse } from './ExecutionReponseModel'

class ExecutionModel {
  public items: ExecutionItem[]
  public total: number
  public page: number
  public size: number
  public pages: number

  constructor(data: ExecutionResponse) {
    this.items = data.items
    this.total = data.total
    this.page = data.page
    this.size = data.size
    this.pages = data.pages
  }

  static fromJSON(data: ExecutionResponse): ExecutionModel {
    return new ExecutionModel(data)
  }
}

export default ExecutionModel
