import {
  Button,
  Container,
  Dropdown,
  Grid,
  IDropdownItemProps,
  Text,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import { startTransition, useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { AllScenariosListModelItem } from 'src/models/api/ScenariosListModel'
import {
  allScenarioListSelector,
  fetchExecutionDataSelector,
  inProductedSecretState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../../styles/Execution.module.sass'
import { newExecutionData } from 'src/api/ExecutionsService'
import classNames from 'classnames'
import { isRoleEmpty } from 'src/helpers/appRoles'

interface ConfirmationExecutionModelProps {
  onCancel: () => void
}

const ConfirmationExecutionModel: React.FC<ConfirmationExecutionModelProps> = ({ onCancel }) => {
  const showToast = useToast()
  const SecretState = useRecoilValue(inProductedSecretState)
  const [showError, setShowError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedItems, setSelectedItems] = useState<IDropdownItemProps[]>([])
  const allScenarioData = useRecoilValue(allScenarioListSelector)
  const [scenarioState, setScenarioState] = useState<{ scenarios: AllScenariosListModelItem[] }>({ scenarios: [] })

  const projectId = useRecoilValue(selectedProjectIdState)
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(fetchExecutionDataSelector)

  useEffect(() => {
    startTransition(() => {
      if (allScenarioData && typeof allScenarioData === 'object') {
        const allScenarioDataArray = Object.values(allScenarioData).filter(
          (item) => typeof item === 'object',
        ) as Array<AllScenariosListModelItem>

        setScenarioState({
          scenarios: allScenarioDataArray,
        })
      }
    })
  }, [allScenarioData])

  const renderStatus = (selectedItems: IDropdownItemProps[]): string | JSX.Element => {
    return (
      <Text>
        {' '}
        Selected {selectedItems.length} item{selectedItems.length > 1 ? 's' : ''}
      </Text>
    )
  }

  const renderOnSelectMultipleItems = (selectedItems: (string | number)[]) => {
    const stringItems = selectedItems.map((item) => String(item))
    setSelectedItems(stringItems.map((item) => ({ id: item, label: item })))
    setShowError(false)
  }

  const handleNewExecutionClick = async () => {
    if (selectedItems.length === 0) {
      setShowError(true)
      return
    }
    setIsSubmitting(true)
    const selectedScenarioID: string[] = selectedItems.map((item) => {
      return item.id.toString()
    })
    const response = await newExecutionData(selectedScenarioID, projectId)
    try {
      if (response?.['id']) {
        showToast.addToast({
          type: 'success',
          message: `Execution created successfully`,
          timeout: 3000,
        })
      } else {
        showToast.addToast({ type: 'error', message: 'An error occurred while creating execution!', timeout: 3000 })
      }
    } catch (error) {
      if (error) {
        showToast.addToast({ type: 'error', message: 'An error occurred while creating execution!', timeout: 3000 })
      }
    } finally {
      setIsSubmitting(false)
      refreshFetchItems()
      onCancel()
    }
  }

  return (
    <Container fluid>
      <h2>Confirm Execution</h2>
      <p>Select the scenarios to execute</p>
      {scenarioState.scenarios.length > 0 ? (
        <>
          <Grid row gap={6}>
            <Grid item xs={12}>
              <Dropdown
                placeholder='All Scenarios'
                items={scenarioState.scenarios.map((scenario: AllScenariosListModelItem) => ({
                  id: scenario.id,
                  label: scenario.name,
                }))}
                onSelectMultipleItems={(selectedItems) => {
                  renderOnSelectMultipleItems(selectedItems)
                }}
                clearSelectionItem='- Clear selection -'
                selectedMultipleItemsRender={(selectedItems) => renderStatus(selectedItems)}
                multipleDisplayValues
                toggleAllItem='Toggle All'
                multiple
                optionsHeight={200}
              />
            </Grid>
            {showError && <span className={Style.errorDescription}>Please select at least one scenario!</span>}
          </Grid>
          <Grid row gap={6}>
            <Grid item xs={12} right cell>
              <div className={Style.btn_container}>
                <Button
                  type='custom'
                  cssClass={classNames('is-white', Style.btndetails)}
                  label='Cancel'
                  size='small'
                  onClick={onCancel}
                />
                {SecretState && !isRoleEmpty(SecretState.roles) && (
                  <Button
                    type='primary'
                    cssClass={classNames('is-white', Style.newExecBtn)}
                    label='New Execution'
                    size='small'
                    onClick={handleNewExecutionClick}
                    disabled={isSubmitting}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <Text>No scenarios available</Text>
      )}
    </Container>
  )
}

export default ConfirmationExecutionModel
