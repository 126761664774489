export const ERROR_MESSAGES = {
  requiredField: 'Required field',
  numberMin: 'Entered value must be greater than 0',
  numberMax2Digits: 'Entered value should be in 2 digits',
  numberMax6Digits: 'Entered value should be in 6 digits',
  numberInteger: 'Entered value must be an integer',
  numberPositive: 'Entered value must be a positive number',
  descriptionMax: 'Entered value must not exceed 500 characters',
  fileSize: 'File size exceeds 5 MB',
  requiredFile: 'File is required',
}
