import { Container, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import Style from '../styles/UserSettings.module.sass'
import { appInsights } from 'src/api/AppInsights'
import { useRecoilValue } from 'recoil'
import { allUserList } from 'src/stores/AppStore'
import { UserResponseModel } from 'src/models/api/UserGetResponseModel'

const UserSettings = () => {
  if (appInsights) appInsights.trackPageView({ name: 'UserSettings' })

  const userData = useRecoilValue(allUserList)

  const user = userData ? userData['data'] : []
  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>User Management</h1>
          </div>
        </Container>
      </header>

      <Container fluid cssClass={`${Style.paddingX} ${Style.pageBtm}`}>
        <section className={Style.grid_container}>
          <Table
            headers={[
              { label: 'Name', sort: 'none' },
              { label: 'Email', sort: 'none' },
              { label: 'Role', sort: 'none' },
            ]}
            cssClass={Style.tableHeader}
          >
            {user.map((item: UserResponseModel) => (
              <TableRow key={item.user_id}>
                <TableCell cellClass={Style.tableDataPadding}>{item.name}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>{item.email}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <span className={Style.paCellBg}>{'Project Administrator'}</span>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </section>
      </Container>
    </Container>
  )
}

export default UserSettings
